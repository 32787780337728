import * as React from 'react';
import { Alert, Platform } from 'react-native';

export default ({ title, body, actions, onConfirm }) => {
  if (Platform.OS === 'web') {
    const confirm = window?.confirm(body);
    if (confirm) {
      onConfirm && onConfirm();
    }
  }
  return Alert.alert(title, body, actions);
};