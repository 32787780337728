import React from "react";
import {
  View,
  Text,
} from "react-native";
import UploadVideoFromLink from 'components/RedeemScreen/UploadVideoFromLink';

const UploadVideoLinkScreen = (props) => (
  <View style={{ flex: 1 }}>
    <UploadVideoFromLink />
  </View>
)
export default UploadVideoLinkScreen;
