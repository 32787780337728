import * as React from 'react';
import { path } from 'ramda';
import {
  Platform,
  View,
  Text,
  Image,
  TextInput,
  TouchableOpacity,
  Modal as NativeModal,
  ActivityIndicator,
  ScrollView,
  Share
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import styled from 'styled-components/native';
import WebModal from 'modal-enhanced-react-native-web';
import { verifyPromoCodeAction } from 'actions/userActions';
import i18n from 'i18n';

const Modal = Platform.select({
  web: WebModal,
  default: NativeModal,
});

const PromoCodeModal = ({
  visible,
  setIsPromoCodeModalOpen,
}) => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const uniqueId = useSelector(state => state.user?.tiktok?.uniqueId);
  const [hasCompleted, setHasCompleted] = React.useState(false);
  const [showActivityIndicator, setActivityIndicator] = React.useState(false);
  const [promoCode, setPromoCode] = React.useState('');
  const [isInvalidCode, setIsInvalidCode] = React.useState(false);

  const onPressConfirm = async (promoCode) => {
    try {
      setActivityIndicator(true);
      const isPromoCodeValid = await dispatch(verifyPromoCodeAction(promoCode));
      if (isPromoCodeValid) {
        setHasCompleted(true);
      } else {
        setIsInvalidCode(true);
      }
      setActivityIndicator(false);
    } catch (e) {
      setActivityIndicator(false);
      setIsInvalidCode(true);
    }
  };

  return (
    <Modal
      visible={visible}
      transparent={true}
      style={{ margin: 0 }}
    >
      {
        showActivityIndicator && <StyledActivityIndicator size="large" color="black" />
      }
      <PromoCodeModalWrapper
        keyboardShouldPersistTaps="handled"
        enableOnAndroid={true}
        removeClippedSubviews={false}
        contentContainerStyle={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
      >
        {
          !hasCompleted ?
            <PromoCodeModalBody>
              <PromoCodeModalImage source={require('assets/images/invite.png')} />
              <PromoCodeModalBottomArea>
                <PromoCodeModalBottomAreaInstruction>
                  {i18n.t('settings_promo_code')}
                </PromoCodeModalBottomAreaInstruction>
                <StyledTextInput
                  placeholder="Your friend's username"
                  selectTextOnFocus={true}
                  value={promoCode}
                  onChangeText={text => setPromoCode(text.toLowerCase())}
                />
                {
                  isInvalidCode &&
                  <InvalidText>Promo code is not valid</InvalidText>
                }
                <ButtonsWrapper>
                  <PromoCodeModalButton
                    isCancel={true}
                    onPress={() => setIsPromoCodeModalOpen(false)}
                  >
                    <ButtonText>Cancel</ButtonText>
                  </PromoCodeModalButton>
                  <PromoCodeModalButton
                    onPress={() => onPressConfirm(promoCode)}
                  >
                    <ButtonText>Confirm</ButtonText>
                  </PromoCodeModalButton>
                </ButtonsWrapper>
              </PromoCodeModalBottomArea>
            </PromoCodeModalBody>
            :
            <CompleteBody>
              <Header>{i18n.t('redeem_promo_code_instruction_title')}</Header>
              <PromoCodeModalImage source={require('assets/images/wait.png')} />
              <View style={{ paddingHorizontal: 20 }}>
                <PromoCodeModalBottomAreaInstruction>
                  {i18n.t('redeem_promo_code_instruction')}
                </PromoCodeModalBottomAreaInstruction>
              </View>
              <ButtonsWrapper>
                <PromoCodeModalButton
                  onPress={() => {
                    setIsPromoCodeModalOpen(false);
                    navigation.navigate('HomeStack');
                  }}
                >
                  <ButtonText>OK</ButtonText>
                </PromoCodeModalButton>
              </ButtonsWrapper>
            </CompleteBody>
        }

      </PromoCodeModalWrapper>
    </Modal>
  );
};

export default PromoCodeModal;

const StyledTextInput = styled(TextInput)`
  width: 100%;
  height: 40;
  border-bottom-width: 1;
  border-color: ${path(['theme', 'primary', 'lightGray'])};
  margin-top: 10;
  padding-left: 3;
  padding-right: 3;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const PromoCodeModalWrapper = styled(ScrollView)`
  flex: 1;
  margin: 0px;
  background-color: rgba(0, 0, 0, 0.3);
`;

const PromoCodeModalBody = styled(View)`
  width: 90%;
  height: 370;
  max-height: 370;
  max-width: 370;
  background-color: #FFFF;
  border-radius: 24px;
  overflow: hidden;
  shadow-opacity: 0.65;
  shadow-radius: 5px;
  shadow-color: black;
  shadow-offset: 0px 0px;
  justify-content: center;
`;

const CompleteBody = styled(View)`
  width: 90%;
  height: 270;
  max-height: 270;
  max-width: 370;
  background-color: #FFFF;
  border-radius: 24px;
  overflow: hidden;
  shadow-opacity: 0.65;
  shadow-radius: 5px;
  shadow-color: black;
  shadow-offset: 0px 0px;
  justify-content: center;
`;

const PromoCodeModalImage = styled(Image)`
  width: 66;
  height: 66;
  align-self: center;
  margin-bottom: 10;
`;

const PromoCodeModalBottomArea = styled(View)`
  width: 100%;
  height: 54%;
  padding: 22px;
`;

const PromoCodeModalBottomAreaInstruction = styled(Text)`
  font-size: 18;
  font-weight: 600;
  line-height: 20;
  color: ${path(['theme', 'primary', 'lightGray'])}
`;

const ButtonsWrapper = styled(View)`
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 15;
`;

const PromoCodeModalButton = styled(TouchableOpacity)`
  width: 124;
  height: 49;
  border-radius: 24;
  margin-top: 16;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.isCancel ? path(['theme', 'primary', 'gray']) : path(['theme', 'primary', 'purple'])}
`;

const ButtonText = styled(Text)`
  font-size: 18;
  font-weight: bold;
  color: #FFFF;
`;

const StyledActivityIndicator = styled(ActivityIndicator)`
  position: absolute;
  margin: auto;
  top: 0; 
  left: 0; 
  bottom: 0; 
  right: 0;
  z-index: 99;
`;

const InvalidText = styled(Text)`
  color: #FF0000;
`;

const Header = styled(Text)`
  font-size: 20;
  font-weight: bold;
  color: #000000;
  align-self: center;
  margin-bottom: 10;
`;

