import i18n from 'i18n-js';
import { NativeModules, Platform } from 'react-native';
const el = require('./dict/el.json');
const en = require('./dict/en.json');
const es = require('./dict/es.json');
const fr = require('./dict/fr.json');
const id = require('./dict/id.json');
const it = require('./dict/it.json');
const ja = require('./dict/ja.json');
const ko = require('./dict/ko.json');
const pl = require('./dict/pl.json');
const pt = require('./dict/pt.json');
const ru = require('./dict/ru.json');
const tr = require('./dict/tr.json');
const zh = require('./dict/zh.json');
const vi = require('./dict/vi.json');

// const deviceLanguage = Platform.select({
//   android: NativeModules.I18nManager?.localeIdentifier,
//   ios: NativeModules.SettingsManager?.settings?.AppleLocale || NativeModules.SettingsManager?.settings?.AppleLanguages[0], // iOS 13
//   web: Intl.DateTimeFormat().resolvedOptions().locale,
// });

let deviceLanguage = 'en-US';
if (Platform.OS === 'android') {
  deviceLanguage = NativeModules.I18nManager?.localeIdentifier;
} else if (Platform.OS === 'ios') {
  deviceLanguage = NativeModules.SettingsManager?.settings?.AppleLocale || NativeModules.SettingsManager?.settings?.AppleLanguages[0];
} else if (Platform.OS === 'web') {
  deviceLanguage = Intl.DateTimeFormat().resolvedOptions().locale;
}

i18n.locale = deviceLanguage.replace('_', '-');
i18n.fallbacks = true;
i18n.translations = {
  el,
  en,
  es,
  fr,
  id,
  it,
  ja,
  ko,
  pl,
  pt,
  ru,
  tr,
  zh,
  vi,
};

export default i18n;