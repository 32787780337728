import * as React from 'react';
import {
  View,
  FlatList,
  Platform,
  TouchableOpacity,
  Image,
  RefreshControl,
  Text,
  ActivityIndicator,
} from 'react-native';
import { AntDesign, FontAwesome } from '@expo/vector-icons';
import { path } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import styled from 'styled-components/native';

import UploadVideoFromLink from 'components/RedeemScreen/UploadVideoFromLink';
import Colors from 'constants/Colors';
import i18n from 'i18n';

const LikesTab = ({ }) => {
  const dispatch = useDispatch();
  const ifFetchingUserTikTokVideos = useSelector(
    (state) => state?.user?.ifFetchingUserTikTokVideos
  );
  const [shouldReload, setShouldReload] = React.useState(false);
  const items = useSelector((state) => state?.user?.tiktok?.videos) ?? [];
  return (
    // <UploadVideoFromLink />
    <Container>
      {/* <UploadVideoFromLink /> */}
      {/* <Image source={require('assets/images/503.png')} style={{ marginTop: 60, width: 79, height: 114 }} />
          <Text style={{ color: '#000000', marginTop: 20, fontSize: 28 }}>Service will come back soon</Text> 
      */}
      {Platform.OS === 'web' && ifFetchingUserTikTokVideos && (
        <StyledActivityIndicator
          size="large"
          color={Colors.primary.lightGray}
        />
      )}
      <FlatList
        style={{
          backgroundColor: Colors.primary.lightBlue,
          flex: 1,
        }}
        contentContainerStyle={{
          backgroundColor: Colors.primary.lightBlue,
          paddingBottom: 100,
        }}
        showsVerticalScrollIndicator={false}
        data={[...items, {}]}
        keyExtractor={(item, index) => index.toString()}
        numColumns={3}
        renderItem={({ item }) => <ListItem item={item} />}
        ListHeaderComponent={
          <ListHeaderText>{i18n.t('redeem_content')}</ListHeaderText>
        }
        refreshControl={
          <RefreshControl
            refreshing={ifFetchingUserTikTokVideos}
            onRefresh={() => setShouldReload(true)}
          />
        }
      />
    </Container>
  );
};

export default LikesTab;

const Container = styled(View)`
  flex: 1;
  background-color: ${path(['theme', 'primary', 'lightBlue'])};
  align-items: center;
`;

const ListItemWrapper = styled(TouchableOpacity)`
  background-color: #FFFFFF;
  height: 184;
  width: 103;
  border-radius: 12;
  margin-top: 24;
  margin-left: 12;
  margin-right: 12;
  overflow: hidden;
`;

const VideoPreview = styled(Image)`
  width: 103;
  height: 123;
`;

const LikesNumberText = styled(Text)`
  color: #000000;
  margin-left: 8;
  margin-top: 8;
`;

const ListItem = ({ item }) => {
  const navigation = useNavigation();
  const isEmpty = item && Object.keys(item)?.length === 0;
  return isEmpty ? (
    <ListItemWrapper
      style={{
        borderColor: Colors.primary.purple,
        borderWidth: 5,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onPress={() => navigation.navigate('UploadVideoLink')}
    >
      <AntDesign name="pluscircle" size={32} color={Colors.primary.purple} />
    </ListItemWrapper>
  ) : (
    <ListItemWrapper
      onPress={() => {
        navigation.navigate('RedeemLikesConfirm', { item });
      }}
    >
      <VideoPreview source={{ uri: item.imageUrl || item.covers?.default }} />
      <LikesNumberText>❤ {item.diggCount}</LikesNumberText>
      <LikesNumberText><FontAwesome name="commenting-o" size={19} color="black" /> {item.commentCount >= 0 && item.commentCount}</LikesNumberText>
    </ListItemWrapper>
  );
};

const ListHeaderText = styled(Text)`
  color: #000000;
  font-size: 16;
  font-weight: bold;
  letter-spacing: 0.19;
  margin-top: 24;
  align-self: center;
`;

const StyledActivityIndicator = styled(ActivityIndicator)`
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
`;
