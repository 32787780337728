// @format
import { useNavigation } from '@react-navigation/native';
import { purchaseItemAsync } from 'expo-in-app-purchases';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import React, { useCallback } from 'react';
import {
  View,
  Text,
  Dimensions,
  TouchableOpacity,
  Image,
  Platform,
} from 'react-native';
import styled from 'styled-components/native';

import Alert from 'components/Alert';
import * as slack from 'apis/slack';
import i18n from 'i18n';

const { width: screenWidth } = Dimensions.get('window');

const ListItemContainer = styled(View)`
  height: 80;
  width: ${screenWidth - 56};
  max-width: 358;
  background-color: #ffffff;
  margin-top: 20;
  border-radius: 12;
  justify-content: space-between;
  padding-left: 18;
  padding-right: 18;
  align-items: center;
  flex-direction: row;
`;

const ItemNameText = styled(Text)`
  font-size: 19;
  font-weight: 700;
  letter-spacing: 0.21;
  line-height: 25;
  color: #000000;
`;

const DiscountLabelWrapper = styled(View)`
  height: 22.5;
  width: 80;
  background-color: ${path(['theme', 'primary', 'blue'])};
  position: absolute;
  top: 0;
  left: 20;
  justify-content: center;
  align-items: center;
`;

const DiscountLabelText = styled(Text)`
  font-size: 13;
  font-weight: 800;
  color: #ffffff;
`;

const DiscountLabel = ({ discount }) => (
  <DiscountLabelWrapper>
    <DiscountLabelText>{`${discount}% OFF`}</DiscountLabelText>
  </DiscountLabelWrapper>
);

DiscountLabel.propTypes = {
  discount: PropTypes.string,
};

const ItemButtonWrapper = styled(TouchableOpacity)`
  width: 120;
  height: 40;
  border-radius: 8;
  background-color: ${(props) => path(['theme', 'primary', 'pink'])};
  justify-content: center;
  align-items: center;
`;

const ItemButtonText = styled(Text)`
  color: #ffffff;
  font-size: 14;
  font-weight: 700;
  line-height: 19;
`;

const ItemButton = ({ onPress, price, customText }) => (
  <ItemButtonWrapper onPress={() => onPress()}>
    <ItemButtonText>{customText ? customText : price}</ItemButtonText>
  </ItemButtonWrapper>
);

ItemButton.propTypes = {
  onPress: PropTypes.func,
  price: PropTypes.string,
};

const StarsNumberText = styled(ItemNameText)`
  color: ${(props) => path(['theme', 'primary', 'lightPink'])};
`;

const ItemName = ({ stars, promotion }) => (
  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
    {promotion && (
      <Image
        source={require('assets/images/best-seller.png')}
        style={{
          width: 30,
          height: 30,
        }}
      />
    )}
    <StarsNumberText>+{stars}</StarsNumberText>
    <Text> </Text>
    {/* <ItemNameText>stars </ItemNameText> */}
    <Image
      style={{ width: 20, height: 20 }}
      source={require('assets/images/star.png')}
    />
  </View>
);

const FreeItemName = ({ stars }) => (
  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
    <StarsNumberText>+{stars}</StarsNumberText>
    <Text> </Text>
    <Image
      style={{ width: 20, height: 20 }}
      source={require('assets/images/star.png')}
    />
  </View>
);

const FreeDiscountLabel = () => (
  <DiscountLabelWrapper>
    <DiscountLabelText>{i18n.t('purchase_free')}</DiscountLabelText>
  </DiscountLabelWrapper>
);

ItemName.propTypes = {
  stars: PropTypes.number,
};

export const FreePurchaseItem = ({ onPress }) => {
  return (
    <ListItemContainer>
      <FreeDiscountLabel />
      <FreeItemName stars={10} />
      <ItemButton
        customText={`${i18n.t('purchase_rate_us')} ⭐`}
        onPress={onPress}
      />
    </ListItemContainer>
  );
};

const SubscriptionText = styled(Text)`
  color: ${path(['theme', 'primary', 'lightGray'])};
  font-size: 13;
  font-weight: 900;
`;

const StarPurchaseItem = ({ content = {}, onPress }) => {
  const price = content?.localPrice || content?.price;
  return (
    <ListItemContainer>
      <DiscountLabel discount={content.discount} />
      <View>
        <ItemName stars={content.stars} promotion={content.promotion} />
        {content.isSubscription && (
          <SubscriptionText>(Weekly Subscription)</SubscriptionText>
        )}
      </View>
      <ItemButton price={price} onPress={onPress} />
    </ListItemContainer>
  );
};

StarPurchaseItem.propTypes = {
  content: PropTypes.object,
  onPress: PropTypes.func,
};

export default StarPurchaseItem;

export const WebStarPurchaseItem = (props) => {
  const navigation = useNavigation();
  const user = useSelector((state) => state.user);
  const applePay = useSelector((state) => state.payment?.applePay);
  const onPress = useCallback(() => {
    slack.tapWebPurchase({
      ...user,
      price: props?.content?.price,
      applePay: applePay?.canMakePaymentsWithActiveCard,
    });

    // if (applePay?.canMakePaymentsWithActiveCard) {
    //   ApplePay.paymentRequest({
    //     user,
    //     content: props?.content,
    //     onApplePayApprove: props?.onApplePayApprove,
    //     setIsLoading: props?.setIsLoading,
    //   });
    //   return;
    // }
    navigation.navigate('SelectPayment', {
      content: props?.content,
      price: props?.content?.price,
      localPrice: props?.content?.localPrice,
      productId: props?.content?.productId,
      onPayPalCaptureApprove: props?.onPayPalCaptureApprove,
      onApplePayApprove: props?.onApplePayApprove,
      onTapPayCreditCardApprove: props?.onTapPayCreditCardApprove,
    });
  }, [props.content]);
  return <StarPurchaseItem {...props} onPress={onPress} />;
};

WebStarPurchaseItem.propTypes = {
  content: PropTypes.object,
  onPayPalCaptureApprove: PropTypes.func,
};

export const StarPurchaseItemWithIAP = (props) => {
  const onPress = useCallback(async () => {
    props.setIsLoading(true);
    // TODO: Victor if the content productId would changed in list item,
    // should put the content as dependency
    const res = await purchaseItemAsync(props?.content?.productId).catch(
      (e) => {
        if (__DEV__) {
          console.log('purchaseItemAsync error: ', e);
        }
      }
    );
    props.setIsLoading(false);
    return;
  }, []);
  return <StarPurchaseItem {...props} onPress={onPress} />;
};

StarPurchaseItemWithIAP.propTypes = {
  setIsLoading: PropTypes.func,
};
