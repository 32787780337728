import * as React from 'react';
import { useSelector } from 'react-redux';
import { path } from 'ramda';
import { Ionicons } from '@expo/vector-icons';
import { Text, View, Dimensions, Image, Platform, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import { getMobileOperatingSystem } from 'utils/system';
import { signInWithApple, SUPPORTED_LANG_OF_APPLE_BUTTON } from 'modules/LoginWithApple/index.js';
import i18n from 'i18n';

const os = getMobileOperatingSystem();

const AppleLoginButton = styled(TouchableOpacity)`
  width: 250px;
  align-items: center;
  margin-vertical: 20px;
`;

const AppleLoginText = styled(Text)`
  color: #fff;
  font-size: 16px;
  text-align: center;
`;

const queryLang =
  Object.values(SUPPORTED_LANG_OF_APPLE_BUTTON).indexOf(navigator.language.replace('-', '_')) === -1
    ? ''
    : `&locale=${navigator.language.replace('-', '_')}`;

const ProfileCard = () => {
  const user = useSelector(state => state.user);
  const navigation = useNavigation();
  const [isAppleButtonLoadError, setIsAppleButtonLoadError] = React.useState(false);

  return (
    <View style={{ width: '100%' }}>
      <ProfileCardWrapper>
        <AvatarWrapper source={{ uri: user?.tiktok?.avatar }} uniqueId={user?.tiktok?.uniqueId} />
        {Platform.OS === 'ios' && (
          <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            <ProfileCardText style={{ marginTop: 20 }}>{user?.tiktok?.signature}</ProfileCardText>
            <GenSignatureButton onPress={() => navigation.navigate('Bio')} />
          </View>
        )}
      </ProfileCardWrapper>
      <ProfileCardStatsWrapper>
        {Platform.OS === 'web' && !user?.appleId && os === 'iOS' ? (
          <View style={{ alignItems: 'center' }}>
            <AppleLoginText>{i18n.t('login_with_apple_instruction')}</AppleLoginText>
            <AppleLoginButton
              onPress={async () => {
                // const url = `https://appleid.apple.com/auth/authorize?${searchParams}`;
                // const supported = await Linking.canOpenURL(url);
                // if (supported) {
                //   Linking.openURL(url);
                // }
                signInWithApple();
              }}
            >
              <Image
                style={{ width: 330, height: 48 }}
                source={
                  isAppleButtonLoadError
                    ? require('assets/images/appleid_button@2x.png')
                    : {
                      uri: `https://appleid.cdn-apple.com/appleid/button?width=330&height=48&scale=2&color=white${queryLang}`,
                    }
                }
                onError={() => setIsAppleButtonLoadError(true)}
              />
            </AppleLoginButton>
          </View>
        ) : (
          // <ProfileCardStarsItem title={"Following"} number={user?.tiktok?.followingCount} />
          <>
            <ProfileCardStarsItem title={'Stars'} number={user.stars} isStars={true} />
            <ProfileCardStarsItem title={'Followers'} number={user?.tiktok?.followerCount} />
          </>
        )}
      </ProfileCardStatsWrapper>
    </View>
  );
};

export default ProfileCard;

const { width: screenWidth } = Dimensions.get('window');
const ProfileCardWrapper = styled(View)`
  min-height: 149;
  width: 100%;
  background-color: ${path(['theme', 'primary', 'purple'])};
  padding: 30px;
  align-items: center;
`;

const Avatar = styled(Image)`
  width: 70;
  height: 70;
  border-radius: 35;
  margin-bottom: 4;
  border-color: #ffffff;
  border-width: 2;
  background-color: ${props => path(['theme', 'primary', 'gray'])};
`;

const ProfileCardText = styled(Text)`
  font-size: 16;
  color: #ffffff;
  margin-top: 4;
  font-weight: bold;
`;

const ProfileCardBoldText = styled(ProfileCardText)`
  font-weight: 600;
`;

const AvatarWrapper = ({ source, uniqueId }) => (
  <View style={{ alignItems: 'center' }}>
    <Avatar source={source} />
    <ProfileCardBoldText>@{uniqueId}</ProfileCardBoldText>
  </View>
);

const ProfileCardStarsItemWrapper = styled(View)`
  justify-content: center;
  align-items: center;
  /* margin-left: 30; */
  flex: 1;
  height: 69;
`;

const ProfileCardStarsItem = ({ title, number, isStars }) => (
  <ProfileCardStarsItemWrapper>
    <ProfileCardBoldText>{number}</ProfileCardBoldText>
    <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
      <ProfileCardText>{title}</ProfileCardText>
      {isStars && <Ionicons name="md-star" size={16} color="white" />}
    </View>
  </ProfileCardStarsItemWrapper>
);

const ProfileCardStatsWrapper = styled(View)`
  flex-direction: row;
  justify-content: center;
  background-color: ${path(['theme', 'primary', 'purple'])};
  shadow-color: #4a4a4a;
  shadow-opacity: 1;
  shadow-offset: 0 0;
`;

const GenSignatureButtonWrapper = styled(TouchableOpacity)`
  width: 240;
  height: 41;
  border-radius: 8;
  border-width: 1.5;
  border-color: #ffffff;
  justify-content: center;
  align-items: center;
  margin-top: 20;
`;

const GenSignatureButton = ({ onPress }) => {
  return (
    <GenSignatureButtonWrapper onPress={onPress}>
      <ProfileCardText>Get new bio</ProfileCardText>
    </GenSignatureButtonWrapper>
  );
};
