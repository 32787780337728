import { Platform } from 'react-native';
import delay from 'delay';
import { always, identity, applySpec, prop, propOr, compose, replace } from 'ramda';
import _ from 'lodash';

import { tiktokUserDataToProfile } from 'utils/tiktokUtils';
import { checkCanMakePaymentsWithActiveCard } from 'modules/ApplePay';
import { resetUnprocessedPurchase, resetUnprocessedTransaction } from 'actions/paymentActions';

import makeActionCreator from './makeActionCreator';
import { getCountryCode } from 'utils/location';
import { getMobileOperatingSystem } from 'utils/system';
import { addDays } from 'utils/time';
import { IAP_PRODUCTS } from 'constants/Products';
import { getAppleLoginResult } from 'modules/LoginWithApple/index.js';

export const REQUEST_TIKTOK_USER = 'REQUEST_TIKTOK_USER';
export const RECEIVE_TIKTOK_USER = 'RECEIVE_TIKTOK_USER';
export const RESET_TIKTOK_USER = 'RESET_TIKTOK_USER';

export const REQUEST_USER_TIKTOK_VIDEOS = 'REQUEST_USER_TIKTOK_VIDEOS';
export const RECEIVE_USER_TIKTOK_VIDEOS = 'RECEIVE_USER_TIKTOK_VIDEOS';
export const RESET_USER_TIKTOK_VIDEOS = 'RESET_USER_TIKTOK_VIDEOS';

export const RECEIVE_AUTH_USER = 'RECEIVE_AUTH_USER';

export const UPDATE_STARS_COUNT = 'UPDATE_STARS_COUNT';
export const UPDATE_FOLLOWING_LIST = 'UPDATE_FOLLOWING_LIST';
export const UPDATE_PURCHASE_STATUS = 'UPDATE_PURCHASE_STATUS';
export const UPDATE_HAS_REDEEMED_PROMO_CODE = 'UPDATE_HAS_REDEEMED_PROMO_CODE';
export const UPDATE_IS_PENDING_REDEEM_PROMO_CODE = 'UPDATE_IS_PENDING_REDEEM_PROMO_CODE';

export const REWARD_USER = 'REWARD_USER';

export const REWARD_REVIEW = 'REWARD_REVIEW';
export const REWARD_FOLLOW_IG = 'REWARD_FOLLOW_IG';
export const UPDATE_RETURN_USER_INFO = 'UPDATE_RETURN_USER_INFO';

export const SAVE_REFERRER_INFO = 'SAVE_REFERRER_INFO';
export const UPDATE_USER_DB_RECORD = 'UPDATE_USER_DB_RECORD';

export const FOUND_SUSPICIOUS_ACCOUNT = 'FOUND_SUSPICIOUS_ACCOUNT';
export const UPDATE_SHOW_SUSPICIOUS_ALERT = 'UPDATE_SHOW_SUSPICIOUS_ALERT';

export const REWARD_DAILY_LOGIN = 'REWARD_DAILY_LOGIN';
export const FLAG_USE_REDEEM_IOS_FIRST_LOGON_REWARD = 'FLAG_USE_REDEEM_IOS_FIRST_LOGON_REWARD';

export const UPDATE_PENDING_STARS_COUNT = 'UPDATE_PENDING_STARS_COUNT';
export const RESET_PENDING_STARS_COUNT = 'RESET_PENDING_STARS_COUNT';
export const UPDATE_SHOULD_RELOAD_USER_PROFILE = 'UPDATE_SHOULD_RELOAD_USER_PROFILE';
export const UPDATE_HAS_REDEEMED = 'UPDATE_HAS_REDEEMED';
export const UPDATE_APPLE_ID = 'UPDATE_APPLE_ID';
export const UPDATE_UID_AND_STARS = 'UPDATE_UID_AND_STARS';
export const UPDATE_SHOW_LOGIN_SCREEN_COUNT = 'UPDATE_SHOW_LOGIN_SCREEN_COUNT';

const requestTikTokUser = makeActionCreator(REQUEST_TIKTOK_USER);
const receiveTikTokUser = makeActionCreator(RECEIVE_TIKTOK_USER, 'user');
export const resetTikTokUser = makeActionCreator(RESET_TIKTOK_USER);

const requestUserTikTokVideo = makeActionCreator(REQUEST_USER_TIKTOK_VIDEOS);
const receiveUserTikTokVideo = makeActionCreator(RECEIVE_USER_TIKTOK_VIDEOS, 'videos');
const resetUserTikTokVideo = makeActionCreator(RESET_USER_TIKTOK_VIDEOS);

const receiveAuthUser = makeActionCreator(RECEIVE_AUTH_USER, 'userInfo');

const updateStarsCount = makeActionCreator(UPDATE_STARS_COUNT, 'stars');

const rewardUser = makeActionCreator(REWARD_USER, 'stars', 'tiktokUser', 'followingUser');

const rewardReview = makeActionCreator(REWARD_REVIEW);
const rewardFollowIg = makeActionCreator(REWARD_FOLLOW_IG);
const updateReturnUserInfo = makeActionCreator(UPDATE_RETURN_USER_INFO, 'returnUserInfo');

const updatePurchaseStatus = makeActionCreator(UPDATE_PURCHASE_STATUS, 'hasPurchased');
const updateHasRedeemedStatus = makeActionCreator(
  UPDATE_HAS_REDEEMED_PROMO_CODE,
  'hasRedeemedPromoCode',
  'canRedeemPromoCode',
);
const updateIsPendingRedeemPromoCode = makeActionCreator(
  UPDATE_IS_PENDING_REDEEM_PROMO_CODE,
  'isPendingRedeemPromoCode',
  'referrerUid',
);

const saveReferrerInfo = makeActionCreator(SAVE_REFERRER_INFO, 'referrerUid', 'referrerUniqueId');
const updateHasUserDbRecord = makeActionCreator(UPDATE_USER_DB_RECORD, 'hasUserDbRecord');

const foundSuspiciousAccount = makeActionCreator(FOUND_SUSPICIOUS_ACCOUNT);
export const updateShowSuspiciousAlert = makeActionCreator(
  UPDATE_SHOW_SUSPICIOUS_ALERT,
  'showSuspiciousAlert',
);

const rewardDailyLogin = makeActionCreator(REWARD_DAILY_LOGIN, 'stars', 'date');

const flagUseRedeemIOSFirstLoginReward = makeActionCreator(
  FLAG_USE_REDEEM_IOS_FIRST_LOGON_REWARD,
  'used',
);

const updatePendingStarsCount = makeActionCreator(UPDATE_PENDING_STARS_COUNT, 'stars');

const resetPendingStarsCount = makeActionCreator(RESET_PENDING_STARS_COUNT);

export const updateShouldReloadUserProfile = makeActionCreator(
  UPDATE_SHOULD_RELOAD_USER_PROFILE,
  'shouldReloadUserProfile',
);

export const updateHasRedeemed = makeActionCreator(UPDATE_HAS_REDEEMED, 'hasRedeemed');

export const updateAppleId = makeActionCreator(UPDATE_APPLE_ID, 'appleId');
export const updateUidAndStars = makeActionCreator(UPDATE_UID_AND_STARS, 'data');
export const updateShowLoginScreenCount = makeActionCreator(UPDATE_SHOW_LOGIN_SCREEN_COUNT);

// subscriptions": Object {
//   "com.tikfans.www.10dollars.weekly": Object {
//     "period": undefined,
//     "renewAt": 2020-06-25T09:26:37.001Z,
//     "stars": undefined,
//   },
// }

const renewSubscriptionIfNeeded =
  (subscriptions = {}) =>
    async (dispatch, getState, { apis }) => {
      if (Object.keys(subscriptions).length <= 0) {
        return;
      }
      const state = getState();
      const history = state?.payment?.history;

      history.map(purchase => {
        const isSubscription = IAP_PRODUCTS[purchase?.productId]?.isSubscription ?? false;
        if (!isSubscription) {
          return;
        }
        const subscription = subscriptions[purchase?.productId];
        if (!subscription) {
          return;
        }
        const renewAt = subscription?.renewAt?.toDate();
        const now = new Date();
        const dateDifference = now?.getDate() - renewAt?.getDate();

        if (dateDifference >= subscription?.period) {
          dispatch(
            purchaseStarsAction({
              isSubscription: true,
              renewAt: subscription?.renewAt
                ? addDays(subscription?.renewAt?.toDate(), subscription?.period)
                : new Date(),
              ...purchase,
            }),
          );
        }
      });
    };

export const getUserProfileInfoAction =
  ({ username, isLogin = false, appleId }) =>
    async (dispatch, getState, { apis }) => {
      try {
        if (!username || !username?.trim() || username?.trim()?.length === 0) return false;
        const state = getState();
        const prevUniqueId = state.user?.tiktok?.uniqueId;
        const prevDiggCount = state.user?.tiktok?.diggCount ?? 0;
        const prevFollowingCount = state.user?.tiktok?.followingCount ?? 0;
        const uid = state?.user?.uid;

        const userInfo = await apis.firebase.getUserInfo({ uid });
        const { stars, hasUserDbRecord, subscriptions } = userInfo;
        dispatch(updateStarsCount(stars + state?.user?.pendingStars));
        dispatch(updateHasUserDbRecord(hasUserDbRecord));
        dispatch(renewSubscriptionIfNeeded(subscriptions));

        dispatch(requestTikTokUser());
        const tiktokProfile = await apis.tiktok.getUserProfileInfo({
          username,
          isLogin,
        });

        if (!tiktokProfile?.uniqueId) {
          if (isLogin) {
            dispatch(resetTikTokUser());
            apis.firebase.logEvent({ name: 'login_error' });
          }
          return false;
        }
        dispatch(receiveTikTokUser(tiktokProfile));

        const serializedTikTokProfile = tiktokUserDataToProfile(tiktokProfile);
        const currentDiggCount = serializedTikTokProfile.diggCount;
        const currentFollowingCount = serializedTikTokProfile.followingCount;
        const currentUniqueId = serializedTikTokProfile.uniqueId;

        if (
          !_.isEmpty(prevUniqueId) &&
          prevUniqueId === currentUniqueId &&
          currentDiggCount - prevDiggCount + (currentFollowingCount - prevFollowingCount) <= -15
        ) {
          const suspiciousCount = (state.user?.suspiciousCount ?? 0) + 1;
          const slackPayload = {
            ...state.user,
            prevDiggCount,
            currentDiggCount,
            prevFollowingCount,
            currentFollowingCount,
            suspiciousCount,
          };
          apis.slack.suspiciousAccount(slackPayload);
          dispatch(foundSuspiciousAccount());
          apis.firebase.logEvent({ name: 'unfollow' });
          if (suspiciousCount >= 2) {
            apis.slack.blockedAccount(slackPayload);
            apis.firebase.logEvent({ name: 'severe_unfollow' });
          }
        }

        return true;
      } catch (e) {
        if (__DEV__) {
          apis.firebase.logEvent({ name: 'login_error' });
          console.log('getUserProfileInfoAction error', e);
        }
        dispatch(resetTikTokUser());
        return false;
      }
    };

export const checkFollowStatusAction =
  ({ type, id, targetUniqueId, orderId, order, currentVideoInfo = {} }) =>
    async (dispatch, getState, { apis }) => {
      try {
        const state = getState();
        const user = state.user;
        const authUserUniqueId = state?.user?.tiktok?.uniqueId ?? '';
        const prevFollowingCount = state?.user?.tiktok?.followingCount;
        const prevDiggCount = state?.user?.tiktok?.diggCount;
        const openFavorite = state?.user?.tiktok?.openFavorite;
        const isPendingRedeemPromoCode = state?.user?.isPendingRedeemPromoCode ?? false;
        const canRedeemPromoCode = state?.user?.canRedeemPromoCode;
        const rewardStarsCount = type === 'comment' ? 2 : 1;

        const shouldSkipFetchingTikData =
          type === 'follower' ? Math.random() < 0.2 : Math.random() < 0.25;
        let shouldRewardUser = false;
        let isFetchTikTokApiFailed = false;
        let tiktokUser = {};

        if (shouldSkipFetchingTikData) {
          await delay(2000);
        } else if (type === 'follower') {
          await delay(2900);
        } else {
          await delay(5000);
        }

        if (type === 'comment' && id === currentVideoInfo?.id) {
          try {
            const newVideoInfo = shouldSkipFetchingTikData ? currentVideoInfo : await apis.tiktok.getVideoInfoFromLink({ url: order?.webVideoUrl });
            if (newVideoInfo?.commentCount > 9999) {
              shouldRewardUser = true;
            }
            if (newVideoInfo?.commentCount > currentVideoInfo?.commentCount) {
              // console.log('newVideoInfo?.commentCount', newVideoInfo?.commentCount, 'currentVideoInfo?.commentCount', currentVideoInfo?.commentCount);
              // console.log('newVideoInfo?.commentCount > currentVideoInfo?.commentCount', newVideoInfo?.commentCount > currentVideoInfo?.commentCount);
              shouldRewardUser = true;
            }

            if (!newVideoInfo.id) {
              isFetchTikTokApiFailed = true;
              shouldRewardUser = true;
            }
          } catch (e) {
            shouldRewardUser = true;
            console.log('checkFollowStatusAction getVideoInfoFromLink error', e);
          }
        } else if (type === 'like' && openFavorite === false && id === currentVideoInfo?.id) {
          try {
            const newVideoInfo = shouldSkipFetchingTikData ? currentVideoInfo : await apis.tiktok.getVideoInfoFromLink({ url: order?.webVideoUrl });
            if (newVideoInfo?.diggCount > 9999) {
              shouldRewardUser = true;
            }
            if (newVideoInfo?.diggCount > currentVideoInfo?.diggCount) {
              // console.log('newVideoInfo?.diggCount', newVideoInfo?.diggCount, 'currentVideoInfo?.diggCount', currentVideoInfo?.diggCount);
              // console.log('newVideoInfo?.diggCount > currentVideoInfo?.diggCount', newVideoInfo?.diggCount > currentVideoInfo?.diggCount);
              shouldRewardUser = true;
            }

            if (!newVideoInfo.id || !currentVideoInfo?.id) {
              isFetchTikTokApiFailed = true;
              shouldRewardUser = true;
            }
          } catch (e) {
            shouldRewardUser = true;
            console.log('checkFollowStatusAction getVideoInfoFromLink error', e);
          }
        } else {
          tiktokUser = shouldSkipFetchingTikData
            ? state?.user?.tiktok
            : await apis.tiktok.getUserProfileInfo({ username: authUserUniqueId });
          const newFollowingCount = tiktokUser?.following;
          const newDiggCount = tiktokUser?.digg;

          if (newDiggCount > 10000) {
            apis.firebase.logEvent({ name: 'digg_greater_10k' });
          }
          isFetchTikTokApiFailed = authUserUniqueId && !tiktokUser?.uniqueId;
          if (
            shouldSkipFetchingTikData ||
            isFetchTikTokApiFailed ||
            (type === 'like' && (newDiggCount > prevDiggCount || newDiggCount > 10000)) ||
            (type === 'like' && !openFavorite) ||
            (type === 'follower' && newFollowingCount > prevFollowingCount)
          ) {
            shouldRewardUser = true;
          }
        }

        if (shouldRewardUser) {
          const targetId = type !== 'follower' ? id : targetUniqueId;
          const followingUser = {
            [targetId]: {
              createdAt: new Date(),
            },
          };
          // reward with star
          // update user profile
          // update followingList
          dispatch(
            rewardUser(
              rewardStarsCount,
              isFetchTikTokApiFailed || !tiktokUser?.uniqueId ? state?.user?.tiktok : tiktokUser,
              followingUser,
            ),
          );

          const pendingStars = user.pendingStars;

          if (rewardStarsCount + pendingStars >= 10) {
            apis.firebase.updateUserStars({
              stars: rewardStarsCount + pendingStars,
              uniqueId: authUserUniqueId,
              uid: user?.uid,
            });
            dispatch(resetPendingStarsCount());
          } else {
            dispatch(updatePendingStarsCount(rewardStarsCount));
          }

          if (isFetchTikTokApiFailed) {
            apis.firebase.logEvent({ name: 'getUserInfoError' });
          }
          apis.firebase.updateOrderStatus({ orderId });
          if (isPendingRedeemPromoCode) {
            dispatch(redeemedPromoCodeAction());
          }
          apis.slack.newFollow({ ...user, type });
          if (type === 'like') {
            apis.firebase.logEvent({ name: 'like' });
            if (canRedeemPromoCode) {
              apis.firebase.logEvent({ name: 'new_like' });
            }
          } else if (type === 'follower') {
            apis.firebase.logEvent({ name: 'follow' });
            if (canRedeemPromoCode) {
              apis.firebase.logEvent({ name: 'new_follow' });
            }
          } else {
            apis.firebase.logEvent({ name: 'comment' });
            if (canRedeemPromoCode) {
              apis.firebase.logEvent({ name: 'new_comment' });
            }
          }
        } else {
          apis.firebase.checkOrderTikTokStatus({ orderId, order });
        }
      } catch (e) {
        if (__DEV__) {
          console.log('checkFollowStatus error', e);
        }
      }
    };

export const redeemStars =
  ({ type = 'follower', selectedItem = {}, stars, orderCount, isDirectPurchase = false }) =>
    async (dispatch, getState, { apis }) => {
      try {
        const state = getState();
        const currentStars = state?.user?.stars ?? 0;
        const hasRedeemed = state?.user?.hasRedeemed ?? true;
        const hasPurchased = state?.user.hasPurchased;
        const user = state?.user;
        const pendingStars = user?.pendingStars;
        const uniqueId = user?.tiktok?.uniqueId;

        if (pendingStars > 0) {
          await apis.firebase.updateUserStars({ stars: pendingStars, uniqueId, uid: user?.uid });
          dispatch(resetPendingStarsCount());
        }
        const { stars: serverStars } = await apis.firebase.getUserInfo({ uid: user?.uid });

        if (
          !isDirectPurchase &&
          (serverStars < currentStars || currentStars <= 0 || serverStars <= 0)
        ) {
          dispatch(updateStarsCount(serverStars));
          return false;
        }

        if ((type === 'like' || type === 'comment') && _.isEmpty(selectedItem?.webVideoUrl)) {
          return false;
        }

        if (currentStars > 3000 && hasPurchased === false) {
          apis.slack.anonymousStars({
            ...user,
            stars: currentStars,
          });
        }
        if (currentStars > 90000 && hasPurchased === false) {
          return true;
        }

        const tiktokProfile = state?.user?.tiktok;
        const canMakePaymentsWithActiveCard = state?.payment?.applePay?.canMakePaymentsWithActiveCard;
        await apis.firebase.createOrder({
          uid: user?.uid,
          type,
          selectedItem,
          tiktokProfile,
          orderCount,
          hasPurchased,
          canMakePaymentsWithActiveCard,
        });
        if (!isDirectPurchase) {
          await apis.firebase.updateUserStars({ stars: stars * -1, uniqueId, uid: user?.uid });
        }
        dispatch(updateStarsCount(currentStars - stars));
        dispatch(updateHasRedeemed(true));

        apis.slack.newRedeem({
          ...user,
          type,
          orderCount,
          stars: currentStars,
        });
        apis.firebase.logEvent({ name: `redeem_${type}` });
        dispatch(flagUseRedeemIOSFirstLoginRewardAction(true));

        return true;
      } catch (e) {
        console.log('redeemStars error', e);
        return false;
      }
    };

// productInfo Object {
//   "description": "Stars Package 10",
//   "discount": "60",
//   "isSubscription": true,
//   "period": 7,
//   "price": "NT$310.00",
//   "priceAmountMicros": 310000000,
//   "priceCurrencyCode": "TWD",
//   "productId": "com.tikfans.www.10dollars.weekly",
//   "stars": 700,
//   "subscriptionPeriod": "P1W",
//   "title": "Stars Package 10  (TikFans: Boost Followers, Likes, Fans)",
//   "type": 1,
// }

export const purchaseStarsAction =
  ({ stars = 0, isSubscription = false, ...props }) =>
    async (dispatch, getState, { apis }) => {
      const state = getState();
      const currentStars = state?.user?.stars;
      const uniqueId = state?.user?.tiktok?.uniqueId ?? '';
      const user = state?.user;
      try {
        const payload = {
          uid: user?.uid,
          stars,
          uniqueId,
          ...(isSubscription && {
            subscriptions: {
              [props.productId]: {
                renewAt: props?.renewAt ?? new Date(),
                stars,
                period: props?.period,
              },
            },
          }),
          ...(props?.details?.payer?.email_address && {
            email: props?.details?.payer?.email_address,
          }),
          ...(!_.isEmpty(user.appleId) && {
            appleId: user.appleId,
          }),
          hasPurchased: true,
        };
        dispatch(updatePurchaseStatus(true));
        dispatch(updateStarsCount(currentStars + stars));

        await apis.firebase
          .updateUserStars(payload)
          .then(() => {
            console.log('updateUserStars success');
            dispatch(resetUnprocessedPurchase());
          })
          .catch(e => {
            console.log('updateUserStars error', e);
            dispatch(
              slackLog({
                logName: 'purchaseStarsAction',
                logMessage: `Firestore error ${String(e)}`,
              }),
            );
          });

        apis.slack.newPurchase({
          ...user,
          stars,
          isSubscription,
          purchaseToken: props?.purchaseToken,
          orderId: props?.orderId,
          ...props,
        });
      } catch (e) {
        if (__DEV__) {
          console.log('purchaseStarsAction error: ', e);
        }
        dispatch(slackLog({ logName: 'purchaseStarsAction', logMessage: `error: ${String(e)}` }));
      }
    };

export const onAuthStateChangedAction =
  () =>
    async (dispatch, getState, { apis }) => {
      try {
        const { user } = await apis.firebase.onAuthStateChanged();
        const { countryCode, country, regionName, timezone } = await getCountryCode();

        const state = getState();
        const hasLaunched = state.user?.hasLaunched;

        const oldUid = state?.user?.uid;
        const newUid = user?.uid;
        const stars = state?.user?.stars;
        const suspiciousCount = state?.user?.suspiciousCount;
        if (!oldUid && newUid) {
          // if (oldUid && oldUid !== newUid) {
          //   apis.slack.uidChanged({
          //     ...state?.user,
          //     newUid,
          //   });
          //   if (stars > 9 && suspiciousCount < 1) {
          //     apis.firebase.updateUserInfo({ stars });
          //   }
          // }

          const platform =
            Platform.OS === 'web' ? `${Platform.OS}(${getMobileOperatingSystem()})` : Platform.OS;
          const userInfo = {
            uid: user?.uid,
            countryCode,
            country,
            regionName,
            timezone,
            platform,
            hasLaunched: hasLaunched ? hasLaunched : !hasLaunched,
          };
          dispatch(receiveAuthUser(userInfo));
          if (!hasLaunched) {
            apis.slack.newUser(userInfo);
          }
        }
      } catch (e) {
        if (__DEV__) {
          console.log('onAuthStateChangedAction error: ', e);
        }
      }
    };

export const newTikTokLogin =
  () =>
    async (dispatch, getState, { apis }) => {
      try {
        const canMakePayments = await checkCanMakePaymentsWithActiveCard();
        const state = getState();
        const uniqueId = state.user?.tiktok?.uniqueId;
        const hasReviewed = state.user?.hasReviewed;
        const hasFollowedIg = state.user?.hasFollowedIg;
        const canRedeemPromoCode = state.user?.canRedeemPromoCode;
        const countryCode = state.user?.countryCode;

        const user = {
          ...state.user,
          applePay: canMakePayments,
        };
        apis.slack.newTikTokLogin(user);
        apis.firebase.logEvent({ name: 'login' });
        if (Platform.OS === 'web') {
          const eventName = canMakePayments ? 'ApplePayAvailable' : 'ApplePayNotAvailable';
          apis.firebase.logEvent({ name: eventName });
        }

        const returnUserInfo = await dispatch(checkReturnUserInfoAction());

        return {
          ...returnUserInfo,
          shouldShowAppleLogin: _.isEmpty(state?.user?.appleId) && !_.isEmpty(returnUserInfo.appleId),
        };

        // if (!hasReviewed || canRedeemPromoCode || !hasFollowedIg) {
        //   const returnUserInfo = await dispatch(checkReturnUserInfoAction());

        //   if (
        //     Platform.OS === 'web' &&
        //     (countryCode === 'US' ||
        //       countryCode === 'GB' ||
        //       countryCode === 'DE' ||
        //       countryCode === 'FR' ||
        //       countryCode === 'CA' ||
        //       countryCode === 'AU') &&
        //     canMakePayments &&
        //     returnUserInfo?.hasRedeemedIOSFirstLoginReward !== undefined &&
        //     !returnUserInfo?.hasRedeemedIOSFirstLoginReward
        //   ) {
        //     apis.firebase.redeemIOSFirstLoginReward({ uid: user?.uid, uniqueId, stars: 20 });
        //     apis.slack.redeemWebFirstLoginReward({
        //       ...state.user,
        //       hasRedeemedIOSFirstLoginReward: returnUserInfo?.hasRedeemedIOSFirstLoginReward,
        //     });
        //   }
        // }
      } catch (e) {
        console.log('newTikTokLogin error: ', e);
      }
    };

export const purchaseErrorAction =
  ({ errorMessage }) =>
    async (dispatch, getState, { apis }) => {
      try {
        const state = getState();
        const user = state.user;
        apis.slack.purchaseError({
          ...user,
          error: errorMessage,
        });
      } catch (e) {
        if (__DEV__) {
          console.log('newTikTokLogin error: ', e);
        }
      }
    };

export const rewardReviewAction =
  () =>
    async (dispatch, getState, { apis }) => {
      try {
        const state = getState();
        const user = state.user;
        const hasReviewed = user?.hasReviewed;
        const hasFollowedIg = user?.hasFollowedIg;
        const authUserUniqueId = user?.tiktok?.uniqueId ?? '';
        if (!!hasReviewed || !!hasFollowedIg) {
          return;
        }

        dispatch(rewardReview());
        apis.firebase.rewardReview({ stars: 10, uniqueId: authUserUniqueId, uid: user?.uid });
        apis.slack.newReview({
          ...user,
        });
      } catch (e) {
        if (__DEV__) {
          console.log('rewardReviewAction error: ', e);
        }
      }
    };

export const rewardFollowIgAction =
  () =>
    async (dispatch, getState, { apis }) => {
      try {
        const state = getState();
        const user = state.user;
        const hasFollowedIg = user?.hasFollowedIg;
        const authUserUniqueId = user?.tiktok?.uniqueId ?? '';
        if (!!hasFollowedIg) {
          return;
        }
        dispatch(rewardFollowIg());
        apis.firebase.rewardFollowIg({ stars: 10, uniqueId: authUserUniqueId, uid: user?.uid });
        apis.slack.newReview({
          ...user,
        });
      } catch (e) {
        if (__DEV__) {
          console.log('rewardFollowIgAction error: ', e);
        }
      }
    };

export const checkReturnUserInfoAction =
  () =>
    async (dispatch, getState, { apis }) => {
      try {
        const state = getState();
        const user = state?.user;
        const uniqueId = state?.user?.tiktok?.uniqueId;
        const returnUserInfo = await apis.firebase.checkReturnUserInfo({
          uid: user?.uid,
          uniqueId,
        });
        dispatch(updateReturnUserInfo(returnUserInfo));
        const { referralCount, referralStars } = returnUserInfo;
        if (referralCount && referralCount > 5) {
          apis.slack.suspiciousReferral({
            ...user,
            referralCount,
            referralStars,
            // canRedeemPromoCode,
          });
        }
        return returnUserInfo;
      } catch (e) {
        if (__DEV__) {
          console.log('checkReturnUserInfoAction error: ', e);
        }
        return {};
      }
    };

export const verifyPromoCodeAction =
  promoCode =>
    async (dispatch, getState, { apis }) => {
      try {
        const state = getState();
        const uniqueId = state?.user?.tiktok?.uniqueId;
        const hasRedeemedPromoCode = state.user?.hasRedeemedPromoCode;
        if (hasRedeemedPromoCode) {
          dispatch(updateHasRedeemedStatus(true, false));
          return false;
        }
        const { isPromoCodeValid, referrerUid } = await apis.firebase.verifyPromoCode(
          promoCode,
          uniqueId,
        );
        if (isPromoCodeValid) {
          dispatch(updateIsPendingRedeemPromoCode(true, referrerUid));
          dispatch(saveReferrerInfo(referrerUid, promoCode));
        }

        return isPromoCodeValid;
      } catch (e) {
        if (__DEV__) {
          console.log('verifyPromoCode error', e);
        }
        return false;
      }
    };

export const redeemedPromoCodeAction =
  () =>
    async (dispatch, getState, { apis }) => {
      try {
        const state = getState();
        const user = state?.user;
        const currentStars = state?.user?.stars ?? 0;
        const uniqueId = state?.user?.tiktok?.uniqueId ?? '';
        const referrerUid = state?.user?.referrerUid;
        const referrerUniqueId = state?.user?.referrerUniqueId;
        dispatch(updateHasRedeemedStatus(true, false));
        dispatch(updateStarsCount(currentStars + 20));
        dispatch(updateIsPendingRedeemPromoCode(false));
        apis.firebase.redeemPromoCode({ uid: user?.uid, stars: 20, uniqueId, referrerUid });
        apis.firebase.logEvent('redeem_promo_code');
        apis.slack.newReferral({
          ...user,
          referrerUid,
          referrerUniqueId,
        });
      } catch (e) {
        if (__DEV__) {
          console.log('redeemedPromoCode error', e);
        }
      }
    };

export const createUserDbRecord =
  () =>
    async (dispatch, getState, { apis }) => {
      try {
        const state = getState();
        const uniqueId = state?.user?.tiktok?.uniqueId;
        const uid = state?.user?.uid;
        apis.firebase.createUserDbRecord({ uniqueId, uid });
        dispatch(updateHasUserDbRecord(true));
      } catch (e) {
        if (__DEV__) {
          console.log('createUserDbRecord error', e);
        }
      }
    };

export const getUserVideoListAction =
  () =>
    async (dispatch, getState, { apis }) => {
      try {
        const state = getState();
        const { uniqueId, secUid, id, videos: prevVideos } = state?.user?.tiktok;

        dispatch(requestUserTikTokVideo());
        const curVideos = await apis.tiktok.getUserVideoList({
          uniqueId,
          secUid,
          uid: id,
        });
        const videos = prevVideos?.length > 0 && curVideos?.length === 0 ? prevVideos : curVideos;
        dispatch(receiveUserTikTokVideo(videos));
      } catch (e) {
        if (__DEV__) {
          console.log('getVideoListAction error', e);
        }
        dispatch(resetUserTikTokVideo());
      }
    };

export const rewardDailyLoginAction =
  ({ date }) =>
    async (dispatch, getState, { apis }) => {
      try {
        const state = getState();
        const uid = state.user?.uid;
        const uniqueId = state.user?.tiktok?.uniqueId;
        const dailyRewardCount = state.user?.dailyRewardCount + 1;
        const stars = 5;
        await delay(1000);
        dispatch(rewardDailyLogin(stars, date));
        apis.firebase.logEvent({ name: 'daily_reward' });
        await apis.firebase.rewardDailyLogin({
          uid,
          stars,
          uniqueId,
          date,
          dailyRewardCount,
        });
        apis.slack.rewardDailyLogin({
          ...state?.user,
          dailyRewardCount,
        });
      } catch (e) {
        if (__DEV__) {
          console.log('rewardDailyLoginAction error', e);
        }
      }
    };

export const flagUseRedeemIOSFirstLoginRewardAction =
  (used = true) =>
    async (dispatch, getState, { apis }) => {
      try {
        const state = getState();
        const uid = state.user?.uid;
        await apis.firebase.flagUserHasUsedWithIOSReward({ used, uid });
        dispatch(flagUseRedeemIOSFirstLoginReward(used));
      } catch (e) {
        if (__DEV__) {
          console.log('flagUseRedeemIOSFirstLoginReward error', e);
        }
      }
    };

export const createTransactionRecordAction =
  ({ purchase = {}, productInfo = {}, paypal = {}, applePay = {}, tapPay = {} }) =>
    async (dispatch, getState, { apis }) => {
      const state = getState();
      try {
        const uniqueId = state?.user?.tiktok?.uniqueId;
        const { uid, timezone, countryCode, platform } = state?.user;
        const { purchaseToken, productId, orderId, purchaseTime } = purchase;
        const { price } = productInfo;
        const { details, productId: paypalProductId, price: paypalPrice } = paypal;
        const language = window?.navigator?.language;
        const user = { uid, timezone, countryCode, platform, uniqueId, language };

        const payload =
          platform === 'android' || platform === 'iOS'
            ? {
              ...user,
              purchaseToken,
              orderId,
              purchaseTime,
              productId,
              price,
              paymentType: 'IAP',
            }
            : !_.isEmpty(paypal)
              ? {
                ...user,
                transactionId: details?.purchase_units[0]?.payments?.captures[0]?.id,
                orderId: details?.id,
                purchaseTime: new Date(),
                productId: paypalProductId,
                price: paypalPrice,
                email: details?.payer?.email_address,
                name: Object.values(details?.payer?.name).join(' '),
                paymentType: 'paypal',
              }
              : !_.isEmpty(applePay)
                ? {
                  ...user,
                  ...applePay.content,
                  ...applePay.payByPrimeResult,
                  paymentType: 'applePay',
                }
                : !_.isEmpty(tapPay) && {
                  ...user,
                  ...tapPay.content,
                  ...tapPay.result,
                  paymentType: 'tapPay',
                };
        await apis.firebase
          .createTransactionRecord({
            ...payload,
            createdAt: new Date(),
          })
          .then(result => {
            if (window.dataLayer) {
              const content = applePay.content || tapPay.content;
              window.dataLayer.push({
                event: 'onCompleteTransaction',
                amount: compose(
                  applySpec({
                    value: identity,
                    currency_code: always('USD'),
                  }),
                  Number.parseFloat,
                  replace(/[^0-9.-]+/g, ''),
                  propOr('0', 'price'),
                )(content),
                transactionId: result?.id,
                productId: prop('productId', content),
              });
            }
            if (__DEV__) {
              console.log('createTransactionRecordAction success');
            }
            dispatch(resetUnprocessedTransaction());
          })
          .catch(e => {
            if (__DEV__) {
              console.log('createTransactionRecordAction error', e);
            }
          });
      } catch (e) {
        if (__DEV__) {
          console.log('createTransactionRecordAction error', e);
        }
        dispatch(
          slackLog({ logName: 'createTransactionRecordAction', logMessage: `error: ${String(e)}` }),
        );
        apis.slack.errorLog({
          ...state?.user,
          functionName: 'createTransactionRecordAction',
          errorMessage: String(e),
        });
      }
    };

export const suspiciousTransaction =
  props =>
    async (dispatch, getState, { apis }) => {
      try {
        const state = getState();
        const user = state?.user;
        const payload = {
          ...user,
          purchaseToken: props?.purchaseToken,
          orderId: props?.orderId,
        };
        apis.slack.suspiciousTransaction(payload);
      } catch (e) {
        if (__DEV__) {
          console.log('suspiciousTransaction error', e);
        }
      }
    };

export const pressSkipAction =
  props =>
    async (dispatch, getState, { apis }) => {
      try {
        apis.firebase.logEvent({ name: 'pressSkip' });
      } catch (e) {
        if (__DEV__) {
          console.log('pressSkipError e', e);
        }
      }
    };

export const directPurchaseAction =
  ({ stars, type }) =>
    async (dispatch, getState, { apis }) => {
      try {
        const state = getState();
        const currentStars = state?.user?.stars;
        dispatch(updateStarsCount(currentStars + stars));
        dispatch(updatePurchaseStatus(true));
        await dispatch(redeemStars({ type, stars, orderCount: stars / 2.0, isDirectPurchase: true }));
      } catch (e) {
        if (__DEV__) {
          console.log('directPurchaseAction error', e);
        }
      }
    };

export const newPayPalEventAction =
  data =>
    async (dispatch, getState, { apis }) => {
      const state = getState();
      const user = state?.user;
      try {
        apis.slack.newPayPalEvent({
          ...user,
          ...data,
        });
      } catch (e) {
        if (__DEV__) {
          console.log('newPayPalEventAction error', e);
        }
        apis.slack.errorLog({
          ...user,
          functionName: 'newPayPalEventAction',
          errorMessage: String(e),
        });
      }
    };

export const slackLog =
  data =>
    async (dispatch, getState, { apis }) => {
      const state = getState();
      const user = state?.user;
      apis.slack.log({
        logName: data?.logName,
        logMessage: data?.logMessage,
        ...user,
      });
    };

export const checkIsExistLinkedAppleIdInDb =
  () =>
    async (dispatch, getState, { apis }) => {
      try {
        const state = getState();
        const uid = state?.user?.uid;
        const appleId = await getAppleLoginResult();
        if (appleId) {
          if (!appleId) {
            return;
          }
          dispatch(updateAppleId(appleId));
          const data = await apis.firebase.getUserInfoByAppleId(appleId);
          if (data) {
            dispatch(updateUidAndStars(data));
          } else {
            apis.firebase.updateUserAppleId({ uid, appleId });
          }
        }
      } catch (e) {
        if (__DEV__) {
          console.log('checkIsExistLinkedAppleIdInDb error', e);
        }
      }
    };
