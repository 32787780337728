import { useLinking } from '@react-navigation/native';
import { Linking } from 'expo';

export default function (containerRef) {
  return useLinking(containerRef, {
    prefixes: [Linking.makeUrl('/')],
    config: {
      Root: {
        path: 'root',
        screens: {
          HomeStack: 'home',
          RedeemStack: 'redeem',
          RedeemLikesStack: 'redeemLikes',
          PurchaseStack: 'purchase',
          SettingsStack: 'settings'
        },
      },
      Login: {
        path: 'login',
        screens: {
          Login: 'login',
        }
      }
    },
  });
}
