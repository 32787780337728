import {
  REQUEST_TIKTOK_USER,
  RECEIVE_TIKTOK_USER,
  RESET_TIKTOK_USER,
  RECEIVE_AUTH_USER,
  REQUEST_USER_TIKTOK_VIDEOS,
  RECEIVE_USER_TIKTOK_VIDEOS,
  RESET_USER_TIKTOK_VIDEOS,
  UPDATE_STARS_COUNT,
  REWARD_USER,
  REWARD_REVIEW,
  UPDATE_RETURN_USER_INFO,
  UPDATE_PURCHASE_STATUS,
  UPDATE_HAS_REDEEMED_PROMO_CODE,
  UPDATE_IS_PENDING_REDEEM_PROMO_CODE,
  SAVE_REFERRER_INFO,
  UPDATE_USER_DB_RECORD,
  FOUND_SUSPICIOUS_ACCOUNT,
  UPDATE_SHOW_SUSPICIOUS_ALERT,
  REWARD_DAILY_LOGIN,
  REWARD_FOLLOW_IG,
  FLAG_USE_REDEEM_IOS_FIRST_LOGON_REWARD,
  UPDATE_PENDING_STARS_COUNT,
  RESET_PENDING_STARS_COUNT,
  UPDATE_SHOULD_RELOAD_USER_PROFILE,
  UPDATE_HAS_REDEEMED,
  UPDATE_APPLE_ID,
  UPDATE_UID_AND_STARS,
  UPDATE_SHOW_LOGIN_SCREEN_COUNT,
} from 'actions/userActions';

import createReducers from './createReducers';
import { tiktokUserDataToProfile } from 'utils/tiktokUtils';

const initialState = {
  hasUserDbRecord: false,
  hasLaunched: false,
  hasReviewed: false,
  hasFollowedIg: false,
  hasPurchased: false,
  hasRedeemedPromoCode: false,
  hasRedeemed: false,
  canRedeemPromoCode: true,
  isFetchingUser: false,
  isFetchingUserError: false,
  ifFetchingUserTikTokVideos: false,
  isPendingRedeemPromoCode: false,
  showSuspiciousAlert: false,
  stars: 0,
  pendingStars: 0,
  suspiciousCount: 0,
  tiktok: {},
  followingList: {},
  dailyRewardCount: 0,
  appleId: '',
  showLoginCount: 0,
};

export default createReducers(initialState, {
  [REQUEST_TIKTOK_USER]: (state, action) => ({
    ...state,
    isFetchingUser: true,
    isFetchingUserError: false,
  }),
  [RECEIVE_TIKTOK_USER]: (state, action) => ({
    ...state,
    isFetchingUser: false,
    isFetchingUserError: false,
    tiktok: {
      ...state.tiktok,
      ...tiktokUserDataToProfile(action.user),
    },
  }),
  [RESET_TIKTOK_USER]: (state, action) => ({
    ...state,
    isFetchingUser: false,
    isFetchingUserError: true,
  }),
  [REQUEST_USER_TIKTOK_VIDEOS]: (state, action) => ({
    ...state,
    ifFetchingUserTikTokVideos: true,
  }),
  [RECEIVE_USER_TIKTOK_VIDEOS]: (state, action) => ({
    ...state,
    ifFetchingUserTikTokVideos: false,
    tiktok: {
      ...state.tiktok,
      videos: action.videos,
    },
  }),
  [RESET_USER_TIKTOK_VIDEOS]: (state, action) => ({
    ...state,
    ifFetchingUserTikTokVideos: false,
    tiktok: {
      ...state.tiktok,
      videos: [],
    },
  }),
  [RECEIVE_AUTH_USER]: (state, action) => ({
    ...state,
    ...action.userInfo,
  }),
  [UPDATE_STARS_COUNT]: (state, action) => ({
    ...state,
    stars: parseInt(action.stars),
  }),
  [REWARD_USER]: (state, action) => ({
    ...state,
    stars: parseInt(state.stars + action.stars),
    tiktok: action.tiktokUser?.id ? action.tiktokUser : tiktokUserDataToProfile(action.tiktokUser),
    followingList: { ...state.followingList, ...action.followingUser },
  }),
  [REWARD_REVIEW]: (state, action) => ({
    ...state,
    stars: parseInt(state.stars + 10),
    hasReviewed: true,
  }),
  [REWARD_FOLLOW_IG]: (state, action) => ({
    ...state,
    stars: parseInt(state.stars + 10),
    hasFollowedIg: true,
  }),
  [UPDATE_RETURN_USER_INFO]: (state, action) => ({
    ...state,
    hasReviewed: action.returnUserInfo?.hasReviewed,
    hasFollowedIg: action.returnUserInfo?.hasFollowedIg,
    hasRedeemedPromoCode: action.returnUserInfo?.hasRedeemedPromoCode,
    canRedeemPromoCode: action.returnUserInfo?.canRedeemPromoCode,
    hasRedeemedIOSFirstLoginReward: action.returnUserInfo?.hasRedeemedIOSFirstLoginReward,
    hasRedeemedIOSFirstLoginRewardUsed: action.returnUserInfo?.hasRedeemedIOSFirstLoginRewardUsed,
  }),
  [FLAG_USE_REDEEM_IOS_FIRST_LOGON_REWARD]: (state, action) => ({
    ...state,
    hasRedeemedIOSFirstLoginRewardUsed: action.used,
  }),
  [UPDATE_PURCHASE_STATUS]: (state, action) => ({
    ...state,
    hasPurchased: action.hasPurchased || state?.hasPurchased,
  }),
  [UPDATE_HAS_REDEEMED_PROMO_CODE]: (state, action) => ({
    ...state,
    hasRedeemedPromoCode: action.hasRedeemedPromoCode,
    canRedeemPromoCode: action.canRedeemPromoCode,
  }),
  [UPDATE_IS_PENDING_REDEEM_PROMO_CODE]: (state, action) => ({
    ...state,
    isPendingRedeemPromoCode: action.isPendingRedeemPromoCode,
  }),
  [SAVE_REFERRER_INFO]: (state, action) => ({
    ...state,
    referrerUid: action.referrerUid,
    referrerUniqueId: action.referrerUniqueId,
  }),
  [UPDATE_USER_DB_RECORD]: (state, action) => ({
    ...state,
    hasUserDbRecord: action.hasUserDbRecord,
  }),
  [FOUND_SUSPICIOUS_ACCOUNT]: (state, action) => ({
    ...state,
    suspiciousCount: state.suspiciousCount + 1,
    showSuspiciousAlert: true,
  }),
  [UPDATE_SHOW_SUSPICIOUS_ALERT]: (state, action) => ({
    ...state,
    showSuspiciousAlert: action.showSuspiciousAlert,
  }),
  [REWARD_DAILY_LOGIN]: (state, action) => ({
    ...state,
    dailyReward: action.date,
    dailyRewardCount: state.dailyRewardCount + 1,
    dailyRewardBeganAt: state.dailyRewardBeganAt ? state.dailyRewardBeganAt : action.date,
    stars: parseInt(state.stars + action.stars),
  }),
  [UPDATE_PENDING_STARS_COUNT]: (state, action) => ({
    ...state,
    pendingStars: state.pendingStars + parseInt(action.stars),
  }),
  [RESET_PENDING_STARS_COUNT]: (state, action) => ({
    ...state,
    pendingStars: 0,
  }),
  [UPDATE_SHOULD_RELOAD_USER_PROFILE]: (state, action) => ({
    ...state,
    shouldReloadUserProfile: action.shouldReloadUserProfile,
  }),
  [UPDATE_HAS_REDEEMED]: (state, action) => ({
    ...state,
    hasRedeemed: action.hasRedeemed,
  }),
  [UPDATE_APPLE_ID]: (state, action) => ({
    ...state,
    appleId: action?.appleId,
  }),
  [UPDATE_UID_AND_STARS]: (state, action) => ({
    ...state,
    stars: parseInt(state.stars + action?.data?.stars ?? 0),
    uid: action?.data?.uid ?? state.uid,
  }),
  [UPDATE_SHOW_LOGIN_SCREEN_COUNT]: (state, action) => ({
    ...state,
    showLoginCount: state.showLoginCount + 1,
  }),
});
