import _ from 'lodash';
import makeActionCreator from './makeActionCreator';
import * as ApplePay from 'modules/ApplePay';
import { Platform } from 'react-native';
import * as InAppPurchases from 'expo-in-app-purchases';

import { IAP_PRODUCTS } from 'constants/Products';

export const RECEIVE_APPLE_PAY_AVAILABILITY = 'RECEIVE_APPLE_PAY_AVAILABILITY';
export const RECEIVE_PURCHASE_HISTORY = 'RECEIVE_PURCHASE_HISTORY';

export const UNPROCESSED_PURCHASE = 'UNPROCESSED_PURCHASE';
export const UNPROCESSED_TRANSACTION = 'UNPROCESSED_TRANSACTION';
export const RESET_UNPROCESSED_PURCHASE = 'RESET_UNPROCESSED_PURCHASE';
export const RESET_UNPROCESSED_TRANSACTION = 'RESET_UNPROCESSED_TRANSACTION';

const receiveApplePayAvailability = makeActionCreator(RECEIVE_APPLE_PAY_AVAILABILITY, 'applePayAvailability');
const receivePurchaseHistory = makeActionCreator(RECEIVE_PURCHASE_HISTORY, 'history');

export const unprocessedPurchaseAction = makeActionCreator(UNPROCESSED_PURCHASE, 'purchase');
export const unprocessedTransactionAction = makeActionCreator(UNPROCESSED_TRANSACTION, 'transaction');

export const resetUnprocessedPurchase = makeActionCreator(RESET_UNPROCESSED_PURCHASE);
export const resetUnprocessedTransaction = makeActionCreator(RESET_UNPROCESSED_TRANSACTION);

export const initApplePay = () => async (dispatch, getState, { apis }) => {
  const applePayAvailability = await ApplePay.init();
  dispatch(receiveApplePayAvailability(applePayAvailability));
};

export const connectAppStore = () => async (dispatch, getState, { apis }) => {
  try {
    if (Platform.OS === 'web') {
      return;
    }
    const res = await InAppPurchases.connectAsync();
    const history = (res?.results ?? []).map(result => {
      if (result?.acknowledged === false) {
        const isSubscription = IAP_PRODUCTS[result?.productId]?.isSubscription;
        const consumeItem = !isSubscription;
        InAppPurchases.finishTransactionAsync(result, consumeItem);
      }
      return ({
        ...result,
        ...IAP_PRODUCTS[result.productId],
      });
    });
    dispatch(receivePurchaseHistory(history));
  } catch (e) {
    if (__DEV__) {
      console.log('initIap error: ', e);
    }
    dispatch(receivePurchaseHistory([]));
  }
};

export const syncPurchaseHistory = (productInfo) => async (dispatch, getState, { apis }) => {
  try {
    if (Platform.OS === 'web') {
      return;
    }
    const state = getState();
    const history = [
      ...state?.payment.history,
      productInfo,
    ];
    dispatch(receivePurchaseHistory(history));
  } catch (e) {
    if (__DEV__) {
      console.log('getPurchaseHistory error: ', e);
    }
    dispatch(receivePurchaseHistory([]));
  }
};

export const checkStillUnprocessedPurchase = () => (dispatch, getState, { apis }) => {
  const state = getState();
  const { unprocessedPurchase } = state?.payment;
  return _.isEmpty(unprocessedPurchase);
};

export const checkStillUnprocessedTransaction = () => (dispatch, getState, { apis }) => {
  const state = getState();
  const { unprocessedTransaction } = state?.payment;
  return _.isEmpty(unprocessedTransaction);
};
