import React from 'react';
import { View, Platform } from 'react-native';
import { useDispatch } from 'react-redux';
import { ScrollView } from 'react-native-gesture-handler';
import styled from 'styled-components/native';

import { IAP_PRODUCTS, getProductList } from 'constants/Products';
import { WebDirectPurchaseItem } from 'modules/payment/components/DirectPurchaseItem';
import SegmentedControlTab from 'components/RedeemScreen/SegmentedControlTab';
import Colors from 'constants/Colors';
import { directPurchaseAction } from 'actions/userActions';
import Alert from 'components/Alert';

const initialListContents = getProductList({ purchaseHistory: [] });

const PurchaseItem = Platform.select({
  web: WebDirectPurchaseItem,
  default: WebDirectPurchaseItem,
});

const segmentTypeDict = {
  0: 'follower',
  1: 'like',
};

const DirectPurchaseScreen = props => {
  const dispatch = useDispatch();
  const [listContents, setListContents] = React.useState(initialListContents);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedType, setSelectedType] = React.useState('follower');

  const onTabPress = React.useCallback(
    index => {
      const type = segmentTypeDict[index];
      setSelectedIndex(index);
      setSelectedType(type);
    },
    [selectedIndex],
  );

  return (
    <Container>
      {/* <SegmentedControlTab
        values={["Fans", "likes"]}
        selectedIndex={selectedIndex}
        onTabPress={index => onTabPress(index)}
        style={{ alignSelf: 'center' }}
      /> */}
      <StyledScrollView>
        {listContents.map(content => {
          return (
            <PurchaseItem
              key={content.productId}
              content={content}
              type={selectedType}
              onPayPalCaptureApprove={({ stars, details = {} }) => {
                dispatch(directPurchaseAction({ stars, details }));
                Alert({
                  title: '💜Success💜',
                  body: '💜Success💜',
                  // IS_REVIEWING
                  // body: '🎉🎉🎉🎉',
                  actions: [
                    {
                      text: 'OK',
                      onPress: async () => {
                        // if (!hasPurchased) {
                        //   // showFullScreenAd();
                        //   await delay(1800);
                        //   // promotionAlert();
                        // }
                      },
                    },
                  ],
                  // onConfirm: () => promotionAlert(),
                });
              }}
              onApplePayApprove={({ stars }) => {
                dispatch(directPurchaseAction({ stars, isApplePay: true }));
              }}
              onTapPayCreditCardApprove={({ starts }) => {
                dispatch(purchaseStarsAction({ stars }));
              }}
            />
          );
        })}
      </StyledScrollView>
    </Container>
  );
};

export default DirectPurchaseScreen;

const Container = styled(View)`
  flex: 1;
  /* align-items: center; */
`;

const StyledScrollView = styled(ScrollView).attrs({
  flex: 1,
  backgroundColor: Colors.primary?.lightBlue,
  width: 100,
  height: 100,
  contentContainerStyle: {
    backgroundColor: Colors.primary?.lightBlue,
    alignItems: 'center',
  },
})``;
