import * as React from 'react';
import SegmentedControlTab from "react-native-segmented-control-tab";

import Colors from 'constants/Colors';

const StyleSegmentedControlTab = ({ style, ...props }) => {
  return (
    <SegmentedControlTab
      tabsContainerStyle={{
        width: '90%',
        maxWidth: 358,
        height: 49,
        marginTop: 8,
        ...style,
      }}
      tabStyle={{
        borderWidth: 2,
        backgroundColor: 'transparent',
        borderColor: Colors.primary.blue,
      }}
      activeTabStyle={{
        backgroundColor: Colors.primary.blue,
      }}
      tabTextStyle={{ fontWeight: 'bold', fontSize: 16, color: Colors.primary.blue }}
      {...props}
    />
  );
};

export default StyleSegmentedControlTab;
