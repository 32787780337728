import * as React from 'react';
import { path } from 'ramda';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Image, Text, ImageBackground, TouchableOpacity, View } from 'react-native';
import styled from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';

import { signInWithApple, SUPPORTED_LANG_OF_APPLE_BUTTON } from 'modules/LoginWithApple/index.js';
import CloseButton from 'components/Header/CloseButton';
import i18n from 'i18n';

// Params for using sign in from apple directly(not firebase)
// const params = {
//   client_id: 'com.bumbumapp.tikfans',
//   redirect_uri: 'https://f451-118-166-99-93.ngrok.io',
//   response_type: 'code id_token',
//   response_mode: 'fragment',
// };
// const searchParams = new URLSearchParams(params);

const queryLang =
  Object.values(SUPPORTED_LANG_OF_APPLE_BUTTON).indexOf(navigator.language.replace('-', '_')) === -1
    ? ''
    : `&locale=${navigator.language.replace('-', '_')}`;

const Container = styled(View)`
  flex: 1;
  background-color: ${path(['theme', 'primary', 'darkBlue2'])};
  align-items: center;
`;

const StyledImageBackground = styled(ImageBackground)`
  width: 100%;
  height: 62%;
  margin-top: -20%;
`;

const style = `
  color: #fff;
  text-align: center;
`;

const Title = styled(Text)`
  ${style}
  font-size: 24px;
  font-weight: 800;
  margin-top: 18px;
`;

const Content = styled(Text)`
  ${style}
  font-size: 16px;
  width: 80%;
  margin-top: 16px;
`;

const SkipText = styled(Text)`
  ${style}
  textDecorationLine: underline;
  font-weight: 700;
  margin-top: 22px;
`;

const AppleLoginButton = styled(TouchableOpacity)`
  width: 250px;
  align-items: center;
  margin-top: 44px;
`;

const LeftWrapper = styled(View)`
  position: absolute;
  left: 10;
  top: 30;
`;

const AppleLoginScreen = ({
  route,
  setAppleLoginModalStatus,
  loginType,
  nextRoute,
}) => {
  const [isAppleButtonLoadError, setIsAppleButtonLoadError] = React.useState(false);
  const navigation = useNavigation();

  const type = route?.params?.type || loginType || 'default';

  const onPressClose = () => {
    setAppleLoginModalStatus && setAppleLoginModalStatus({ visible: false });
    if (nextRoute === 'Back') {
      navigation?.goBack();
    }
    if (!_.isEmpty(nextRoute)) {
      navigation.navigate(nextRoute);
    }
  };

  const goToNextScreen = () => {
    if (nextRoute === 'Back') {
      navigation?.goBack();
    }
    if (!_.isEmpty(nextRoute)) {
      navigation.navigate(nextRoute);
    }
  };

  const text =
    type === 'default'
      ? {
        title: i18n.t('login_with_apple_title_1'),
        content: i18n.t('login_with_apple_content_1'),
        skip: i18n.t('login_with_apple_skip_1'),
      }
      : {
        title: i18n.t('login_with_apple_title_2'),
        content: '',
        skip: i18n.t('login_with_apple_skip_2'),
      };

  return (
    <Container>
      <StyledImageBackground
        source={require('assets/images/signinwithapple.png')}
        resizeMode="cover"
      />
      <LeftWrapper>
        <CloseButton onPress={onPressClose} />
      </LeftWrapper>
      <Title>{text.title}</Title>
      <Content>{text.content} </Content>
      <AppleLoginButton
        onPress={async () => {
          // Usage of sign in from apple directly(not firebase)
          // const url = `https://appleid.apple.com/auth/authorize?${searchParams}`;
          // const supported = await Linking.canOpenURL(url);
          // if (supported) {
          //   Linking.openURL(url);
          // }
          signInWithApple();
          goToNextScreen();
        }}
      >
        <Image
          style={{ maxWidth: 330, height: 48, width: '100%', borderRadius: 20 }}
          source={
            isAppleButtonLoadError
              ? require('assets/images/appleid_button@2x.png')
              : {
                uri: `https://appleid.cdn-apple.com/appleid/button?width=330&height=48&scale=2&color=white${queryLang}`,
              }
          }
          onError={() => setIsAppleButtonLoadError(true)}
        />
      </AppleLoginButton>
      <SkipText onPress={onPressClose}>{text.skip}</SkipText>
    </Container>
  );
};

export default AppleLoginScreen;
