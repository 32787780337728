import * as React from "react";
import { createStackNavigator } from "@react-navigation/stack";

import PurchaseScreen from "screens/PurchaseScreen";
import StarsButton from "components/Header/StarsButton";
import QuestionButton from "components/Header/QuestionButton";
import i18n from "i18n";

const Stack = createStackNavigator();

const PurchaseStack = ({ route }) => {
  return (
    <Stack.Navigator initialRouteName="Purchase">
      <Stack.Screen
        name="Purchase"
        component={PurchaseScreen}
        options={({ navigation, route }) => {
          return {
            title: i18n.t("purchase_title"),
            // headerLeft: () => <QuestionButton />,
            headerRight: () => <StarsButton />,
            headerTitleAlign: "center",
          };
        }}
      />
    </Stack.Navigator>
  );
};

export default PurchaseStack;
