import {
  RECEIVE_APPLE_PAY_AVAILABILITY,
  RECEIVE_PURCHASE_HISTORY,
  UNPROCESSED_PURCHASE,
  UNPROCESSED_TRANSACTION,
  RESET_UNPROCESSED_PURCHASE,
  RESET_UNPROCESSED_TRANSACTION
} from 'actions/paymentActions';

import createReducers from './createReducers';

const initialState = {
  applePay: {
    canMakePayments: false,
    canMakePaymentsWithActiveCard: false,
  },
  history: [],
  unprocessedPurchase: {},
  unprocessedTransaction: {},
};

export default createReducers(initialState, {
  [RECEIVE_APPLE_PAY_AVAILABILITY]: (state, action) => ({
    ...state,
    applePay: {
      ...state?.applePay,
      canMakePayments: action?.applePayAvailability?.canMakePayments,
      canMakePaymentsWithActiveCard: action?.applePayAvailability?.canMakePaymentsWithActiveCard,
    }
  }),
  [RECEIVE_PURCHASE_HISTORY]: (state, action) => ({
    ...state,
    history: action.history,
  }),
  [UNPROCESSED_PURCHASE]: (state, action) => ({
    ...state,
    unprocessedPurchase: action.purchase,
  }),
  [UNPROCESSED_TRANSACTION]: (state, action) => ({
    ...state,
    unprocessedTransaction: action.transaction,
  }),
  [RESET_UNPROCESSED_PURCHASE]: (state, action) => ({
    ...state,
    unprocessedPurchase: {},
  }),
  [RESET_UNPROCESSED_TRANSACTION]: (state, action) => ({
    ...state,
    unprocessedTransaction: {},
  }),
});