import * as React from 'react';
import {
  View,
  Image,
  Text,
  Platform,
  TouchableOpacity,
  SafeAreaView
} from 'react-native';
import { path } from 'ramda';
import { ScrollView } from 'react-native-gesture-handler';
import styled from 'styled-components/native';
import Constants from 'expo-constants';
import Colors from 'constants/Colors';

import Header from 'components/Header/Header';
import CloseButton from 'components/Header/CloseButton';
import i18n from 'i18n';

const TutorialScreen = ({ navigation }) => {
  const goToHome = React.useCallback(() => {
    navigation.pop();
  }, [])
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#FFFFFF', }}>
      <Header>
        <CloseButton onPress={() => goToHome()} />
      </Header>
      <StyledScrollView>
        <Title>{i18n.t('tutorial_title')}</Title>
        {/* <H3>Steps</H3> */}
        {/* <Paragraph number={1}>
          <ParaText>Open the TikTok app and log in with the same account you use on the <TikFansText>TikFans</TikFansText> App.
          </ParaText>
        </Paragraph> */}
        <Paragraph number={1}>
          <ParaText>{i18n.t('tutorial_step_1_part1')} <TikFansText>TikFans</TikFansText> {i18n.t('tutorial_step_1_part2')}
          </ParaText>
        </Paragraph>
        <StyledImage height={620} source={require('assets/images/step2-2.png')} />
        <Paragraph number={2}>
          <ParaText>{i18n.t('tutorial_step_2')}</ParaText>
        </Paragraph>
        <StyledImage height={272} source={require('assets/images/step3.png')} />
        <Paragraph number={3}>
          <ParaText>{i18n.t('tutorial_step_4')}
          </ParaText>
        </Paragraph>
        <StyledImage height={272} source={require('assets/images/step4.png')} />
        <Paragraph number={4}>
          <ParaText>💬 💬 ✍️✍️✍️
          </ParaText>
        </Paragraph>
        <StyledImage height={272} source={require('assets/images/step6.png')} />
        <Paragraph number={5}>
          <ParaText>{i18n.t('tutorial_step_3_part1')} <TikFansText>TikFans</TikFansText> {i18n.t('tutorial_step_3_part2')}
          </ParaText>
        </Paragraph>
        <StyledImage height={145} source={require('assets/images/step5.png')} />
        <ParaText>{i18n.t('how_it_work_desc')}</ParaText>
        <GetStartedButton onPress={() => goToHome()}>
          <GetStartedButtonText>
            {i18n.t('tutorial_get_started')}
          </GetStartedButtonText>
        </GetStartedButton>
      </StyledScrollView>
    </SafeAreaView>
  );
};

export default TutorialScreen;

const StyledScrollView = styled(ScrollView).attrs({
  flex: 1,
  backgroundColor: '#FFFFFF',
  contentContainerStyle: {
    backgroundColor: '#FFFFFF',
    alignItems: 'flex-start',
    width: '100%',
    maxWidth: 500,
    padding: 15,
    alignSelf: 'center',
  }
})``;

const Title = styled(Text)`
  font-size: 20;
  font-weight: bold;
  line-height: 24;
  color: #000000;
`;

const H3 = styled(Text)`
  font-size: 16;
  font-weight: bold;
  line-height: 19;
  margin-top: 15;
`;

const ListNumber = ({ number }) => (
  <View
    style={{
      width: 24,
      height: 24,
      borderRadius: 12,
      backgroundColor: Colors.primary.purple,
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Text style={{ color: 'white' }}>
      {number}
    </Text>
  </View>
);

const Paragraph = ({ number, children }) => (
  <View style={{
    flexDirection: 'row',
    marginTop: 16,
    justifyContent: 'center',
    alignItems: 'center',
  }}>
    <ListNumber number={number} />
    {children}
  </View>
);

const ParaText = styled(Text)`
  font-size: 16;
  font-weight: 500;
  line-height: 19;
  margin-left: 14;
  max-width: 80%;
`;

const TikFansText = styled(Text)`
  font-size: 16;
  font-weight: 800;
  line-height: 19;
  color: ${path(['theme', 'primary', 'purple'])};
`;

const B = styled(TikFansText)`
  color: #000000;
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: ${props => props.height};
  margin-top: 20;
`;

const GetStartedButtonText = styled(Text)`
  color: #FFFFFF;
  font-size: 18;
  font-weight: 900;
  line-height: 21;
`;

const GetStartedButton = styled(TouchableOpacity)`
  width: 70%;
  max-width: 180;
  height: 49;
  border-radius: 24;
  align-items: center;
  justify-content: center;
  background-color: ${path(['theme', 'primary', 'lightPink'])};
  margin-left: ${props => props.isSkipButton ? 0 : 22};
  margin-top: 30;
  margin-bottom: 80;
  align-self: center;
`;
