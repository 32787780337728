import * as React from 'react';
import { path } from 'ramda';
import { View, ActivityIndicator } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import styled from 'styled-components/native';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import Colors from 'constants/Colors';
import ProfileCard from 'components/ProfileCard';
import LikesTab from 'components/RedeemScreen/LikesTab';
import { getUserVideoListAction } from 'actions/userActions';

// import { AdBanner } from 'components/Ads';
// import TSAdBanner from 'components/Ads/TSAdBanner';
import { TSAdBanner } from 'ts-ad-modules';
import RedeemContentList from 'components/RedeemScreen/RedeemContentList';
import i18n from 'i18n';
import { BANNER_LIST } from 'constants/TsadConfig';

const showSelfPromotionBanner = false;
const bannerConfig = _.shuffle(BANNER_LIST)[0];

const RedeemScreen = ({ route }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const user = useSelector(state => state.user);
  const ifFetchingUserTikTokVideos = user?.ifFetchingUserTikTokVideos;
  React.useEffect(() => {
    if (user?.tiktok?.uniqueId && route.name !== 'RedeemLikes') {
      dispatch(getUserVideoListAction());
    }
  }, [user?.tiktok?.uniqueId]);
  return (
    <Container>
      {isLoading && <StyledActivityIndicator size="large" color={Colors.primary.lightGray} />}
      {route?.name === 'Redeem' ? (
        <StyledScrollView>
          <ProfileCard />
          {showSelfPromotionBanner && (
            <TSAdBanner
              style={{
                marginTop: 10,
                maxWidth: '100%',
                alignSelf: 'center',
                backgroundColor: '#FFFFFF',
              }}
              {...bannerConfig}
            />
          )}
          <RedeemContentList type="follower" setIsLoading={setIsLoading} />
        </StyledScrollView>
      ) : (
        <LikesTab />
      )}
    </Container>
  );
};

export default RedeemScreen;

const Container = styled(View)`
  flex: 1;
  background-color: ${path(['theme', 'primary', 'lightBlue'])};
  align-items: center;
`;

const StyledScrollView = styled(ScrollView).attrs({
  flex: 1,
  backgroundColor: Colors.primary?.lightBlue,
  contentContainerStyle: {
    backgroundColor: Colors.primary?.lightBlue,
    alignItems: 'center',
  },
})``;

const StyledActivityIndicator = styled(ActivityIndicator)`
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
`;
