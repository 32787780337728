// Detects if device is on iOS 
export const isIos = () => {
  const userAgent = window?.navigator?.userAgent?.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
}
// Detects if device is in standalone mode
export const isInStandaloneMode = () => ('standalone' in window?.navigator) && (window?.navigator?.standalone);

// Checks if should display install popup notification:
export const isIosBrowser = () => {
  return isIos() && !isInStandaloneMode();
};


export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

export const getIOSVersion = () => {
  try {
    return parseFloat(
      ('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
        .replace('undefined', '3_2').replace('_', '.').replace('_', '')
    ) || -1;
  } catch (e) {
    return undefined;
  }
};

