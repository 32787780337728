import * as React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import TabBarIcon from 'components/TabBarIcon';
import HomeStack from 'navigation/HomeStackNavigator';
import RedeemStack from 'navigation/RedeemStackNavigator';
import RedeemLikesStack from 'navigation/RedeemLikesStackNavigator';
import PurchaseStack from 'navigation/PurchaseStackNavigator';
import SettingsStack from 'navigation/SettingsStackNavigator';
import useListenFromTapPay from 'modules/tappay/useListenFromTapPay';

const BottomTab = createBottomTabNavigator();
const INITIAL_ROUTE_NAME = 'HomeStack';

export default function BottomTabNavigator({ navigation, route }) {
  // Set the header title on the parent stack navigator depending on the
  // currently active tab. Learn more in the documentation:
  // https://reactnavigation.org/docs/en/screen-options-resolution.html
  // navigation.setOptions({ headerTitle: getHeaderTitle(route) });

  useListenFromTapPay();
  return (
    <BottomTab.Navigator
      initialRouteName={INITIAL_ROUTE_NAME}
      tabBarOptions={{
        showLabel: false,
      }}
      lazy={false}
    >
      <BottomTab.Screen
        name="HomeStack"
        component={HomeStack}
        options={{
          // title: "",
          tabBarIcon: props => (
            <TabBarIcon
              {...props}
              activeImg={require('assets/images/tabbargetstar_S.png')}
              inActiveImg={require('assets/images/tabbargetstar_N.png')}
            />
          ),
        }}
      />
      <BottomTab.Screen
        name="RedeemStack"
        component={RedeemStack}
        options={{
          title: 'Redeem',
          tabBarIcon: props => (
            <TabBarIcon
              {...props}
              activeImg={require('assets/images/tabbargetfans_S.png')}
              inActiveImg={require('assets/images/tabbargetfans_N.png')}
            />
          ),
        }}
      />
      <BottomTab.Screen
        name="RedeemLikesStack"
        component={RedeemLikesStack}
        options={{
          title: 'RedeemLikes',
          tabBarIcon: props => (
            <TabBarIcon
              {...props}
              activeImg={require('assets/images/tabbargetlike_S.png')}
              inActiveImg={require('assets/images/tabbargetlike_N.png')}
            />
          ),
        }}
      />
      <BottomTab.Screen
        name="PurchaseStack"
        component={PurchaseStack}
        options={{
          title: 'Purchase',
          tabBarLabel: () => {
            return null;
          },
          tabBarIcon: props => (
            <TabBarIcon
              {...props}
              activeImg={require('assets/images/tabbarbuy_S.png')}
              inActiveImg={require('assets/images/tabbarbuy_N.png')}
            />
          ),
        }}
      />
      <BottomTab.Screen
        name="SettingsStack"
        component={SettingsStack}
        options={{
          title: 'Settings',
          tabBarIcon: props => (
            <TabBarIcon
              {...props}
              activeImg={require('assets/images/tabbarmore_S.png')}
              inActiveImg={require('assets/images/tabbarmore_N.png')}
            />
          ),
        }}
      />
    </BottomTab.Navigator>
  );
}

function getHeaderTitle(route) {
  const routeName = route.state?.routes[route.state.index]?.name ?? INITIAL_ROUTE_NAME;

  switch (routeName) {
    case 'Home':
      return 'GET STARS';
    case 'Links':
      return 'Links to learn more';
  }
}
