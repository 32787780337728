import axios from 'axios';
import _ from 'lodash';
import { Platform } from 'react-native';
import UserAgent from 'user-agents';

import * as firebaseApi from 'apis/firebase';
import { getUserInfoError } from 'apis/slack';

export const getUserProfileInfo = async ({ username, proxy = '', isLogin = false }) => {
  const userAgentFactory = new UserAgent({ deviceCategory: 'mobile' });
  const userAgent = userAgentFactory.toString();
  try {
    if (isLogin) {
      const user = await firebaseApi.getLoginUserTikTokInfo({ username });

      if (user?.uniqueId) {
        return user;
      }
      if (user?.statusCode === 0) {
        return;
      }
    }

    // const headers = {
    //   'User-Agent': userAgent,
    // };
    // const shareApiUrl = 'https://cors-anywhere.herokuapp.com/https://www.tiktok.com/node/share/user/';
    // const shareApiRes = await axios.get(`${shareApiUrl}@${username}`, { headers }).catch(e => {
    //   if (__DEV__) {
    //     console.log('shareApiUrl client error', e);
    //   }
    //   if (!isLogin) {
    //     getUserInfoError({ username, userAgent, catchError: `shareApiRes: ${String(e)}` });
    //   }
    // });

    // const shareApiUser = shareApiRes?.data?.body?.userData;

    // if (!_.isEmpty(shareApiUser)) {
    //   return shareApiUser;
    // }

    const user = await firebaseApi.getTikTokUserInfo({ username });
    if (!isLogin && _.isEmpty(user)) {
      getUserInfoError({ username, userAgent, env: 'Web => Cloud Function' });
    }
    return user;
  } catch (e) {
    if (__DEV__) {
      console.log('getUserProfileInfo error', e);
    }
    getUserInfoError({ username, userAgent, catchError: `getUserProfileInfo: ${String(e)}` });
  }
};

export const getUsernameFromShareLink = async (shareLink) => {
  try {
    const { data } = await axios.get(shareLink);
    const dataString = String(data);
    const start = dataString.indexOf(`"uniqueId":`);
    const uniqueIdStart = start + `"uniqueId":`.length + 1;
    let end = uniqueIdStart;
    while (dataString[end] !== `"`) {
      end = end + 1;
    }
    const username = dataString.slice(uniqueIdStart, end);
    // console.log('start', dataString[end], start, uniqueIdStart, end);
    return username;
  } catch (e) {
    if (__DEV__) {
      console.log('getUsernameFromShareLink error', e);
    }
    return undefined;
  }
};

export const getUserVideoList = async ({ uniqueId }) => {
  try {
    const data = await firebaseApi.getUserTikTokVideoList({ uniqueId });
    return data;
  } catch (e) {
    if (__DEV__) {
      console.log('getUserVideoList api error', e);
    }
  }
};

export const getVideoInfoFromLink = async ({ url }) => {
  if (!url) {
    return;
  }
  try {
    if (Platform.OS !== 'web') {
      const data = await getVideoInfoFromLinkLocal({ url });
      if (!_.isEmpty(data)) {
        return data;
      }
    }
    const data = await firebaseApi.getVideoFromLink({ url });
    return data;
  } catch (e) {
    if (__DEV__) {
      console.log('getVideoInfoFromLink error', e);
    }
  }
};


const getVideoInfoFromLinkLocal = async ({ url }) => {
  try {
    const response = await axios.get(url, {
      headers: {
        'User-Agent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:74.0) Gecko/20100101 Firefox/74.0',
        referer: 'https://www.tiktok.com/',
      },
    });
    const rawVideoMetadata = response
      .data
      .split(/<script id="__NEXT_DATA__" type="application\/json" nonce="[\w-]+" crossorigin="anonymous">/)[1]
      .split(`</script>`)[0];
    const videoProps = JSON.parse(rawVideoMetadata);
    const videoData = videoProps.props.pageProps.itemInfo.itemStruct;

    const id = videoData?.id;
    const uniqueId = videoData?.author?.uniqueId;
    const item = {
      id,
      imageUrl: videoData?.video?.cover,
      diggCount: videoData?.stats?.diggCount,
      commentCount: videoData?.stats?.commentCount,
      videoUrl: videoData?.video?.playAddr,
      webVideoUrl: `https://www.tiktok.com/@${uniqueId}/video/${id}`,
    };
    return item;
  } catch (e) {
    if (__DEV__) {
      console.log('getVideoInfoFromLinkLocal error: ', e);
    }
    return {};
  }
};