import axios from 'axios';
import { Platform } from 'react-native';
// import * as Cellular from 'expo-cellular';

const timeZoneCountryDict = {
  'Asia/Kabul': { countryCode: 'AF' },
  'Europe/Mariehamn': { countryCode: 'AX' },
  'Europe/Tirane': { countryCode: 'AL' },
  'Africa/Algiers': { countryCode: 'DZ' },
  'Pacific/Pago_Pago': { countryCode: 'AS' },
  'Europe/Andorra': { countryCode: 'AD' },
  'Africa/Luanda': { countryCode: 'AO' },
  'America/Anguilla': { countryCode: 'AI' },
  'Antarctica/Casey': { countryCode: 'AQ' },
  'Antarctica/Davis': { countryCode: 'AQ' },
  'Antarctica/DumontDUrville': { countryCode: 'AQ' },
  'Antarctica/Mawson': { countryCode: 'AQ' },
  'Antarctica/McMurdo': { countryCode: 'AQ' },
  'Antarctica/Palmer': { countryCode: 'AQ' },
  'Antarctica/Rothera': { countryCode: 'AQ' },
  'Antarctica/Syowa': { countryCode: 'AQ' },
  'Antarctica/Troll': { countryCode: 'AQ' },
  'Antarctica/Vostok': { countryCode: 'AQ' },
  'America/Antigua': { countryCode: 'AG' },
  'America/Argentina/Buenos_Aires': { countryCode: 'AR' },
  'America/Buenos_Aires': { countryCode: 'AR' },
  'America/Argentina/Catamarca': { countryCode: 'AR' },
  'America/Argentina/Cordoba': { countryCode: 'AR' },
  'America/Argentina/Jujuy': { countryCode: 'AR' },
  'America/Argentina/La_Rioja': { countryCode: 'AR' },
  'America/Argentina/Mendoza': { countryCode: 'AR' },
  'America/Argentina/Rio_Gallegos': { countryCode: 'AR' },
  'America/Argentina/Salta': { countryCode: 'AR' },
  'America/Argentina/San_Juan': { countryCode: 'AR' },
  'America/Argentina/San_Luis': { countryCode: 'AR' },
  'America/Argentina/Tucuman': { countryCode: 'AR' },
  'America/Argentina/Ushuaia': { countryCode: 'AR' },
  'Asia/Yerevan': { countryCode: 'AM' },
  'America/Aruba': { countryCode: 'AW' },
  'Antarctica/Macquarie': { countryCode: 'AU' },
  'Australia/Adelaide': { countryCode: 'AU' },
  'Australia/Brisbane': { countryCode: 'AU' },
  'Australia/Broken_Hill': { countryCode: 'AU' },
  'Australia/Currie': { countryCode: 'AU' },
  'Australia/Darwin': { countryCode: 'AU' },
  'Australia/Eucla': { countryCode: 'AU' },
  'Australia/Hobart': { countryCode: 'AU' },
  'Australia/Lindeman': { countryCode: 'AU' },
  'Australia/Lord_Howe': { countryCode: 'AU' },
  'Australia/Melbourne': { countryCode: 'AU' },
  'Australia/Perth': { countryCode: 'AU' },
  'Australia/Sydney': { countryCode: 'AU' },
  'Europe/Vienna': { countryCode: 'AT' },
  'Asia/Baku': { countryCode: 'AZ' },
  'America/Nassau': { countryCode: 'BS' },
  'Asia/Bahrain': { countryCode: 'BH' },
  'Asia/Dhaka': { countryCode: 'BD' },
  'America/Barbados': { countryCode: 'BB' },
  'Europe/Minsk': { countryCode: 'BY' },
  'Europe/Brussels': { countryCode: 'BE' },
  'America/Belize': { countryCode: 'BZ' },
  'Africa/Porto-Novo': { countryCode: 'BJ' },
  'Atlantic/Bermuda': { countryCode: 'BM' },
  'Asia/Thimphu': { countryCode: 'BT' },
  'America/La_Paz': { countryCode: 'BO' },
  'America/Kralendijk': { countryCode: 'BQ' },
  'Europe/Sarajevo': { countryCode: 'BA' },
  'Africa/Gaborone': { countryCode: 'BW' },
  'America/Araguaina': { countryCode: 'BR' },
  'America/Bahia': { countryCode: 'BR' },
  'America/Belem': { countryCode: 'BR' },
  'America/Boa_Vista': { countryCode: 'BR' },
  'America/Campo_Grande': { countryCode: 'BR' },
  'America/Cuiaba': { countryCode: 'BR' },
  'America/Eirunepe': { countryCode: 'BR' },
  'America/Fortaleza': { countryCode: 'BR' },
  'America/Maceio': { countryCode: 'BR' },
  'America/Manaus': { countryCode: 'BR' },
  'America/Noronha': { countryCode: 'BR' },
  'America/Porto_Velho': { countryCode: 'BR' },
  'America/Recife': { countryCode: 'BR' },
  'America/Rio_Branco': { countryCode: 'BR' },
  'America/Santarem': { countryCode: 'BR' },
  'America/Sao_Paulo': { countryCode: 'BR' },
  'Indian/Chagos': { countryCode: 'IO' },
  'America/Tortola': { countryCode: 'VG' },
  'Asia/Brunei': { countryCode: 'BN' },
  'Europe/Sofia': { countryCode: 'BG' },
  'Africa/Ouagadougou': { countryCode: 'BF' },
  'Africa/Bujumbura': { countryCode: 'BI' },
  'Asia/Phnom_Penh': { countryCode: 'KH' },
  'Africa/Douala': { countryCode: 'CM' },
  'America/Atikokan': { countryCode: 'CA' },
  'America/Blanc-Sablon': { countryCode: 'CA' },
  'America/Cambridge_Bay': { countryCode: 'CA' },
  'America/Montreal': { countryCode: 'CA' },
  'America/Creston': { countryCode: 'CA' },
  'America/Dawson': { countryCode: 'CA' },
  'America/Dawson_Creek': { countryCode: 'CA' },
  'America/Edmonton': { countryCode: 'CA' },
  'America/Fort_Nelson': { countryCode: 'CA' },
  'America/Glace_Bay': { countryCode: 'CA' },
  'America/Goose_Bay': { countryCode: 'CA' },
  'America/Halifax': { countryCode: 'CA' },
  'America/Inuvik': { countryCode: 'CA' },
  'America/Iqaluit': { countryCode: 'CA' },
  'America/Moncton': { countryCode: 'CA' },
  'America/Nipigon': { countryCode: 'CA' },
  'America/Pangnirtung': { countryCode: 'CA' },
  'America/Rainy_River': { countryCode: 'CA' },
  'America/Rankin_Inlet': { countryCode: 'CA' },
  'America/Regina': { countryCode: 'CA' },
  'America/Resolute': { countryCode: 'CA' },
  'America/St_Johns': { countryCode: 'CA' },
  'America/Swift_Current': { countryCode: 'CA' },
  'America/Thunder_Bay': { countryCode: 'CA' },
  'America/Toronto': { countryCode: 'CA' },
  'America/Vancouver': { countryCode: 'CA' },
  'America/Whitehorse': { countryCode: 'CA' },
  'America/Winnipeg': { countryCode: 'CA' },
  'America/Yellowknife': { countryCode: 'CA' },
  'Atlantic/Cape_Verde': { countryCode: 'CV' },
  'America/Cayman': { countryCode: 'KY' },
  'Africa/Bangui': { countryCode: 'CF' },
  'Africa/Ndjamena': { countryCode: 'TD' },
  'America/Punta_Arenas': { countryCode: 'CL' },
  'America/Santiago': { countryCode: 'CL' },
  'Pacific/Easter': { countryCode: 'CL' },
  'Asia/Shanghai': { countryCode: 'CN' },
  'Asia/Urumqi': { countryCode: 'CN' },
  'Asia/Chungking': { countryCode: 'CN' },
  'Asia/Chongqing': { countryCode: 'CN' },
  'Indian/Christmas': { countryCode: 'CX' },
  'Indian/Cocos': { countryCode: 'CC' },
  'America/Bogota': { countryCode: 'CO' },
  'Indian/Comoro': { countryCode: 'KM' },
  'Pacific/Rarotonga': { countryCode: 'CK' },
  'America/Costa_Rica': { countryCode: 'CR' },
  'Europe/Zagreb': { countryCode: 'HR' },
  'America/Havana': { countryCode: 'CU' },
  'America/Curacao': { countryCode: 'CW' },
  'Asia/Famagusta': { countryCode: 'CY' },
  'Asia/Nicosia': { countryCode: 'CY' },
  'Europe/Prague': { countryCode: 'CZ' },
  'Africa/Kinshasa': { countryCode: 'CD' },
  'Africa/Lubumbashi': { countryCode: 'CD' },
  'Europe/Copenhagen': { countryCode: 'DK' },
  'Africa/Djibouti': { countryCode: 'DJ' },
  'America/Dominica': { countryCode: 'DM' },
  'America/Santo_Domingo': { countryCode: 'DO' },
  'Asia/Dili': { countryCode: 'TL' },
  'America/Guayaquil': { countryCode: 'EC' },
  'Pacific/Galapagos': { countryCode: 'EC' },
  'Africa/Cairo': { countryCode: 'EG' },
  'America/El_Salvador': { countryCode: 'SV' },
  'Africa/Malabo': { countryCode: 'GQ' },
  'Africa/Asmara': { countryCode: 'ER' },
  'Europe/Tallinn': { countryCode: 'EE' },
  'Africa/Addis_Ababa': { countryCode: 'ET' },
  'Atlantic/Stanley': { countryCode: 'FK' },
  'Atlantic/Faroe': { countryCode: 'FO' },
  'Pacific/Fiji': { countryCode: 'FJ' },
  'Europe/Helsinki': { countryCode: 'FI' },
  'Europe/Paris': { countryCode: 'FR' },
  'America/Cayenne': { countryCode: 'GF' },
  'Pacific/Gambier': { countryCode: 'PF' },
  'Pacific/Marquesas': { countryCode: 'PF' },
  'Pacific/Tahiti': { countryCode: 'PF' },
  'Indian/Kerguelen': { countryCode: 'TF' },
  'Africa/Libreville': { countryCode: 'GA' },
  'Africa/Banjul': { countryCode: 'GM' },
  'Asia/Tbilisi': { countryCode: 'GE' },
  'Europe/Berlin': { countryCode: 'DE' },
  'Europe/Busingen': { countryCode: 'DE' },
  'Africa/Accra': { countryCode: 'GH' },
  'Europe/Gibraltar': { countryCode: 'GI' },
  'Europe/Athens': { countryCode: 'GR' },
  'America/Danmarkshavn': { countryCode: 'GL' },
  'America/Godthab': { countryCode: 'GL' },
  'America/Scoresbysund': { countryCode: 'GL' },
  'America/Thule': { countryCode: 'GL' },
  'America/Grenada': { countryCode: 'GD' },
  'America/Guadeloupe': { countryCode: 'GP' },
  'Pacific/Guam': { countryCode: 'GU' },
  'America/Guatemala': { countryCode: 'GT' },
  'Europe/Guernsey': { countryCode: 'GG' },
  'Africa/Conakry': { countryCode: 'GN' },
  'Africa/Bissau': { countryCode: 'GW' },
  'America/Guyana': { countryCode: 'GY' },
  'America/Port-au-Prince': { countryCode: 'HT' },
  'America/Tegucigalpa': { countryCode: 'HN' },
  'Asia/Hong_Kong': { countryCode: 'HK' },
  'Europe/Budapest': { countryCode: 'HU' },
  'Atlantic/Reykjavik': { countryCode: 'IS' },
  'Asia/Kolkata': { countryCode: 'IN' },
  'Asia/Calcutta': { countryCode: 'IN' },
  'Asia/Jakarta': { countryCode: 'ID' },
  'Asia/Jayapura': { countryCode: 'ID' },
  'Asia/Makassar': { countryCode: 'ID' },
  'Asia/Pontianak': { countryCode: 'ID' },
  'Asia/Tehran': { countryCode: 'IR' },
  'Asia/Baghdad': { countryCode: 'IQ' },
  'Europe/Dublin': { countryCode: 'IE' },
  'Europe/Isle_of_Man': { countryCode: 'IM' },
  'Asia/Jerusalem': { countryCode: 'IL' },
  'Europe/Rome': { countryCode: 'IT' },
  'Africa/Abidjan': { countryCode: 'CI' },
  'America/Jamaica': { countryCode: 'JM' },
  'Asia/Tokyo': { countryCode: 'JP' },
  'Europe/Jersey': { countryCode: 'JE' },
  'Asia/Amman': { countryCode: 'JO' },
  'Asia/Almaty': { countryCode: 'KZ' },
  'Asia/Aqtau': { countryCode: 'KZ' },
  'Asia/Aqtobe': { countryCode: 'KZ' },
  'Asia/Atyrau': { countryCode: 'KZ' },
  'Asia/Oral': { countryCode: 'KZ' },
  'Asia/Qyzylorda': { countryCode: 'KZ' },
  'Africa/Nairobi': { countryCode: 'KE' },
  'Pacific/Enderbury': { countryCode: 'KI' },
  'Pacific/Kiritimati': { countryCode: 'KI' },
  'Pacific/Tarawa': { countryCode: 'KI' },
  'Asia/Kuwait': { countryCode: 'KW' },
  'Asia/Bishkek': { countryCode: 'KG' },
  'Asia/Vientiane': { countryCode: 'LA' },
  'Europe/Riga': { countryCode: 'LV' },
  'Asia/Beirut': { countryCode: 'LB' },
  'Africa/Maseru': { countryCode: 'LS' },
  'Africa/Monrovia': { countryCode: 'LR' },
  'Africa/Tripoli': { countryCode: 'LY' },
  'Europe/Vaduz': { countryCode: 'LI' },
  'Europe/Vilnius': { countryCode: 'LT' },
  'Europe/Luxembourg': { countryCode: 'LU' },
  'Asia/Macau': { countryCode: 'MO' },
  'Europe/Skopje': { countryCode: 'MK' },
  'Indian/Antananarivo': { countryCode: 'MG' },
  'Africa/Blantyre': { countryCode: 'MW' },
  'Asia/Kuala_Lumpur': { countryCode: 'MY' },
  'Asia/Kuching': { countryCode: 'MY' },
  'Indian/Maldives': { countryCode: 'MV' },
  'Africa/Bamako': { countryCode: 'ML' },
  'Europe/Malta': { countryCode: 'MT' },
  'Pacific/Kwajalein': { countryCode: 'MH' },
  'Pacific/Majuro': { countryCode: 'MH' },
  'America/Martinique': { countryCode: 'MQ' },
  'Africa/Nouakchott': { countryCode: 'MR' },
  'Indian/Mauritius': { countryCode: 'MU' },
  'Indian/Mayotte': { countryCode: 'YT' },
  'America/Bahia_Banderas': { countryCode: 'MX' },
  'America/Cancun': { countryCode: 'MX' },
  'America/Chihuahua': { countryCode: 'MX' },
  'America/Hermosillo': { countryCode: 'MX' },
  'America/Matamoros': { countryCode: 'MX' },
  'America/Mazatlan': { countryCode: 'MX' },
  'America/Merida': { countryCode: 'MX' },
  'America/Mexico_City': { countryCode: 'MX' },
  'America/Monterrey': { countryCode: 'MX' },
  'America/Ojinaga': { countryCode: 'MX' },
  'America/Tijuana': { countryCode: 'MX' },
  'Pacific/Chuuk': { countryCode: 'FM' },
  'Pacific/Kosrae': { countryCode: 'FM' },
  'Pacific/Pohnpei': { countryCode: 'FM' },
  'Europe/Chisinau': { countryCode: 'MD' },
  'Europe/Monaco': { countryCode: 'MC' },
  'Asia/Choibalsan': { countryCode: 'MN' },
  'Asia/Hovd': { countryCode: 'MN' },
  'Asia/Ulaanbaatar': { countryCode: 'MN' },
  'Europe/Podgorica': { countryCode: 'ME' },
  'America/Montserrat': { countryCode: 'MS' },
  'Africa/Casablanca': { countryCode: 'MA' },
  'Africa/Maputo': { countryCode: 'MZ' },
  'Asia/Yangon': { countryCode: 'MM' },
  'Africa/Windhoek': { countryCode: 'NA' },
  'Pacific/Nauru': { countryCode: 'NR' },
  'Asia/Kathmandu': { countryCode: 'NP' },
  'Asia/Katmandu': { countryCode: 'NP' },
  'Europe/Amsterdam': { countryCode: 'NL' },
  'Pacific/Noumea': { countryCode: 'NC' },
  'Pacific/Auckland': { countryCode: 'NZ' },
  'Pacific/Chatham': { countryCode: 'NZ' },
  'America/Managua': { countryCode: 'NI' },
  'Africa/Niamey': { countryCode: 'NE' },
  'Africa/Lagos': { countryCode: 'NG' },
  'Pacific/Niue': { countryCode: 'NU' },
  'Pacific/Norfolk': { countryCode: 'NF' },
  'Asia/Pyongyang': { countryCode: 'KP' },
  'Pacific/Saipan': { countryCode: 'MP' },
  'Europe/Oslo': { countryCode: 'NO' },
  'Asia/Muscat': { countryCode: 'OM' },
  'Asia/Karachi': { countryCode: 'PK' },
  'Pacific/Palau': { countryCode: 'PW' },
  'Asia/Gaza': { countryCode: 'PS' },
  'Asia/Hebron': { countryCode: 'PS' },
  'America/Panama': { countryCode: 'PA' },
  'Pacific/Bougainville': { countryCode: 'PG' },
  'Pacific/Port_Moresby': { countryCode: 'PG' },
  'America/Asuncion': { countryCode: 'PY' },
  'America/Lima': { countryCode: 'PE' },
  'Asia/Manila': { countryCode: 'PH' },
  'Pacific/Pitcairn': { countryCode: 'PN' },
  'Europe/Warsaw': { countryCode: 'PL' },
  'Atlantic/Azores': { countryCode: 'PT' },
  'Atlantic/Madeira': { countryCode: 'PT' },
  'Europe/Lisbon': { countryCode: 'PT' },
  'America/Puerto_Rico': { countryCode: 'PR' },
  'Asia/Qatar': { countryCode: 'QA' },
  'Africa/Brazzaville': { countryCode: 'CG' },
  'Indian/Reunion': { countryCode: 'RE' },
  'Europe/Bucharest': { countryCode: 'RO' },
  'Asia/Anadyr': { countryCode: 'RU' },
  'Asia/Barnaul': { countryCode: 'RU' },
  'Asia/Chita': { countryCode: 'RU' },
  'Asia/Irkutsk': { countryCode: 'RU' },
  'Asia/Kamchatka': { countryCode: 'RU' },
  'Asia/Khandyga': { countryCode: 'RU' },
  'Asia/Krasnoyarsk': { countryCode: 'RU' },
  'Asia/Magadan': { countryCode: 'RU' },
  'Asia/Novokuznetsk': { countryCode: 'RU' },
  'Asia/Novosibirsk': { countryCode: 'RU' },
  'Asia/Omsk': { countryCode: 'RU' },
  'Asia/Sakhalin': { countryCode: 'RU' },
  'Asia/Srednekolymsk': { countryCode: 'RU' },
  'Asia/Tomsk': { countryCode: 'RU' },
  'Asia/Ust-Nera': { countryCode: 'RU' },
  'Asia/Vladivostok': { countryCode: 'RU' },
  'Asia/Yakutsk': { countryCode: 'RU' },
  'Asia/Yekaterinburg': { countryCode: 'RU' },
  'Europe/Astrakhan': { countryCode: 'RU' },
  'Europe/Kaliningrad': { countryCode: 'RU' },
  'Europe/Kirov': { countryCode: 'RU' },
  'Europe/Moscow': { countryCode: 'RU' },
  'Europe/Samara': { countryCode: 'RU' },
  'Europe/Saratov': { countryCode: 'RU' },
  'Europe/Simferopol': { countryCode: 'RU' },
  'Europe/Ulyanovsk': { countryCode: 'RU' },
  'Europe/Volgograd': { countryCode: 'RU' },
  'Africa/Kigali': { countryCode: 'RW' },
  'America/St_Barthelemy': { countryCode: 'BL' },
  'Atlantic/St_Helena': { countryCode: 'SH' },
  'America/St_Kitts': { countryCode: 'KN' },
  'America/St_Lucia': { countryCode: 'LC' },
  'America/Marigot': { countryCode: 'MF' },
  'America/Miquelon': { countryCode: 'PM' },
  'America/St_Vincent': { countryCode: 'VC' },
  'Pacific/Apia': { countryCode: 'WS' },
  'Europe/San_Marino': { countryCode: 'SM' },
  'Africa/Sao_Tome': { countryCode: 'ST' },
  'Asia/Riyadh': { countryCode: 'SA' },
  'Africa/Dakar': { countryCode: 'SN' },
  'Europe/Belgrade': { countryCode: 'RS' },
  'Indian/Mahe': { countryCode: 'SC' },
  'Africa/Freetown': { countryCode: 'SL' },
  'Asia/Singapore': { countryCode: 'SG' },
  'America/Lower_Princes': { countryCode: 'SX' },
  'Europe/Bratislava': { countryCode: 'SK' },
  'Europe/Ljubljana': { countryCode: 'SI' },
  'Pacific/Guadalcanal': { countryCode: 'SB' },
  'Africa/Mogadishu': { countryCode: 'SO' },
  'Africa/Johannesburg': { countryCode: 'ZA' },
  'Atlantic/South_Georgia': { countryCode: 'GS' },
  'Asia/Seoul': { countryCode: 'KR' },
  'Africa/Juba': { countryCode: 'SS' },
  'Africa/Ceuta': { countryCode: 'ES' },
  'Atlantic/Canary': { countryCode: 'ES' },
  'Europe/Madrid': { countryCode: 'ES' },
  'Asia/Colombo': { countryCode: 'LK' },
  'Africa/Khartoum': { countryCode: 'SD' },
  'America/Paramaribo': { countryCode: 'SR' },
  'Arctic/Longyearbyen': { countryCode: 'SJ' },
  'Africa/Mbabane': { countryCode: 'SZ' },
  'Europe/Stockholm': { countryCode: 'SE' },
  'Europe/Zurich': { countryCode: 'CH' },
  'Asia/Damascus': { countryCode: 'SY' },
  'Asia/Taipei': { countryCode: 'TW' },
  'Asia/Dushanbe': { countryCode: 'TJ' },
  'Africa/Dar_es_Salaam': { countryCode: 'TZ' },
  'Asia/Bangkok': { countryCode: 'TH' },
  'Africa/Lome': { countryCode: 'TG' },
  'Pacific/Fakaofo': { countryCode: 'TK' },
  'Pacific/Tongatapu': { countryCode: 'TO' },
  'America/Port_of_Spain': { countryCode: 'TT' },
  'Africa/Tunis': { countryCode: 'TN' },
  'Europe/Istanbul': { countryCode: 'TR' },
  'Asia/Ashgabat': { countryCode: 'TM' },
  'America/Grand_Turk': { countryCode: 'TC' },
  'Pacific/Funafuti': { countryCode: 'TV' },
  'America/St_Thomas': { countryCode: 'VI' },
  'Africa/Kampala': { countryCode: 'UG' },
  'Europe/Kiev': { countryCode: 'UA' },
  'Europe/Uzhgorod': { countryCode: 'UA' },
  'Europe/Zaporozhye': { countryCode: 'UA' },
  'Asia/Dubai': { countryCode: 'AE' },
  'Europe/London': { countryCode: 'GB' },
  'America/Adak': { countryCode: 'US' },
  'America/Anchorage': { countryCode: 'US' },
  'America/Boise': { countryCode: 'US' },
  'America/Chicago': { countryCode: 'US' },
  'US/Central': { countryCode: 'US' },
  'America/Denver': { countryCode: 'US' },
  'America/Detroit': { countryCode: 'US' },
  'US/Michigan': { countryCode: 'US' },
  'America/Indiana/Indianapolis': { countryCode: 'US' },
  'America/Indiana/Knox': { countryCode: 'US' },
  'America/Indiana/Marengo': { countryCode: 'US' },
  'America/Indiana/Petersburg': { countryCode: 'US' },
  'America/Indiana/Tell_City': { countryCode: 'US' },
  'America/Indiana/Vevay': { countryCode: 'US' },
  'America/Indiana/Vincennes': { countryCode: 'US' },
  'America/Indiana/Winamac': { countryCode: 'US' },
  'America/Juneau': { countryCode: 'US' },
  'America/Kentucky/Louisville': { countryCode: 'US' },
  'America/Kentucky/Monticello': { countryCode: 'US' },
  'America/Los_Angeles': { countryCode: 'US' },
  'US/Pacific': { countryCode: 'US' },
  'America/Menominee': { countryCode: 'US' },
  'America/Metlakatla': { countryCode: 'US' },
  'America/New_York': { countryCode: 'US' },
  'US/Eastern': { countryCode: 'US' },
  'America/Nome': { countryCode: 'US' },
  'America/North_Dakota/Beulah': { countryCode: 'US' },
  'America/North_Dakota/Center': { countryCode: 'US' },
  'America/North_Dakota/New_Salem': { countryCode: 'US' },
  'America/Phoenix': { countryCode: 'US' },
  'America/Sitka': { countryCode: 'US' },
  'America/Yakutat': { countryCode: 'US' },
  'Pacific/Honolulu': { countryCode: 'US' },
  'Pacific/Midway': { countryCode: 'UM' },
  'Pacific/Wake': { countryCode: 'UM' },
  'America/Montevideo': { countryCode: 'UY' },
  'Asia/Samarkand': { countryCode: 'UZ' },
  'Asia/Tashkent': { countryCode: 'UZ' },
  'Pacific/Efate': { countryCode: 'VU' },
  'Europe/Vatican': { countryCode: 'VA' },
  'America/Caracas': { countryCode: 'VE' },
  'Asia/Ho_Chi_Minh': { countryCode: 'VN' },
  'Asia/Saigon': { countryCode: 'VN' },
  'Asia/Hanoi': { countryCode: 'VN' },
  'Pacific/Wallis': { countryCode: 'WF' },
  'Africa/El_Aaiun': { countryCode: 'EH' },
  'Asia/Aden': { countryCode: 'YE' },
  'Africa/Lusaka': { countryCode: 'ZM' },
  'Africa/Harare': { countryCode: 'ZW' },
  'Asia/Rangoon': { countryCode: 'MM' }
}


export const getCountryCode = async () => {

  try {
    if (Platform.OS === 'web') {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return {
        countryCode: timeZoneCountryDict[timezone]?.countryCode || Intl.DateTimeFormat().resolvedOptions().locale,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
    }
    const res = await axios.get('http://ip-api.com/json/');
    const { countryCode, country, timezone, regionName } = res?.data;
    return {
      countryCode,
      country,
      regionName,
      timezone
    };
  } catch (e) {
    if (__DEV__) {
      console.log('getCountryCode error', e);
    }
    return {};
  }
};
