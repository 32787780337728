import axios from 'axios';
import dedent from 'dedent';
import { Platform } from 'react-native';
import Constants from 'expo-constants';

import { slackNotificationFunction } from 'apis/firebase';
import { isInStandaloneMode, getIOSVersion } from 'utils/system';

export const newUser = data => {
  if (!__DEV__) {
    const url = 'https://hooks.slack.com/services/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';
    const payload = {
      text: dedent`uid: ${data?.uid}
                  countryCode: ${data?.countryCode}
                  country: ${data?.country}
                  timezone: ${data?.timezone}
                  regionName: ${data?.regionName}
                  platform: ${data?.platform} ${isInStandaloneMode() ? '(PWA)' : ''}
                  version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
    };
    if (Platform.OS === 'web') {
      slackNotificationFunction({ payload, url });
      return;
    }
    axios.post(url, payload).catch(err => console.log('slack err', err.response));
  }
};

export const newTikTokLogin = data => {
  if (!__DEV__) {
    const url = Platform.select({
      ios: 'https://hooks.slack.com/services/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',
      android: 'https://hooks.slack.com/services/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',
      default: 'https://hooks.slack.com/services/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',
    });
    const payload = {
      text: dedent`uid: ${data?.uid}
                  countryCode: ${data?.countryCode}
                  country: ${data?.country}
                  timezone: ${data?.timezone}
                  regionName: ${data?.regionName}
                  platform: ${data?.platform} ${isInStandaloneMode() ? '(PWA)' : ''}
                  applePay: ${data?.applePay}
                  uniqueId: ${data?.tiktok?.uniqueId}
                  nickName: ${data?.tiktok?.nickName}
                  video: ${data?.tiktok?.video}
                  followingCount: ${data?.tiktok?.followingCount}
                  followerCount: ${data?.tiktok?.followerCount}
                  avatar: ${data?.tiktok?.avatar}
                  version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
    };
    if (Platform.OS === 'web') {
      slackNotificationFunction({ payload, url });
      return;
    }
    axios.post(url, payload).catch(err => console.log('slack err', err.response));
  }
};

export const newPurchase = data => {
  try {
    if (!__DEV__) {
      const url =
        Platform.OS === 'web'
          ? !!data?.isApplePay
            ? 'https://hooks.slack.com/services/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'
            : 'https://hooks.slack.com/services/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'
          : 'https://hooks.slack.com/services/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';
      const payload = {
        text: dedent`uid: ${data?.uid}
                    stars: ${data?.stars}
                    isSubscription: ${data?.isSubscription}
                    orderId: ${data?.orderId}
                    purchaseToken: ${data?.purchaseToken}
                    countryCode: ${data?.countryCode}
                    country: ${data?.country}
                    timezone: ${data?.timezone}
                    regionName: ${data?.regionName}
                    platform: ${data?.platform} ${isInStandaloneMode() ? '(PWA)' : ''}
                    uniqueId: ${data?.tiktok?.uniqueId}
                    nickName: ${data?.tiktok?.nickName}
                    video: ${data?.tiktok?.video}
                    followingCount: ${data?.tiktok?.followingCount}
                    followerCount: ${data?.tiktok?.followerCount}
                    avatar: ${data?.tiktok?.avatar}
                    userAgent: ${window?.navigator?.userAgent}
                    version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
      };
      if (Platform.OS === 'web') {
        slackNotificationFunction({ payload, url });
        return;
      }
      axios.post(url, payload).catch(err => console.log('slack err', err.response));
    }
  } catch (e) {
    if (__DEV__) {
      console.log('newPurchase notification error: ', e);
    }
  }
};

export const purchaseError = data => {
  if (!__DEV__) {
    const url = 'https://hooks.slack.com/services/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';
    const payload = {
      text: dedent`uid: ${data?.uid}
                  stars: ${data?.stars}
                  error: ${data?.error}
                  countryCode: ${data?.countryCode}
                  country: ${data?.country}
                  timezone: ${data?.timezone}
                  regionName: ${data?.regionName}
                  platform: ${data?.platform} ${isInStandaloneMode() ? '(PWA)' : ''}
                  uniqueId: ${data?.tiktok?.uniqueId}
                  nickName: ${data?.tiktok?.nickName}
                  video: ${data?.tiktok?.video}
                  followingCount: ${data?.tiktok?.followingCount}
                  followerCount: ${data?.tiktok?.followerCount}
                  avatar: ${data?.tiktok?.avatar}
                  version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
    };
    if (Platform.OS === 'web') {
      slackNotificationFunction({ payload, url });
      return;
    }
    axios.post(url, payload).catch(err => console.log('slack err', err.response));
  }
};

export const newRedeem = data => {
  try {
    if (!__DEV__) {
      const redeemFollowUrl =
        'https://hooks.slack.com/services/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';
      const redeemLikeUrl =
        'https://hooks.slack.com/services/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';
      const redeemWebFollowUrl =
        'https://hooks.slack.com/services/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';
      const redeemWebLikeUrl =
        'https://hooks.slack.com/services/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';
      const redeemWebCommentUrl =
        'https://hooks.slack.com/services/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';

      let url = '';
      if (Platform.OS === 'web') {
        if (data.type === 'like') {
          url = redeemWebLikeUrl;
        } else if (data.type === 'follower') {
          url = redeemWebFollowUrl;
        } else {
          url = redeemWebCommentUrl;
        }
      } else {
        if (data.type === 'like') {
          url = redeemLikeUrl;
        } else {
          url = redeemFollowUrl;
        }
      }

      const payload = {
        text: dedent`uid: ${data?.uid}
                    orderCount: ${data?.orderCount}
                    stars: ${data?.stars}
                    countryCode: ${data?.countryCode}
                    country: ${data?.country}
                    timezone: ${data?.timezone}
                    regionName: ${data?.regionName}
                    platform: ${data?.platform} ${isInStandaloneMode() ? '(PWA)' : ''}
                    uniqueId: ${data?.tiktok?.uniqueId}
                    nickName: ${data?.tiktok?.nickName}
                    video: ${data?.tiktok?.video}
                    followingCount: ${data?.tiktok?.followingCount}
                    followerCount: ${data?.tiktok?.followerCount}
                    avatar: ${data?.tiktok?.avatar}
                    webVideoUrl: ${data?.webVideoUrl ?? ''}
                    version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
      };
      if (Platform.OS === 'web') {
        slackNotificationFunction({ payload, url });
        return;
      }
      axios.post(url, payload).catch(err => console.log('slack err', err.response));
    }
  } catch (e) {
    console.log('newRedeem error', e);
  }
};

export const newFollow = data => {
  if (!__DEV__) {
    const followUrl =
      'https://hooks.slack.com/services/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';
    const likeUrl =
      'https://hooks.slack.com/services/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';
    const webFollowUrl =
      'https://hooks.slack.com/services/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';
    const webLikeUrl =
      'https://hooks.slack.com/services/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';
    const commentUrl =
      'https://hooks.slack.com/services/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';

    let url = '';

    if (Platform.OS === 'web') {
      if (data.type === 'like') {
        url = webLikeUrl;
      } else {
        url = webFollowUrl;
      }
    } else {
      if (data.type === 'like') {
        url = likeUrl;
      } else if (data.type === 'comment') {
        url = commentUrl;
      } else {
        url = followUrl;
      }
    }

    const payload = {
      text: dedent`uid: ${data?.uid}
                  orderCount: ${data?.orderCount}
                  stars: ${data?.stars}
                  countryCode: ${data?.countryCode}
                  country: ${data?.country}
                  timezone: ${data?.timezone}
                  regionName: ${data?.regionName}
                  platform: ${data?.platform} ${isInStandaloneMode() ? '(PWA)' : ''}
                  uniqueId: ${data?.tiktok?.uniqueId}
                  nickName: ${data?.tiktok?.nickName}
                  video: ${data?.tiktok?.video}
                  followingCount: ${data?.tiktok?.followingCount}
                  followerCount: ${data?.tiktok?.followerCount}
                  digg: ${data?.tiktok?.diggCount}
                  avatar: ${data?.tiktok?.avatar}
                  version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
    };
    if (Platform.OS === 'web' && Math.random() < 0.3) {
      slackNotificationFunction({ payload, url });
      return;
    }
    axios.post(url, payload).catch(err => console.log('slack err', err.response));
  }
};

export const newReview = data => {
  try {
    if (!__DEV__) {
      const url =
        Platform.OS === 'web'
          ? 'https://hooks.slack.com/services/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'
          : 'https://hooks.slack.com/services/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';
      const payload = {
        text: dedent`uid: ${data?.uid}
                    orderCount: ${data?.orderCount}
                    stars: ${data?.stars}
                    countryCode: ${data?.countryCode}
                    country: ${data?.country}
                    timezone: ${data?.timezone}
                    regionName: ${data?.regionName}
                    platform: ${data?.platform} ${isInStandaloneMode() ? '(PWA)' : ''}
                    uniqueId: ${data?.tiktok?.uniqueId}
                    nickName: ${data?.tiktok?.nickName}
                    video: ${data?.tiktok?.video}
                    followingCount: ${data?.tiktok?.followingCount}
                    followerCount: ${data?.tiktok?.followerCount}
                    avatar: ${data?.tiktok?.avatar}
                    version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
      };
      if (Platform.OS === 'web') {
        slackNotificationFunction({ payload, url });
        return;
      }
      axios.post(url, payload).catch(err => console.log('slack err', err.response));
    }
  } catch (e) {
    if (__DEV__) {
      console.log('newReview error', e);
    }
  }
};

export const getUserInfoError = data => {
  try {
    if (!__DEV__) {
      const url = 'https://hooks.slack.com/services/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';
      const payload = {
        text: dedent`API: TikTokUserInfo
                     ENV: ${data.env ?? Platform.OS}
                     URL: https://www.tiktok.com/@${data.username}
                     catchError: ${data.catchError ?? false}
                     data: ${JSON.stringify(data)}
                     version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}
                     `,
      };
      if (Platform.OS === 'web') {
        slackNotificationFunction({ payload, url });
        return;
      }
      axios.post(url, payload).catch(err => console.log('slack err', err.response));
    }
  } catch (e) {
    console.log('call getUserInfoError: ', e);
  }
};

export const newPayPalEvent = data => {
  try {
    if (!__DEV__) {
      const url = 'https://hooks.slack.com/services/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';
      const payload = {
        text: dedent`type: ${data?.type}
                   transactionId: ${data?.message?.purchase_units?.[0].payments?.captures?.[0].id}
                   message: ${JSON.stringify(data?.message)}
                   price: ${data?.price}
                   countryCode: ${data?.countryCode}
                   timeZone: ${data?.timezone}
                   platform: ${data?.platform}
                   uid: ${data?.uid}
                   avatar: ${data?.tiktok?.avatar}
                   postMessage: ${JSON.stringify(data?.postMessage)}
                   userAgent: ${window?.navigator?.userAgent}
                   version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
      };
      if (Platform.OS === 'web') {
        slackNotificationFunction({ payload, url });
        return;
      }
      axios.post(url, payload).catch(err => console.log('slack err', err.response));
    }
  } catch (e) {
    if (__DEV__) {
      console.log('newPayPalEvent error', e);
    }
  }
};

export const newApplePayEvent = data => {
  try {
    if (!__DEV__) {
      const url = 'https://hooks.slack.com/services/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';
      const payload = {
        text: dedent`type: ${data?.type}
                   message: ${JSON.stringify(data?.message)}
                   price: ${data?.price}
                   countryCode: ${data?.countryCode}
                   timeZone: ${data?.timezone}
                   platform: ${data?.platform}
                   email: ${data?.email}
                   uid: ${data?.uid}
                   avatar: ${data?.tiktok?.avatar}
                   version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
      };
      if (Platform.OS === 'web') {
        slackNotificationFunction({ payload, url });
        return;
      }
      axios.post(url, payload).catch(err => console.log('slack err', err.response));
    }
  } catch (e) {
    if (__DEV__) {
      console.log('newApplePayEvent notification error: ', e);
    }
  }
};

export const newTapPayEvent = data => {
  try {
    if (!__DEV__) {
      const url = 'https://hooks.slack.com/services/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';
      const payload = {
        text: dedent`type: ${data?.type}
                   message: ${JSON.stringify(data?.message)}
                   price: ${data?.price}
                   countryCode: ${data?.countryCode}
                   timeZone: ${data?.timezone}
                   platform: ${data?.platform}
                   uid: ${data?.uid}
                   uniqueId: ${data?.tiktok?.uniqueId}
                   avatar: ${data?.tiktok?.avatar}
                   version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
      };
      if (Platform.OS === 'web') {
        slackNotificationFunction({ payload, url });
        return;
      }
      axios.post(url, payload).catch(err => console.log('slack err', err.response));
    }
  } catch (e) {
    if (__DEV__) {
      console.log('newApplePayEvent notification error: ', e);
    }
  }
};

export const tapWebPurchase = async data => {
  try {
    if (!__DEV__) {
      const url = 'https://hooks.slack.com/services/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';
      const payload = {
        text: dedent`uid: ${data?.uid}
                   uniqueId: ${data?.tiktok?.uniqueId}
                   platform: ${data?.platform}
                   applePay: ${data?.applePay}
                   price: ${data?.price}
                   countryCode: ${data?.countryCode}
                   timeZone: ${data?.timezone}
                   avatar: ${data?.tiktok?.avatar}
                   version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
      };
      slackNotificationFunction({ payload, url });
    }
  } catch (e) {
    if (__DEV__) {
      console.log('tapWebPurchase error', e);
    }
  }
};

export const tapPromotion = data => {
  try {
    if (!__DEV__) {
      const url = 'https://hooks.slack.com/services/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';
      const payload = {
        text: dedent`uid: ${data?.uid}
                   uniqueId: ${data?.tiktok?.uniqueId}
                   platform: ${data?.platform}
                   countryCode: ${data?.countryCode}
                   timeZone: ${data?.timezone}
                   avatar: ${data?.tiktok?.avatar}
                   version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
      };
      slackNotificationFunction({ payload, url });
    }
  } catch (e) {
    if (__DEV__) {
      console.log('tapPromotion error: ', e);
    }
  }
};

export const newReferral = data => {
  try {
    if (!__DEV__) {
      const url =
        Platform.OS === 'web'
          ? 'https://hooks.slack.com/services/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'
          : 'https://hooks.slack.com/services/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';
      const payload = {
        text: dedent`uid: ${data?.uid}
                     uniqueId: ${data?.tiktok?.uniqueId}
                     stars: ${data?.stars}
                     countryCode: ${data?.countryCode}
                     country: ${data?.country}
                     timezone: ${data?.timezone}
                     regionName: ${data?.regionName}
                     platform: ${data?.platform} ${isInStandaloneMode() ? '(PWA)' : ''}
                     referrerUid: ${data?.referrerUid}
                     referrerUniqueId: ${data?.referrerUniqueId}
                     avatar: ${data?.tiktok?.avatar}
                     version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
      };
      if (Platform.OS === 'web') {
        slackNotificationFunction({ payload, url });
        return;
      }
      axios.post(url, payload).catch(err => console.log('slack err', err.response));
    }
  } catch (e) {
    if (!__DEV__) {
      console.log('newReferral error');
    }
  }
};

export const suspiciousReferral = data => {
  try {
    if (!__DEV__) {
      const url = 'https://hooks.slack.com/services/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';
      const payload = {
        text: dedent`uid: ${data?.uid}
                     uniqueId: ${data?.tiktok?.uniqueId}
                     stars: ${data?.stars}
                     countryCode: ${data?.countryCode}
                     country: ${data?.country}
                     timezone: ${data?.timezone}
                     regionName: ${data?.regionName}
                     platform: ${data?.platform} ${isInStandaloneMode() ? '(PWA)' : ''}
                     referralCount: ${data?.referralCount}
                     referralStars: ${data?.referralStars}
                     avatar: ${data?.tiktok?.avatar}
                     version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
      };
      if (Platform.OS === 'web') {
        slackNotificationFunction({ payload, url });
        return;
      }
      axios.post(url, payload).catch(err => console.log('slack err', err.response));
    }
  } catch (e) {
    if (__DEV__) {
      console.log('suspiciousReferral error');
    }
  }
};

export const suspiciousAccount = data => {
  if (!__DEV__) {
    const url = 'https://hooks.slack.com/services/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';
    const payload = {
      text: dedent`uid: ${data?.uid}
                   uniqueId: ${data?.tiktok?.uniqueId}
                   suspiciousCount: ${data?.suspiciousCount}
                   stars: ${data?.stars}
                   countryCode: ${data?.countryCode}
                   country: ${data?.country}
                   timezone: ${data?.timezone}
                   regionName: ${data?.regionName}
                   platform: ${data?.platform} ${isInStandaloneMode() ? '(PWA)' : ''}
                   prevDiggCount: ${data?.prevDiggCount}
                   currentDiggCount: ${data?.currentDiggCount}
                   prevFollowingCount: ${data?.prevFollowingCount}
                   currentFollowingCount: ${data?.currentFollowingCount}
                   avatar: ${data?.tiktok?.avatar}
                   version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
    };
    if (Platform.OS === 'web') {
      slackNotificationFunction({ payload, url });
      return;
    }
    axios.post(url, payload).catch(err => console.log('slack err', err.response));
  }
};

export const blockedAccount = data => {
  if (!__DEV__) {
    const url = 'https://hooks.slack.com/services/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';
    const payload = {
      text: dedent`uid: ${data?.uid}
                   uniqueId: ${data?.tiktok?.uniqueId}
                   suspiciousCount: ${data?.suspiciousCount}
                   stars: ${data?.stars}
                   countryCode: ${data?.countryCode}
                   country: ${data?.country}
                   timezone: ${data?.timezone}
                   regionName: ${data?.regionName}
                   platform: ${data?.platform} ${isInStandaloneMode() ? '(PWA)' : ''}
                   prevDiggCount: ${data?.prevDiggCount}
                   currentDiggCount: ${data?.currentDiggCount}
                   prevFollowingCount: ${data?.prevFollowingCount}
                   currentFollowingCount: ${data?.currentFollowingCount}
                   avatar: ${data?.tiktok?.avatar}
                   version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
    };
    if (Platform.OS === 'web') {
      slackNotificationFunction({ payload, url });
      return;
    }
    axios.post(url, payload).catch(err => console.log('slack err', err.response));
  }
};

export const redeemWebFirstLoginReward = data => {
  if (!__DEV__) {
    const url = 'https://hooks.slack.com/services/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';
    const payload = {
      text: dedent`uid: ${data?.uid}
                   uniqueId: ${data?.tiktok?.uniqueId}
                   stars: ${data?.stars}
                   countryCode: ${data?.countryCode}
                   country: ${data?.country}
                   timezone: ${data?.timezone}
                   regionName: ${data?.regionName}
                   platform: ${data?.platform} ${isInStandaloneMode() ? '(PWA)' : ''}
                   hasRedeemedIOSFirstLoginReward: ${data?.hasRedeemedIOSFirstLoginReward}
                   avatar: ${data?.tiktok?.avatar}
                   version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
    };
    if (Platform.OS === 'web') {
      slackNotificationFunction({ payload, url });
      return;
    }
    axios.post(url, payload).catch(err => console.log('slack err', err.response));
  }
};

export const rewardDailyLogin = data => {
  if (!__DEV__) {
    const url =
      data?.dailyRewardCount > 15
        ? 'https://hooks.slack.com/services/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'
        : 'https://hooks.slack.com/services/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';
    const payload = {
      text: dedent`uid: ${data?.uid}
                   uniqueId: ${data?.tiktok?.uniqueId}
                   dailyRewardCount: ${data?.dailyRewardCount}
                   stars: ${data?.stars}
                   countryCode: ${data?.countryCode}
                   country: ${data?.country}
                   timezone: ${data?.timezone}
                   regionName: ${data?.regionName}
                   platform: ${data?.platform} ${isInStandaloneMode() ? '(PWA)' : ''}
                   avatar: ${data?.tiktok?.avatar}
                   date: ${new Date().getDate()}
                   version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
    };
    if (Platform.OS === 'web') {
      slackNotificationFunction({ payload, url });
      return;
    }
    axios.post(url, payload).catch(err => console.log('slack err', err.response));
  }
};

export const getTimeError = data => {
  if (!__DEV__) {
    const url = 'https://hooks.slack.com/services/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';
    const payload = {
      text: dedent`uid: ${data?.uid}
                   uniqueId: ${data?.tiktok?.uniqueId}
                   dailyRewardCount: ${data?.dailyRewardCount}
                   stars: ${data?.stars}
                   countryCode: ${data?.countryCode}
                   country: ${data?.country}
                   timezone: ${data?.timezone}
                   regionName: ${data?.regionName}
                   platform: ${data?.platform} ${isInStandaloneMode() ? '(PWA)' : ''}
                   avatar: ${data?.tiktok?.avatar}
                   error: ${String(data?.error)}
                   version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
    };
    if (Platform.OS === 'web') {
      slackNotificationFunction({ payload, url });
      return;
    }
    axios.post(url, payload).catch(err => console.log('slack err', err.response));
  }
};

export const uidChanged = data => {
  try {
    if (!__DEV__) {
      const url = 'https://hooks.slack.com/services/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';
      const payload = {
        text: dedent`oldUid: ${data?.uid}
                     newUid: ${data.newUid}
                     uniqueId: ${data?.tiktok?.uniqueId}
                     stars: ${data?.stars}
                     countryCode: ${data?.countryCode}
                     country: ${data?.country}
                     timezone: ${data?.timezone}
                     regionName: ${data?.regionName}
                     platform: ${data?.platform} ${isInStandaloneMode() ? '(PWA)' : ''}
                     avatar: ${data?.tiktok?.avatar}
                     error: ${String(data?.error)}
                     version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
      };
      if (Platform.OS === 'web') {
        slackNotificationFunction({ payload, url });
        return;
      }
      axios.post(url, payload).catch(err => console.log('slack err', err.response));
    }
  } catch (e) {
    if (__DEV__) {
      console.log('slack uidChanged error', e);
    }
  }
};

export const suspiciousTransaction = data => {
  try {
    if (!__DEV__) {
      const url = 'https://hooks.slack.com/services/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';
      const payload = {
        text: dedent`uid: ${data.uid}
                     uniqueId: ${data?.tiktok?.uniqueId}
                     stars: ${data?.stars}
                     countryCode: ${data?.countryCode}
                     country: ${data?.country}
                     timezone: ${data?.timezone}
                     regionName: ${data?.regionName}
                     platform: ${data?.platform} ${isInStandaloneMode() ? '(PWA)' : ''}
                     avatar: ${data?.tiktok?.avatar}
                     orderId: ${data?.orderId}
                     purchaseToken: ${data?.purchaseToken}
                     version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
      };
      if (Platform.OS === 'web') {
        slackNotificationFunction({ payload, url });
        return;
      }
      axios.post(url, payload).catch(err => console.log('slack err', err.response));
    }
  } catch (e) {
    if (__DEV__) {
      console.log('suspiciousTransaction error', e);
    }
  }
};

export const anonymousStars = data => {
  try {
    if (!__DEV__) {
      const url = 'https://hooks.slack.com/services/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';
      const payload = {
        text: dedent`uid: ${data.uid}
                     uniqueId: ${data?.tiktok?.uniqueId}
                     stars: ${data?.stars}
                     countryCode: ${data?.countryCode}
                     country: ${data?.country}
                     timezone: ${data?.timezone}
                     regionName: ${data?.regionName}
                     platform: ${data?.platform} ${isInStandaloneMode() ? '(PWA)' : ''}
                     avatar: ${data?.tiktok?.avatar}
                     version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
      };
      if (Platform.OS === 'web') {
        slackNotificationFunction({ payload, url });
        return;
      }
      axios.post(url, payload).catch(err => console.log('slack err', err.response));
    }
  } catch (e) {
    if (__DEV__) {
      console.log('anonymousStars slack error', e);
    }
  }
};

export const errorLog = data => {
  try {
    if (!__DEV__) {
      const url = 'https://hooks.slack.com/services/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';
      const payload = {
        text: dedent`functionName: ${data?.functionName}
                     errorMessage: ${data?.errorMessage}
                     uid: ${data.uid}
                     uniqueId: ${data?.tiktok?.uniqueId}
                     countryCode: ${data?.countryCode}
                     country: ${data?.country}
                     timezone: ${data?.timezone}
                     regionName: ${data?.regionName}
                     platform: ${data?.platform} ${isInStandaloneMode() ? '(PWA)' : ''}
                     avatar: ${data?.tiktok?.avatar}
                     version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
      };
      if (Platform.OS === 'web') {
        slackNotificationFunction({ payload, url });
        return;
      }
      axios.post(url, payload).catch(err => console.log('slack err', err.response));
    }
  } catch (e) {
    if (__DEV__) {
      console.log('errorLog slack error', e);
    }
  }
};

export const log = data => {
  try {
    const url = 'https://hooks.slack.com/services/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';
    const payload = {
      text: dedent`logName: ${data?.logName}
                   logMessage: ${data?.logMessage}
                   uid: ${data.uid}
                   uniqueId: ${data?.tiktok?.uniqueId}
                   countryCode: ${data?.countryCode}
                   country: ${data?.country}
                   timezone: ${data?.timezone}
                   regionName: ${data?.regionName}
                   platform: ${data?.platform} ${isInStandaloneMode() ? '(PWA)' : ''}
                   avatar: ${data?.tiktok?.avatar}
                   iosVersion: ${getIOSVersion()}
                   userAgent: ${window?.navigator?.userAgent}
                   version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
    };
    if (Platform.OS === 'web') {
      slackNotificationFunction({ payload, url });
      return;
    }
    axios.post(url, payload).catch(err => console.log('slack err', err.response));
  } catch (e) {
    errorLog({
      functionName: 'slack log function',
      errorMessage: String(e),
      ...data,
    });
  }
};

export const newPendingTapPayEvent = data => {
  try {
    if (!__DEV__) {
      const url = 'https://hooks.slack.com/services/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';
      const payload = {
        text: dedent`type: ${data?.type}
                   message: ${JSON.stringify(data?.message)}
                   price: ${data?.price}
                   countryCode: ${data?.countryCode}
                   timeZone: ${data?.timezone}
                   platform: ${data?.platform}
                   uid: ${data?.uid}
                   uniqueId: ${data?.tiktok?.uniqueId}
                   avatar: ${data?.tiktok?.avatar}
                   record_status: ${data?.result?.record_status}
                   rec_trade_id: ${data?.result?.rec_trade_id}
                   email: ${data?.result?.email}
                   version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
      };
      if (Platform.OS === 'web') {
        slackNotificationFunction({ payload, url });
        return;
      }
      axios.post(url, payload).catch(err => console.log('slack err', err.response));
    }
  } catch (e) {
    if (__DEV__) {
      console.log('newApplePayEvent notification error: ', e);
    }
  }
};
