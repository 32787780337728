import * as React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Platform, StatusBar, StyleSheet, View, Text, Linking, AsyncStorage } from 'react-native';
import { SplashScreen } from 'expo';
import { Provider } from 'react-redux';
import { Ionicons, AntDesign, FontAwesome5 } from '@expo/vector-icons';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { ThemeProvider } from 'styled-components/native';
// import { enableScreens } from 'react-native-screens';
import analytics from '@react-native-firebase/analytics';

import 'utils/silentTimerWarnings';
import BottomTabNavigator from 'navigation/BottomTabNavigator';
import useLinking from 'navigation/useLinking';
import Colors from 'constants/Colors';
import LoginScreen from 'screens/LoginScreen';
import TutorialScreen from 'screens/TutorialScreen';
import IntroductionScreen from 'screens/IntroductionScreen';
import AppleLoginScreen from 'screens/AppleLoginScreen';

import SelectPaymentScreen from 'modules/payment/screens/SelectPaymentScreen';
// import { getAppVersion } from 'utils/versionUtils';

import { onAuthStateChangedAction } from 'actions/userActions';
import { initApplePay, connectAppStore } from 'actions/paymentActions';
import { fetchCurrentTimeAction } from 'actions/timeAction';
// import { registerAppWithFCM } from 'apis/firebase';

import configureStore from 'store/configureStore';

import ServiceDownScreen from 'screens/ServiceDownScreen';

// enableScreens();
const Stack = createStackNavigator();

const getActiveRouteName = state => {
  const route = state.routes[state.index];

  if (route.state) {
    // Dive into nested navigators
    return getActiveRouteName(route.state);
  }

  return route.name;
};

const { store, persistor } = configureStore();

export default function App(props) {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);
  const [initialNavigationState, setInitialNavigationState] = React.useState();
  const routeNameRef = React.useRef();
  const navigationRef = React.useRef();
  const { getInitialState } = useLinking(navigationRef);

  // React.useEffect(() => {
  //   if (Platform.OS === 'web') {
  //     const isIOS = !!window.navigator.userAgent && /iPad|iPhone|iPod/.test(window.navigator.userAgent)
  //     const handleScreenFocus = async () => {
  //       if (isIOS) {
  //         const currentVersion = await AsyncStorage.getItem('appVersion');
  //         const version = await getAppVersion();
  //         if (version !== currentVersion) {
  //           await AsyncStorage.setItem('appVersion', version);
  //           window.location.reload(true);
  //         }
  //       }
  //     }

  //     window.addEventListener('focus', handleScreenFocus, false)
  //     return () => {
  //       window.removeEventListener('focus', handleScreenFocus, false)
  //     }
  //   }
  // }, []);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHide();
        // Load our initial navigation state
        const initialNavigationState = await getInitialState();
        setInitialNavigationState(initialNavigationState);
        // For screen tracking
        routeNameRef.current = initialNavigationState;
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hide();
      }
    }
    loadResourcesAndDataAsync();
    store.dispatch(onAuthStateChangedAction());
    store.dispatch(initApplePay());
    store.dispatch(connectAppStore());
    store.dispatch(fetchCurrentTimeAction());
    // registerAppWithFCM();
  }, []);

  if (!isLoadingComplete && !props.skipLoadingScreen) {
    return null;
  } else {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={Colors}>
            <View style={styles.container}>
              {Platform.OS === 'ios' && <StatusBar barStyle="dark-content" />}
              <NavigationContainer
                ref={navigationRef}
                initialState={initialNavigationState}
                onStateChange={state => {
                  const previousRouteName = routeNameRef.current;
                  const currentRouteName = getActiveRouteName(state);
                  if (previousRouteName !== currentRouteName) {
                    // The line below uses the @react-native-firebase/analytics tracker
                    // Change this line to use another Mobile analytics SDK
                    if (Platform.OS === 'default') {
                      analytics().setCurrentScreen(currentRouteName, currentRouteName);
                    }
                  }
                  // Save the current route name for later comparision
                  routeNameRef.current = currentRouteName;
                }}
              >
                <Stack.Navigator headerMode="none" mode="modal">
                  <Stack.Screen name="Root" component={BottomTabNavigator} />
                  <Stack.Screen
                    name="Login"
                    component={LoginScreen}
                    options={{
                      gestureEnabled: false,
                    }}
                  />
                  <Stack.Screen
                    name="Tutorial"
                    component={TutorialScreen}
                  // options={{}}
                  />
                  <Stack.Screen
                    name="Introduction"
                    component={IntroductionScreen}
                  />
                  <Stack.Screen name="SelectPayment" component={SelectPaymentScreen} />
                  <Stack.Screen name="AppleLogin" component={AppleLoginScreen} />
                </Stack.Navigator>
              </NavigationContainer>
            </View>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});
