import * as React from 'react';
import { path } from 'ramda';
import {
  Platform,
  View,
  Text,
  Image,
  TouchableOpacity,
  Modal as NativeModal,
  ActivityIndicator,
  Share
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/native';
import WebModal from 'modal-enhanced-react-native-web';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import dedent from 'dedent';
import i18n from 'i18n';

import { createUserDbRecord } from 'actions/userActions';

const Modal = Platform.select({
  web: WebModal,
  default: NativeModal,
});

export const onPressShare = (uniqueId) => {
  if (Platform.OS === 'web') {
    if (navigator.share) {
      navigator.share({
        title: `${i18n.t('share_message_1')} ${uniqueId} ${i18n.t('share_message_2')}`,
        text: `${i18n.t('share_message_1')} ${uniqueId} ${i18n.t('share_message_2')}`,
        url: 'https://tikfans.page.link/invite'
      })
    }
  } else {
    Share.share({
      message: `Try TikFans App: https://tikfans.page.link/invite. Use my Promo Code: ${uniqueId} to get FREE 20 Stars for TikTok followers and likes.`,
    })
  }
};

const InviteModal = ({ visible, setIsInviteModalOpen }) => {
  const dispatch = useDispatch();
  const uniqueId = useSelector(state => state.user?.tiktok?.uniqueId);
  const hasUserDbRecord = useSelector(state => state.user?.hasUserDbRecord);
  const onPressInviteButton = async () => {
    if (!hasUserDbRecord) {
      dispatch(createUserDbRecord());
    }
    onPressShare(uniqueId);
  };

  return (
    <Modal
      visible={visible}
      transparent={true}
      style={{ margin: 0 }}
    >
      <InviteModalWrapper
        keyboardShouldPersistTaps="handled"
        enableOnAndroid={true}
        removeClippedSubviews={false}
        contentContainerStyle={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
      >
        <InviteModalBody>
          <InviteModalImage source={require('assets/images/invite.png')} />
          <InviteModalBottomArea>
            <InviteModalBottomAreaInstruction>
              {
                dedent`${i18n.t('invite_message_1')}
                      ${i18n.t('invite_message_2')}`
              }
            </InviteModalBottomAreaInstruction>
            <StyledTextInput>
              <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{uniqueId}</Text>
              <CopyIcon source={require('assets/images/copy.png')} />
            </StyledTextInput>
            <ButtonsWrapper>
              <InviteModalButton
                isCancel={true}
                onPress={() => setIsInviteModalOpen(false)}
              >
                <ButtonText>Cancel</ButtonText>
              </InviteModalButton>
              <InviteModalButton
                onPress={onPressInviteButton}
              >
                <ButtonText>Invite</ButtonText>
              </InviteModalButton>
            </ButtonsWrapper>
          </InviteModalBottomArea>
        </InviteModalBody>
      </InviteModalWrapper>
    </Modal>
  );
};

export default InviteModal;

const StyledTextInput = styled(TouchableOpacity)`
  width: 100%;
  height: 40;
  border-bottom-width: 1;
  border-color: ${path(['theme', 'primary', 'lightGray'])};
  margin-top: 10;
  padding-left: 10;
  padding-right: 10;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const CopyIcon = styled(Image)`
  width: 25;
  height: 26;
`;

const InviteModalWrapper = styled(KeyboardAwareScrollView)`
  flex: 1;
  background-color: rgba(0, 0, 0, 0.3);
`;

const InviteModalBody = styled(View)`
  width: 90%;
  height: 400;
  max-width: 370;
  background-color: #FFFF;
  border-radius: 24px;
  overflow: hidden;
  shadow-opacity: 0.65;
  shadow-radius: 5px;
  shadow-color: black;
  shadow-offset: 0px 0px;
  justify-content: center;
`;

const InviteModalImage = styled(Image)`
  width: 66;
  height: 66;
  align-self: center;
  margin-bottom: 10;
`;

const InviteModalBottomArea = styled(View)`
  width: 100%;
  height: 54%;
  padding: 22px;
`;

const InviteModalBottomAreaInstruction = styled(Text)`
  font-size: 18;
  font-weight: 600;
  line-height: 20;
  color: ${path(['theme', 'primary', 'lightGray'])}
`;

const ButtonsWrapper = styled(View)`
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 10;
`;

const InviteModalButton = styled(TouchableOpacity)`
  width: 124;
  height: 49;
  border-radius: 24;
  margin-top: 16;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.isCancel ? path(['theme', 'primary', 'gray']) : path(['theme', 'primary', 'purple'])}
`;

const ButtonText = styled(Text)`
  font-size: 18;
  font-weight: bold;
  color: #FFFF;
`;

const StyledActivityIndicator = styled(ActivityIndicator)`
  position: absolute;
  margin: auto;
  top: 0; 
  left: 0; 
  bottom: 0; 
  right: 0;
  z-index: 99;
`;
