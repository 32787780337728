import * as React from "react";
import { path } from "ramda";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import {
  Platform,
  ActivityIndicator,
  Linking,
  Dimensions,
  View,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import styled from "styled-components/native";
import * as InAppPurchases from "expo-in-app-purchases";
import * as StoreReview from "expo-store-review";
import useAppState from "react-native-appstate-hook";

import Colors from "constants/Colors";
import { IAP_PRODUCTS, getProductList } from "constants/Products";
import {
  purchaseStarsAction,
  purchaseErrorAction,
  rewardReviewAction,
  createTransactionRecordAction,
  newPayPalEventAction,
  slackLog,
} from "actions/userActions";
import {
  syncPurchaseHistory,
  unprocessedPurchaseAction,
  unprocessedTransactionAction,
  checkStillUnprocessedPurchase,
  checkStillUnprocessedTransaction,
} from "actions/paymentActions";
import { newPayPalEvent } from "apis/slack";
import {
  WebStarPurchaseItem,
  StarPurchaseItemWithIAP,
  FreePurchaseItem,
} from "modules/payment/components/StarPurchaseItem";
import Alert from "components/Alert";
import DirectPurchaseScreen from "screens/DirectPurchaseScreen";

const { height: screenHeight } = Dimensions.get("window");

const PurchaseItem = Platform.select({
  web: WebStarPurchaseItem,
  default: StarPurchaseItemWithIAP,
});

// const initialListContents = [
//   { stars: 700, productId: 'com.tikfans.www.10dollars.weekly', discount: '60', price: '$9.99', isSubscription: true },
//   { stars: 50, productId: 'com.tikfans.www.2dollars', discount: '0', price: '$1.99' },
//   // { stars: 75, productId: 'com.tikfans.www.3dollars', discount: '0', price: '$2.99' },
//   { stars: 150, productId: 'com.tikfans.www.5dollars', discount: '17', price: '$4.99' },
//   { stars: 350, productId: 'com.tikfans.www.10dollars', discount: '29', price: '$9.99' },
//   { stars: 750, productId: 'com.tikfans.www.20dollars', discount: '33', price: '$19.99', promotion: true },
//   { stars: 1000, productId: 'com.tikfans.www.25dollars', discount: '36', price: '$24.99' },
//   { stars: 2000, productId: 'com.tikfans.www.50dollars', discount: '40', price: '$48.99' },
//   { stars: 4000, productId: 'com.tikfans.www.80dollars', discount: '50', price: '$79.99' },
//   { stars: 6000, productId: 'com.tikfans.www.100dollars', discount: '60', price: '$99.99' },
// ];

const initialListContents = getProductList({ purchaseHistory: [] });

const PurchaseScreen = ({ route, navigation }) => {
  const [listContents, setListContents] = React.useState(initialListContents);
  const [isLoading, setIsLoading] = React.useState(false);
  const [wentToReview, setWentToReview] = React.useState(false);
  const user = useSelector((state) => state.user);
  const { unprocessedPurchase, unprocessedTransaction } = useSelector(
    (state) => state.payment
  );
  const purchaseHistory = useSelector((state) => state?.payment?.history);
  const hasReviewed = user?.hasReviewed ?? false;
  const countryCode = user?.countryCode;
  const uniqueId = user?.tiktok?.uniqueId;
  const showDirectPurchaseScreen =
    countryCode === "TW" && uniqueId !== "vvan989";

  const dispatch = useDispatch();

  useAppState({
    onForeground: async () => {
      if (wentToReview) {
        dispatch(rewardReviewAction());
        setWentToReview(false);
      }
    },
  });

  // React.useEffect(() => {
  //   if (showDirectPurchaseScreen) {
  //     navigation.setOptions({
  //       title: 'Products',
  //       headerRight: null,
  //     });
  //   }
  // }, [showDirectPurchaseScreen]);

  const targetOrigin = __DEV__
    ? `http://localhost:19007`
    : "https://www.monido.co";

  // const receiveMessage = async event => {
  //   event.preventDefault();
  //   const data = event.data;

  //   if (event.origin !== targetOrigin) {
  //     if (__DEV__) {
  //       // console.log('event.origin !== targetOrigin', event.origin);
  //       // console.log('event.data', event.data);
  //     }
  //     return;
  //   }

  //   if (data?.eventName === 'onPayPalEvent') {
  //     if (data?.type === 'onApprove') {
  //       const purchase = {
  //         stars: data?.stars,
  //         details: data?.message,
  //       };
  //       const transaction = {
  //         paypal: {
  //           details: data?.message,
  //           price: data?.price,
  //           productId: data?.productId,
  //         },
  //       };
  //       dispatch(unprocessedPurchaseAction(purchase));
  //       dispatch(unprocessedTransactionAction(transaction));
  //       await dispatch(purchaseStarsAction(purchase));
  //       await dispatch(createTransactionRecordAction(transaction));
  //       if (window.dataLayer) {
  //         window.dataLayer.push({
  //           event: 'onPayPalCaptureApprove',
  //           amount: path(['purchase_units', 0, 'payments', 'captures', 0, 'amount'])(data?.message),
  //           transactionId: path(['purchase_units', 0, 'payments', 'captures', 0, 'id'])(
  //             data?.message,
  //           ),
  //           productId: data?.productId,
  //         });
  //       }
  //     }
  //     dispatch(
  //       newPayPalEventAction({
  //         ...data,
  //         postMessage: true,
  //       }),
  //     );
  //   }
  // };

  // React.useEffect(() => {
  //   window.addEventListener('message', receiveMessage, false);
  //   return () => {
  //     window.removeEventListener('message', receiveMessage);
  //   };
  // }, [user?.tiktok?.uniqueId, window.dataLayer]);

  // React.useEffect(() => {
  //   // console.log('unprocessedPurchase', unprocessedPurchase);
  //   setTimeout(() => {
  //     if (!_.isEmpty(unprocessedPurchase) && !dispatch(checkStillUnprocessedPurchase())) {
  //       dispatch(purchaseStarsAction(unprocessedPurchase));
  //     }
  //   }, 5000);
  // }, [unprocessedPurchase, unprocessedTransaction]);

  // React.useEffect(() => {
  //   // console.log('unprocessedTransaction', unprocessedTransaction);
  //   setTimeout(() => {
  //     if (!_.isEmpty(unprocessedTransaction) && !dispatch(checkStillUnprocessedTransaction())) {
  //       dispatch(createTransactionRecordAction(unprocessedTransaction));
  //     }
  //   }, 5000);
  // }, [unprocessedTransaction]);

  // return (<iframe id="pFrame" style={{ flex: 1 }} src={`${targetOrigin}/purchase`} frameBorder="0"></iframe>);

  return (
    <StyledScrollView>
      {isLoading && (
        <StyledActivityIndicator
          size="large"
          color={Colors.primary.lightGray}
        />
      )}
      {listContents.map((content) => (
        <PurchaseItem
          key={content.productId}
          content={content}
          setIsLoading={setIsLoading}
          onPayPalCaptureApprove={({ stars, details = {} }) => {
            dispatch(purchaseStarsAction({ stars, details }));
          }}
          onApplePayApprove={({ stars }) => {
            dispatch(purchaseStarsAction({ stars, isApplePay: true }));
          }}
          onTapPayCreditCardApprove={({ stars }) => {
            dispatch(purchaseStarsAction({ stars }));
          }}
        />
      ))}
      {!hasReviewed && Platform.OS === "web" && (
        <FreePurchaseItem
          onPress={() => {
            const url =
              "https://itunes.apple.com/app/id1511867825?action=write-review";
            Alert({
              // title: 'Rate us 5 stars',
              body: "If you like our product, pleas rate us 5 stars and write a positive comment❤️",
              actions: [
                {
                  text: "OK",
                  onPress: async () => {
                    setWentToReview(true);
                    const supported = await Linking.canOpenURL(url);
                    if (supported) {
                      Linking.openURL(url);
                    }
                  },
                },
              ],
              onConfirm: async () => {
                setWentToReview(true);
                const supported = await Linking.canOpenURL(url);
                if (supported) {
                  Linking.openURL(url);
                }
              },
            });
          }}
        />
      )}
      {/* {
        countryCode !== 'IN' && !hasReviewed && Platform.OS !== 'web' &&
        <FreePurchaseItem
          onPress={() => {
            Alert({
              title: 'Rate us 5 stars',
              body: 'Write a positive comment❤️, we will detect the comment then give you 10 free stars as reward',
              actions: [{
                text: 'OK', onPress: () => {
                  setWentToReview(true);
                  StoreReview.requestReview();
                }
              }],
            });
          }}
        />
      } */}
    </StyledScrollView>
  );
};
export default PurchaseScreen;

const StyledScrollView = styled(ScrollView).attrs({
  flex: 1,
  backgroundColor: Colors.primary?.lightBlue,
  contentContainerStyle: {
    backgroundColor: Colors.primary?.lightBlue,
    alignItems: "center",
  },
})``;

const StyledActivityIndicator = styled(ActivityIndicator)`
  position: absolute;
  top: ${screenHeight / 2.5};
  left: 0;
  right: 0;
  z-index: 99;
`;
