import * as React from 'react';
import {
  View,
  Text,
  Image,
  Linking,
  Platform,
  TouchableOpacity,
} from 'react-native';
import styled from 'styled-components/native';
import { path } from 'ramda';
import TextTicker from 'react-native-text-ticker'

import i18n from 'i18n';

// TS = TouShih ad banner
const TSAdBanner = ({
  style = {},
  iconSource,
  title = 'Reports for Instagram',
  subtitle = 'Ins Master 🚀',
  linkUrl,
}) => {
  return (
    <Container style={style} onPress={async () => {
      const supported = Linking.canOpenURL(linkUrl);
      if (Platform.OS === 'web') {
        window.open(linkUrl, '_blank');
        return;
      }
      if (supported) {
        Linking.openURL(linkUrl);
      }
    }}>
      <IconImage
        source={iconSource}
      />
      <TextArea
        style={{
          marginLeft: 12,
          width: '69%',
          maxWidth: '69%',
        }}
        title={title}
        subtitle={subtitle}
      />
      <ActionButton source={require('assets/images/download.png')} />
      <AdText>Ad Sponsored</AdText>
    </Container>
  );
};

export default TSAdBanner;

const Container = styled(TouchableOpacity)`
  width: 100%;
  height: 66;
  border-radius: 12;
  background-color: ${path(['theme', 'primary', 'lightBlue'])};
  padding-left: 12;
  padding-right: 12;
  padding-top: 19;
  padding-bottom: 19;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const IconImage = styled(Image)`
  flex-shrink: 1;
  width: 49;
  height: 49;
  background-color: #000000;
  border-radius: 10;
`;

const Title = styled(Text)`
  font-size: 14;
`;

const SubTitle = styled(TextTicker)`
  font-weight: bold;
  font-size: 14;
`;

const TextArea = ({ style = {}, title, subtitle }) => {
  return (
    <View style={{
      flexShrink: 4,
      ...style,
    }}>
      <Title numberOfLines={2} ellipsizeMode='tail'>{title}</Title>
      <SubTitle
        duration={5000}
        loop
        bounce
        repeatSpacer={50}
        marqueeDelay={1000}
      >
        {subtitle}
      </SubTitle>
    </View>
  );
};

const ActionButton = styled(Image)`
  flex-shrink: 1;
  width: 48;
  height: 48;
  border-radius: 24;
  background-color: #000000;
`;

const AdText = styled(Text)`
  color: ${path(['theme', 'primary', 'gray'])};
  position: absolute;
  top: -1;
  right: 10;
  font-size: 7;
`;

