import * as React from 'react';
import { View, Text, TouchableOpacity, Platform, TextInput } from 'react-native';
import Swal from 'sweetalert2';
import styled from 'styled-components/native';
import { path } from 'ramda';

import { handlePayByPrime } from 'apis/firebase';
import { newTapPayEvent, newPendingTapPayEvent } from 'apis/slack';
import i18n from 'i18n';

import usePollingTapPayTxResult from './usePollingTapPayTxResult';

const StyledTouchableOpacity = styled(TouchableOpacity)`
  width: 100%;
  height: 45;
  background-color: gray;
  border-radius: 2;
  margin-bottom: 15;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled(Text)`
  font-size: 16;
  font-weight: 500;
  color: white;
`;

const Wrapper = styled(View)`
  background: white;
  padding: ${props => (props.isOpenFrame ? '15px' : '0')};
  margin-bottom: ${props => (props.isOpenFrame ? '15px' : '0')};
`;

const ConfirmBtnWrapper = styled(TouchableOpacity)`
  border-radius: 8;
  background-color: ${props => path(['theme', 'primary', 'purple'])};
  justify-content: center;
  align-items: center;
  padding-vertical: 10px;
  margin-top: 15px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`;

const ConfirmBtnText = styled(Text)`
  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
`;

const TapPayCardViewWrapper = styled(View)`
  display: ${props => (props.isOpenFrame ? 'block' : 'none')};
  height: 36;
  border-width: 0.5;
  border-color: #ccc;
  border-radius: 5;
  padding-left: 4;
  padding-right: 4;
  padding-top: 2;
  padding-bottom: 2;
`;

const EmailInput = styled(TextInput)`
  height: 36;
  border-width: ${props => (props.invalidEmail ? 2 : 0.5)};
  border-color: ${props => (props.invalidEmail ? '#ff0000' : '#ccc')};
  border-radius: 5;
  padding-left: 7;
  padding-right: 4;
  padding-top: 2;
  padding-bottom: 2;
  margin-bottom: 10;
`;

const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const WithTapPayCreditCardButton = ({
  styled,
  product,
  user,
  onProcess,
  onFailed,
  onSuccess,
  onPending,
}) => {
  const [isOpenFrame, setIsOpenFrame] = React.useState(false);
  const [canGetPrime, setCanGetPrime] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const handlePress = () => {
    const defaultCardViewStyle = {
      color: 'rgb(0,0,0)',
      fontSize: '15px',
      lineHeight: '24px',
      fontWeight: '300',
      errorColor: 'red',
      placeholderColor: '',
    };
    // 預設不戴第三個參數的話, 是會必須填入 CCV
    if (!isOpenFrame) {
      window.TPDirect.card.setup('#tappay-cardview-container', defaultCardViewStyle);
      setIsOpenFrame(true);
    }
  };

  React.useEffect(() => {
    window.TPDirect.card.onUpdate(function (update) {
      if (update.canGetPrime) {
        setCanGetPrime(true);
      } else {
        setCanGetPrime(false);
      }
    });
  }, []);

  const { pollingTxResult } = usePollingTapPayTxResult();

  const onPressContinue = () => {
    if (typeof onProcess === 'function') {
      onProcess();
    }
    if (!validateEmail(email)) {
      setInvalidEmail(true);
      onFailed();
      return;
    }
    setInvalidEmail(false);
    window.TPDirect.card.getPrime(async function (result) {
      const price = product.price.replace(/[^0-9.-]+/g, '');
      const priceNumber = Number.parseFloat(price);
      const payload = {
        getPrimeResult: {
          prime: result.card.prime,
          total_amount: Number.parseFloat(priceNumber * 30).toFixed(0),
        },
        product,
        buyer: {
          uid: user?.uid,
          countryCode: user?.countryCode,
          timezone: user?.timezone,
          tiktok: user?.tiktok,
          email,
        },
        isDev: __DEV__,
      };
      if (user?.tiktok?.uniqueId === 'parole_riche') {
        return;
      }

      try {
        const win = window.open('');
        const result = await handlePayByPrime(payload);
        if (result.status === 0) {
          console.log('receive result', result.payment_url);
          win.location = result.payment_url;
          const tid = setInterval(() => {
            if (win?.closed) {
              console.log('window closed');
              clearInterval(tid);
              pollingTxResult({
                recTradeId: result.rec_trade_id,
                onSuccess: res => {
                  onSuccess({
                    stars: product.stars,
                    result: {
                      ...result,
                      email,
                    },
                  });
                  newTapPayEvent({
                    type: 'onSuccess',
                    message: 'onSuccess',
                    ...payload?.product,
                    ...payload?.buyer,
                    platform: Platform.OS,
                  });
                },
                onPending: res => {
                  newPendingTapPayEvent({
                    type: 'onPending',
                    message: 'onPending',
                    stars: product.stars,
                    ...payload?.product,
                    ...payload?.buyer,
                    platform: Platform.OS,
                    result: {
                      ...res,
                      email,
                    },
                  });
                  onPending(res);
                },
              });
            }
          }, 1000);
        } else {
          // failed
          newTapPayEvent({
            type: 'onFailed',
            message: result.status,
            ...payload?.product,
            ...payload?.buyer,
            platform: Platform.OS,
          });
          throw new Error(JSON.stringify(result));
        }
      } catch (e) {
        if (__DEV__) {
          console.log('payByTapPayCreditCard failed', e);
        }
        Swal.fire({
          title: 'Oops...Purchase Failed',
          text: 'Your payment failed. please try again!',
          icon: 'error',
          width: '85%',
        });
        onFailed();
        newTapPayEvent({
          type: 'onError',
          message: String(e),
          ...payload?.product,
          ...payload?.buyer,
          platform: Platform.OS,
        });
      }
    });
  };

  return (
    <View styled={styled}>
      <StyledTouchableOpacity onPress={handlePress}>
        <StyledText>{i18n.t('purchase_credit_card')}</StyledText>
      </StyledTouchableOpacity>
      <Wrapper isOpenFrame={isOpenFrame}>
        {isOpenFrame && (
          <EmailInput
            textContentType="emailAddress"
            keyboardType="email-address"
            autoCompleteType="email"
            invalidEmail={invalidEmail}
            onChangeText={setEmail}
            value={email}
            placeholder="Email"
          />
        )}
        <TapPayCardViewWrapper isOpenFrame={isOpenFrame}>
          <View nativeID="tappay-cardview-container" />
        </TapPayCardViewWrapper>
        {isOpenFrame && (
          <ConfirmBtnWrapper onPress={onPressContinue} disabled={!canGetPrime}>
            <ConfirmBtnText>{i18n.t('general_continue')}</ConfirmBtnText>
          </ConfirmBtnWrapper>
        )}
      </Wrapper>
    </View>
  );
};

export default WithTapPayCreditCardButton;
