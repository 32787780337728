import * as React from 'react';
import { View, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

const SegmentedControl = ({
  selectedTab,
  setSelectedTab,
  values,
  containerStyle = {}
}) => {
  return (
    <Container>
      {
        values.map((value, idx) => (
          <Tab
            isSelected={selectedTab === idx}
            onPress={() => setSelectedTab(idx)}
            key={idx}
            isLast={idx === values.length - 1}
          >
            {value()}
          </Tab>
        ))
      }
    </Container>
  );
};

export default SegmentedControl;

const Container = styled(View)`
  width: 100%;
  max-width: 358;
  height: 49;
  margin-top: 12;
  border-radius: 12;
  border-color: #FFFFFF;
  border-width: 5;
  /* background-color: #000000; */
  justify-content: space-evenly;
  flex-direction: row;
`;

const Tab = styled(TouchableOpacity)`
  border-right-color: #FFFFFF;
  border-right-width: ${props => props.isLast ? 0 : 5};
  background-color: ${props => props.isSelected ? '#FFFFFF' : 'transparent'};
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
