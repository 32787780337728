// @format
import Constants from 'expo-constants';
import { path } from 'ramda';
import { View, Platform } from 'react-native';
import styled from 'styled-components/native';

const Header = styled(View)`
  width: 100%;
  max-width: 500;
  height: ${
    Platform.select({
      android: 56,
      default: 44,
    }) + Constants.statusBarHeight
  };
  background-color: #FFFFFF;
  flex-direction: row;
  align-items: center;
  padding-left: 15;
  padding-right: 15;
  padding-top: ${Constants.statusBarHeight};
  background-color: #FFFFFF;
  /* background-color: ${path(['theme', 'primary', 'lightBlue'])} */
`;

export default Header;
