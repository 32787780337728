// @format
import { Platform } from 'react-native';
import { omit } from 'lodash';
import ct from 'countries-and-timezones';

export const PRODUCTS_IDS = {
  PACKAGE_10_WEEKLY: 'com.tikfans.www.10dollars.weekly',
  PACKAGE_2: 'com.tikfans.www.2dollars',
  PACKAGE_3: 'com.tikfans.www.3dollars',
  PACKAGE_5: 'com.tikfans.www.5dollars',
  PACKAGE_10: 'com.tikfans.www.10dollars',
  PACKAGE_20: 'com.tikfans.www.20dollars',
  PACKAGE_25: 'com.tikfans.www.25dollars',
  PACKAGE_50: 'com.tikfans.www.50dollars',
  PACKAGE_80: 'com.tikfans.www.80dollars',
  PACKAGE_100: 'com.tikfans.www.100dollars',
};

const productsLocalPrices = {
  [PRODUCTS_IDS.PACKAGE_2]: {
    TW: 'NT$63.00',
    KZ: '₸790-00',
    KH: '2៛',
    NG: '₦790.00',
    JP: '¥220',
    LB: 'ل.ل.‏ 3,000.00',
    LV: '2.19',
    AE: 'د.إ.‏ 7.79',
    HK: 'HK$15.00',
    CO: '$ 7.500,00',
    RS: '249,00 Дин.',
    AU: '$3.29',
    PE: 'S/. 6.99',
    NZ: '$3.59',
    IT: '2,19 €',
    BM: '$1.99',
    DE: '2,09 €',
    DK: '17,00 kr.',
    KW: 'د.ك.‏ 1.990',
    BE: '2,19 €',
    IQ: 'د.ع.‏ 2,400.00',
    SK: '2,19 €',
    SI: '2,19 €',
    AT: '2,19 €',
    PL: '9,89 zł',
    RU: '169,00 ₽',
    GE: '6,00 Lari',
    DZ: 'د.ج.‏ 250.00',
    KY: '$1.99',
    JO: 'د.ا.‏ 1.450',
    ZA: 'R39,99',
    LI: 'CHF 1.90',
    SE: '24,00 kr',
    FR: '2,19 €',
    MM: 'K3,000.00',
    MO: 'MOP$15.99',
    FI: '2,19 €',
    ID: 'Rp29.000',
    QA: '﷼ 7.50',
    TH: '฿63.00',
    CZ: '59,99 Kč',
    IN: '₹180.00',
    CA: '$2.79',
    BR: 'R$ 10,99',
    PT: '2,19 €',
    RO: '9,99 L',
    MA: 'د.م.‏ 19.99',
    LT: '2.19',
    EE: '2,19 €',
    VG: '$1.99',
    HU: '799,00 Ft',
    KE: 'KSh200.00',
    HR: '17,00 kn',
    LU: '2,09 €',
    SA: '﷼ 8.79',
    MY: 'RM8.99',
    PY: '₲ 10.000,00',
    IE: '2,19 €',
    NL: '2,19 €',
    BH: 'د.ب.‏ 1.990',
    NO: 'kr 24,00',
    UA: '54,99₴',
    BO: 'Bs 13,99',
    IL: '₪ 7.00',
    US: '$1.99',
    CL: '$ 1.700,00',
    GB: '£1.99',
    CY: '2,19 €',
    OM: '﷼ 1.990',
    ES: '2,19 €',
    KR: '₩2,700',
    GR: '2,19 €',
    CH: 'CHF 1.90',
    LK: '₨ 375',
    GH: '₵10.00',
    SG: '$2.99',
    EG: 'ج.م.‏ 32.99',
    TC: '$1.99',
    TR: '₺15,99',
    BD: '৳ 190',
    PK: '₨340.00',
    PH: '₱100.00',
    BG: '4,29 лв.',
    FM: '$1.99',
    TZ: 'TSh4,700.00',
    VN: '47.000 ₫',
    CR: '₡1.200,00',
    MX: '$47.00',
  },
  [PRODUCTS_IDS.PACKAGE_5]: {
    TW: 'NT$160.00',
    DZ: 'د.ج.‏ 650.00',
    AU: '$7.99',
    AT: '5,49 €',
    BH: 'د.ب.‏ 5.490',
    BD: '৳ 420',
    BE: '5,49 €',
    BM: '$4.99',
    BO: 'Bs 34,99',
    BR: 'R$ 27,99',
    VG: '$4.99',
    BG: '10,99 лв.',
    KH: '5៛',
    CA: '$6.99',
    KY: '$4.99',
    CL: '$ 4.200,00',
    CO: '$ 19.000,00',
    CR: '₡2.900,00',
    HR: '43,00 kn',
    CY: '5,49 €',
    CZ: '149,99 Kč',
    DK: '42,00 kr.',
    EG: 'ج.م.‏ 81.99',
    EE: '5,49 €',
    FI: '5,49 €',
    FR: '5,49 €',
    GE: '14,00 Lari',
    DE: '5,49 €',
    GH: '₵30.00',
    GR: '5,49 €',
    HK: 'HK$39.90',
    HU: '1 999,00 Ft',
    IN: '₹450.00',
    ID: 'Rp72.000',
    IQ: 'د.ع.‏ 6,050.00',
    IE: '5,49 €',
    IL: '₪ 17.90',
    IT: '5,49 €',
    JP: '¥550',
    JO: 'د.ا.‏ 3.600',
    KZ: '₸2 090-00',
    KE: 'KSh500.00',
    KW: 'د.ك.‏ 4.990',
    LV: '5.49',
    LB: 'ل.ل.‏ 7,750.00',
    LI: 'CHF 4.80',
    LT: '5.49',
    LU: '5,49 €',
    MO: 'MOP$40.88',
    MY: 'RM22.99',
    MX: '$115.00',
    FM: '$4.99',
    MA: 'د.م.‏ 48.99',
    MM: 'K7,000.00',
    NL: '5,49 €',
    NZ: '$8.99',
    NG: '₦1,970.00',
    NO: 'kr 59,00',
    OM: '﷼ 4.990',
    PK: '₨850.00',
    PY: '₲ 30.000,00',
    PE: 'S/. 17.99',
    PH: '₱255.00',
    PL: '24,99 zł',
    PT: '5,49 €',
    QA: '﷼ 19.00',
    RO: '25,99 L',
    RU: '429,00 ₽',
    SA: '﷼ 21.99',
    RS: '649,00 Дин.',
    SG: '$7.49',
    SK: '5,49 €',
    SI: '5,49 €',
    ZA: 'R99,99',
    KR: '₩6,900',
    ES: '5,49 €',
    LK: '₨ 950',
    SE: '59,00 kr',
    CH: 'CHF 4.80',
    TZ: 'TSh12,000.00',
    TH: '฿150.00',
    TR: '₺40,99',
    TC: '$4.99',
    UA: '134,99₴',
    AE: 'د.إ.‏ 19.99',
    GB: '£4.89',
    US: '$4.99',
    VN: '118.000 ₫'
  },
  [PRODUCTS_IDS.PACKAGE_10]: {
    TW: 'NT$310.00',
    DZ: 'د.ج.‏ 1,300.00',
    AU: '$15.99',
    AT: '10,99 €',
    BH: 'د.ب.‏ 10.990',
    BD: '৳ 850',
    BE: '10,99 €',
    BM: '$9.99',
    BO: 'Bs 69,99',
    BR: 'R$ 54,99',
    VG: '$9.99',
    BG: '20,99 лв.',
    KH: '10៛',
    CA: '$13.99',
    KY: '$9.99',
    CL: '$ 8.300,00',
    CO: '$ 38.000,00',
    CR: '₡5.900,00',
    HR: '85,00 kn',
    CY: '10,99 €',
    CZ: '289,99 Kč',
    DK: '85,00 kr.',
    EG: 'ج.م.‏ 159.99',
    EE: '10,99 €',
    FI: '10,99 €',
    FR: '10,99 €',
    GE: '29,00 Lari',
    DE: '10,99 €',
    GH: '₵60.00',
    GR: '10,99 €',
    HK: 'HK$78.00',
    HU: '4 090,00 Ft',
    IN: '₹900.00',
    ID: 'Rp149.000',
    IQ: 'د.ع.‏ 12,100.00',
    IE: '10,99 €',
    IL: '₪ 35.00',
    IT: '10,99 €',
    JP: '¥1,090',
    JO: 'د.ا.‏ 7.200',
    KZ: '₸4 090-00',
    KE: 'KSh1,100.00',
    KW: 'د.ك.‏ 9.990',
    LV: '10.99',
    LB: 'ل.ل.‏ 15,250.00',
    LI: 'CHF 9.60',
    LT: '10.99',
    LU: '10,99 €',
    MO: 'MOP$80.99',
    MY: 'RM45.99',
    MX: '$235.00',
    FM: '$9.99',
    MA: 'د.م.‏ 98.99',
    MM: 'K14,000.00',
    NL: '10,99 €',
    NZ: '$17.99',
    NG: '₦3,930.00',
    NO: 'kr 119,00',
    OM: '﷼ 9.990',
    PK: '₨1,700.00',
    PY: '₲ 70.000,00',
    PE: 'S/. 35.99',
    PH: '₱505.00',
    PL: '49,99 zł',
    PT: '10,99 €',
    QA: '﷼ 37.00',
    RO: '49,99 L',
    RU: '890,00 ₽',
    SA: '﷼ 43.99',
    RS: '1.299,00 Дин.',
    SG: '$14.99',
    SK: '10,99 €',
    SI: '10,99 €',
    ZA: 'R199,99',
    KR: '₩13,000',
    ES: '10,99 €',
    LK: '₨ 1,900',
    SE: '119,00 kr',
    CH: 'CHF 9.60',
    TZ: 'TSh24,000.00',
    TH: '฿325.00',
    TR: '₺81,99',
    TC: '$9.99',
    UA: '269,99₴',
    AE: 'د.إ.‏ 38.99',
    GB: '£9.99',
    US: '$9.99',
    VN: '236.000 ₫'
  },
  [PRODUCTS_IDS.PACKAGE_20]: {
    TW: 'NT$630.00',
    DZ: 'د.ج.‏ 2,625.00',
    AU: '$31.99',
    AT: '21,99 €',
    BH: 'د.ب.‏ 20.990',
    BD: '৳ 1,700',
    BE: '21,99 €',
    BM: '$19.99',
    BO: 'Bs 139,99',
    BR: 'R$ 109,99',
    VG: '$19.99',
    BG: '42,99 лв.',
    KH: '20៛',
    CA: '$27.99',
    KY: '$19.99',
    CL: '$ 16.700,00',
    CO: '$ 76.000,00',
    CR: '₡11.800,00',
    HR: '169,00 kn',
    CY: '21,99 €',
    CZ: '599,99 Kč',
    DK: '169,00 kr.',
    EG: 'ج.م.‏ 329.99',
    EE: '21,99 €',
    FI: '21,99 €',
    FR: '21,99 €',
    GE: '59,00 Lari',
    DE: '20,99 €',
    GH: '₵120.00',
    GR: '21,99 €',
    HK: 'HK$158.00',
    HU: '8 190,00 Ft',
    IN: '₹1,800.00',
    ID: 'Rp289.000',
    IQ: 'د.ع.‏ 24,150.00',
    IE: '21,99 €',
    IL: '₪ 70.00',
    IT: '21,99 €',
    JP: '¥2,180',
    JO: 'د.ا.‏ 14.400',
    KZ: '₸8 290-00',
    KE: 'KSh2,200.00',
    KW: 'د.ك.‏ 19.990',
    LV: '21.99',
    LB: 'ل.ل.‏ 30,750.00',
    LI: 'CHF 19.00',
    LT: '21.99',
    LU: '20,99 €',
    MO: 'MOP$159.00',
    MY: 'RM91.99',
    MX: '$465.00',
    FM: '$19.99',
    MA: 'د.م.‏ 194.99',
    MM: 'K28,000.00',
    NL: '21,99 €',
    NZ: '$35.99',
    NG: '₦7,860.00',
    NO: 'kr 245,00',
    OM: '﷼ 19.990',
    PK: '₨3,400.00',
    PY: '₲ 150.000,00',
    PE: 'S/. 71.99',
    PH: '₱1,000.00',
    PL: '98,99 zł',
    PT: '21,99 €',
    QA: '﷼ 74.00',
    RO: '104,99 L',
    RU: '1 699,00 ₽',
    SA: '﷼ 87.99',
    RS: '2.599,00 Дин.',
    SG: '$29.99',
    SK: '21,99 €',
    SI: '21,99 €',
    ZA: 'R404,99',
    KR: '₩27,000',
    ES: '21,99 €',
    LK: '₨ 3,775',
    SE: '239,00 kr',
    CH: 'CHF 19.00',
    TZ: 'TSh47,000.00',
    TH: '฿625.00',
    TR: '₺164,99',
    TC: '$19.99',
    UA: '539,99₴',
    AE: 'د.إ.‏ 77.99',
    GB: '£19.49',
    US: '$19.99',
    VN: '471.000 ₫'
  },
  [PRODUCTS_IDS.PACKAGE_25]: {
    TW: 'NT$790.00',
    DZ: 'د.ج.‏ 3,275.00',
    AU: '$40.99',
    AT: '26,99 €',
    BH: 'د.ب.‏ 26.990',
    BD: '৳ 2,200',
    BE: '26,99 €',
    BM: '$24.99',
    BO: 'Bs 174,99',
    BR: 'R$ 139,99',
    VG: '$24.99',
    BG: '52,99 лв.',
    KH: '25៛',
    CA: '$33.99',
    KY: '$24.99',
    CL: '$ 20.800,00',
    CO: '$ 95.000,00',
    CR: '₡14.700,00',
    HR: '215,00 kn',
    CY: '26,99 €',
    CZ: '749,99 Kč',
    DK: '209,00 kr.',
    EG: 'ج.م.‏ 409.99',
    EE: '26,99 €',
    FI: '27,99 €',
    FR: '26,99 €',
    GE: '79,00 Lari',
    DE: '25,99 €',
    GH: '₵150.00',
    GR: '27,99 €',
    HK: 'HK$198.00',
    HU: '9 999,00 Ft',
    IN: '₹2,250.00',
    ID: 'Rp359.000',
    IQ: 'د.ع.‏ 30,200.00',
    IE: '27,99 €',
    IL: '₪ 87.90',
    IT: '27,99 €',
    JP: '¥2,720',
    JO: 'د.ا.‏ 18.000',
    KZ: '₸9 990-00',
    KE: 'KSh2,700.00',
    KW: 'د.ك.‏ 24.990',
    LV: '26.99',
    LB: 'ل.ل.‏ 38,250.00',
    LI: 'CHF 24.00',
    LT: '26.99',
    LU: '26,99 €',
    MO: 'MOP$199.00',
    MY: 'RM114.99',
    MX: '$579.00',
    FM: '$24.99',
    MA: 'د.م.‏ 244.99',
    MM: 'K35,000.00',
    NL: '26,99 €',
    NZ: '$44.99',
    NG: '₦9,830.00',
    NO: 'kr 305,00',
    OM: '﷼ 24.990',
    PK: '₨4,250.00',
    PY: '₲ 175.000,00',
    PE: 'S/. 89.99',
    PH: '₱1,250.00',
    PL: '124,99 zł',
    PT: '27,99 €',
    QA: '﷼ 93.00',
    RO: '129,99 L',
    RU: '2 190,00 ₽',
    SA: '﷼ 109.99',
    RS: '3.199,00 Дин.',
    SG: '$37.98',
    SK: '26,99 €',
    SI: '27,99 €',
    ZA: 'R499,99',
    KR: '₩34,000',
    ES: '26,99 €',
    LK: '₨ 4,725',
    SE: '295,00 kr',
    CH: 'CHF 24.00',
    TZ: 'TSh59,000.00',
    TH: '฿775.00',
    TR: '₺204,99',
    TC: '$24.99',
    UA: '679,99₴',
    AE: 'د.إ.‏ 97.99',
    GB: '£24.49',
    US: '$24.99',
    VN: '589.000 ₫'
  },
  [PRODUCTS_IDS.PACKAGE_50]: {
    TW: 'NT$1,570.00',
    DZ: 'د.ج.‏ 6,550.00',
    AU: '$80.99',
    AT: '54,99 €',
    BH: 'د.ب.‏ 54.990',
    BD: '৳ 4,300',
    BE: '54,99 €',
    BM: '$49.99',
    BO: 'Bs 349,99',
    BR: 'R$ 274,99',
    VG: '$49.99',
    BG: '104,99 лв.',
    KH: '50៛',
    CA: '$68.99',
    KY: '$49.99',
    CL: '$ 41.700,00',
    CO: '$ 191.000,00',
    CR: '₡29.500,00',
    HR: '429,00 kn',
    CY: '54,99 €',
    CZ: '1499,99 Kč',
    DK: '425,00 kr.',
    EG: 'ج.م.‏ 819.99',
    EE: '54,99 €',
    FI: '54,99 €',
    FR: '54,99 €',
    GE: '149,00 Lari',
    DE: '54,99 €',
    GH: '₵290.00',
    GR: '54,99 €',
    HK: 'HK$389.00',
    HU: '20 499,00 Ft',
    IN: '₹4,550.00',
    ID: 'Rp690.000',
    IQ: 'د.ع.‏ 60,400.00',
    IE: '54,99 €',
    IL: '₪ 175.00',
    IT: '54,99 €',
    JP: '¥5,500',
    JO: 'د.ا.‏ 36.000',
    KZ: '₸20 990-00',
    KE: 'KSh5,400.00',
    KW: 'د.ك.‏ 49.990',
    LV: '54.99',
    LB: 'ل.ل.‏ 76,500.00',
    LI: 'CHF 48.00',
    LT: '54.99',
    LU: '54,99 €',
    MO: 'MOP$408.00',
    MY: 'RM229.99',
    MX: '$1,149.00',
    FM: '$49.99',
    MA: 'د.م.‏ 494.99',
    MM: 'K69,000.00',
    NL: '54,99 €',
    NZ: '$89.99',
    NG: '₦19,500.00',
    NO: 'kr 609,00',
    OM: '﷼ 49.990',
    PK: '₨8,500.00',
    PY: '₲ 350.000,00',
    PE: 'S/. 179.99',
    PH: '₱2,550.00',
    PL: '249,99 zł',
    PT: '54,99 €',
    QA: '﷼ 185.00',
    RO: '259,99 L',
    RU: '4 299,00 ₽',
    SA: '﷼ 219.99',
    RS: '6.499,00 Дин.',
    SG: '$75.98',
    SK: '54,99 €',
    SI: '54,99 €',
    ZA: 'R999,99',
    KR: '₩69,000',
    ES: '54,99 €',
    LK: '₨ 9,450',
    SE: '589,00 kr',
    CH: 'CHF 48.00',
    TZ: 'TSh118,000.00',
    TH: '฿1,575.00',
    TR: '₺409,99',
    TC: '$49.99',
    UA: '1 349,99₴',
    AE: 'د.إ.‏ 194.99',
    GB: '£48.99',
    US: '$49.99',
    VN: '1.200.000 ₫'
  },
  [PRODUCTS_IDS.PACKAGE_80]: {
    TW: 'NT$2,520.00',
    DZ: 'د.ج.‏ 10,500.00',
    AU: '$129.99',
    AT: '84,99 €',
    BH: 'د.ب.‏ 84.990',
    BD: '৳ 6,900',
    BE: '89,99 €',
    BM: '$79.99',
    BO: 'Bs 559,99',
    BR: 'R$ 444,99',
    VG: '$79.99',
    BG: '169,99 лв.',
    KH: '80៛',
    CA: '$109.99',
    KY: '$79.99',
    CL: '$ 67.000,00',
    CO: '$ 305.000,00',
    CR: '₡47.100,00',
    HR: '689,00 kn',
    CY: '84,99 €',
    CZ: '2299,99 Kč',
    DK: '679,00 kr.',
    EG: 'ج.م.‏ 1,299.99',
    EE: '84,99 €',
    FI: '89,99 €',
    FR: '84,99 €',
    GE: '249,00 Lari',
    DE: '84,99 €',
    GH: '₵470.00',
    GR: '89,99 €',
    HK: 'HK$629.00',
    HU: '32 499,00 Ft',
    IN: '₹7,200.00',
    ID: 'Rp1.159.921',
    IQ: 'د.ع.‏ 96,600.00',
    IE: '89,99 €',
    IL: '₪ 275.00',
    IT: '89,99 €',
    JP: '¥8,700',
    JO: 'د.ا.‏ 57.500',
    KZ: '₸32 990-00',
    KE: 'KSh8,600.00',
    KW: 'د.ك.‏ 79.990',
    LV: '89.99',
    LB: 'ل.ل.‏ 122,500.00',
    LI: 'CHF 75.00',
    LT: '89.99',
    LU: '84,99 €',
    MO: 'MOP$648.00',
    MY: 'RM369.99',
    MX: '$1,849.00',
    FM: '$79.99',
    MA: 'د.م.‏ 789.99',
    MM: 'K110,000.00',
    NL: '89,99 €',
    NZ: '$144.99',
    NG: '₦31,500.00',
    NO: 'kr 979,00',
    OM: '﷼ 79.990',
    PK: '₨13,600.00',
    PY: '₲ 550.000,00',
    PE: 'S/. 289.99',
    PH: '₱4,050.00',
    PL: '394,99 zł',
    PT: '89,99 €',
    QA: '﷼ 300.00',
    RO: '414,99 L',
    RU: '6 899,00 ₽',
    SA: '﷼ 349.99',
    RS: '9.999,00 Дин.',
    SG: '$119.99',
    SK: '84,99 €',
    SI: '89,99 €',
    ZA: 'R1 599,99',
    KR: '₩110,000',
    ES: '89,99 €',
    LK: '₨ 15,125',
    SE: '949,00 kr',
    CH: 'CHF 75.00',
    TZ: 'TSh188,000.00',
    TH: '฿2,525.00',
    TR: '₺659,99',
    TC: '$79.99',
    UA: '2 149,99₴',
    AE: 'د.إ.‏ 314.99',
    GB: '£78.99',
    US: '$79.99',
    VN: '1.900.000 ₫'
  },
  [PRODUCTS_IDS.PACKAGE_100]: {
    TW: 'NT$3,150.00',
    DZ: 'د.ج.‏ 13,125.00',
    AU: '$159.99',
    AT: '109,99 €',
    BH: 'د.ب.‏ 109.990',
    BD: '৳ 8,600',
    BE: '109,99 €',
    BM: '$99.99',
    BO: 'Bs 699,99',
    BR: 'R$ 549,99',
    VG: '$99.99',
    BG: '214,99 лв.',
    KH: '100៛',
    CA: '$139.99',
    KY: '$99.99',
    CL: '$ 83.000,00',
    CO: '$ 382.000,00',
    CR: '₡59.000,00',
    HR: '859,00 kn',
    CY: '109,99 €',
    CZ: '2899,99 Kč',
    DK: '849,00 kr.',
    EG: 'ج.م.‏ 1,649.99',
    EE: '109,99 €',
    FI: '109,99 €',
    FR: '109,99 €',
    GE: '299,00 Lari',
    DE: '104,99 €',
    GH: '₵590.00',
    GR: '109,99 €',
    HK: 'HK$788.00',
    HU: '40 999,00 Ft',
    IN: '₹9,100.00',
    ID: 'Rp1.449.902',
    IQ: 'د.ع.‏ 121,000.00',
    IE: '109,99 €',
    IL: '₪ 349.90',
    IT: '109,99 €',
    JP: '¥10,900',
    JO: 'د.ا.‏ 72.000',
    KZ: '₸40 990-00',
    KE: 'KSh11,000.00',
    KW: 'د.ك.‏ 99.990',
    LV: '109.99',
    LB: 'ل.ل.‏ 153,250.00',
    LI: 'CHF 95.00',
    LT: '109.99',
    LU: '104,99 €',
    MO: 'MOP$809.00',
    MY: 'RM459.99',
    MX: '$2,349.00',
    FM: '$99.99',
    MA: 'د.م.‏ 989.99',
    MM: 'K140,000.00',
    NL: '109,99 €',
    NZ: '$179.99',
    NG: '₦39,500.00',
    NO: 'kr 1 199,00',
    OM: '﷼ 99.990',
    PK: '₨17,000.00',
    PY: '₲ 700.000,00',
    PE: 'S/. 359.99',
    PH: '₱5,050.00',
    PL: '494,99 zł',
    PT: '109,99 €',
    QA: '﷼ 370.00',
    RO: '499,99 L',
    RU: '8 690,00 ₽',
    SA: '﷼ 439.99',
    RS: '12.999,00 Дин.',
    SG: '$149.99',
    SK: '109,99 €',
    SI: '109,99 €',
    ZA: 'R1 999,99',
    KR: '₩130,000',
    ES: '109,99 €',
    LK: '₨ 19,000',
    SE: '1.199,00 kr',
    CH: 'CHF 95.00',
    TZ: 'TSh235,000.00',
    TH: '฿3,150.00',
    TR: '₺819,99',
    TC: '$99.99',
    UA: '2 699,99₴',
    AE: 'د.إ.‏ 389.99',
    GB: '£97.99',
    US: '$99.99',
    VN: '2.350.000 ₫'
  },
};

const getProductLocalPrice = (productId) => {
  if (Platform.OS === 'web') {
    const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'America/Los_Angeles';
    const country = ct.getTimezone(timezone)?.country || 'US';
    const localPrice = productsLocalPrices[productId]?.[country];
    if (localPrice) {
      return localPrice;
    }
  }
  return undefined;
};



export const IAP_PRODUCTS = {
  [PRODUCTS_IDS.PACKAGE_2]: {
    stars: 50,
    productId: PRODUCTS_IDS.PACKAGE_2,
    discount: '0',
    price: '$1.99',
    localPrice: getProductLocalPrice(PRODUCTS_IDS.PACKAGE_2),
  },
  // [PRODUCTS_IDS.PACKAGE_3]: {
  //   stars: 75,
  //   productId: 'com.tikfans.www.3dollars',
  //   discount: '0',
  //   price: '$2.99',
  // },
  [PRODUCTS_IDS.PACKAGE_5]: {
    stars: 150,
    productId: PRODUCTS_IDS.PACKAGE_5,
    discount: '17',
    price: '$4.99',
    localPrice: getProductLocalPrice(PRODUCTS_IDS.PACKAGE_5)
  },
  ...(Platform.OS !== 'web') && {
    [PRODUCTS_IDS.PACKAGE_10_WEEKLY]: {
      stars: 700,
      productId: PRODUCTS_IDS.PACKAGE_10_WEEKLY,
      discount: '60',
      price: '$9.99',
      isSubscription: true,
      period: 7,
    }
  },
  [PRODUCTS_IDS.PACKAGE_10]: {
    stars: 350,
    productId: PRODUCTS_IDS.PACKAGE_10,
    discount: '29',
    price: '$9.99',
    localPrice: getProductLocalPrice(PRODUCTS_IDS.PACKAGE_10)
  },
  [PRODUCTS_IDS.PACKAGE_20]: {
    stars: 750,
    productId: PRODUCTS_IDS.PACKAGE_20,
    discount: '33',
    price: '$19.99',
    promotion: true,
    localPrice: getProductLocalPrice(PRODUCTS_IDS.PACKAGE_20)
  },
  [PRODUCTS_IDS.PACKAGE_25]: {
    stars: 1000,
    productId: PRODUCTS_IDS.PACKAGE_25,
    discount: '36',
    price: '$24.99',
    localPrice: getProductLocalPrice(PRODUCTS_IDS.PACKAGE_25)
  },
  [PRODUCTS_IDS.PACKAGE_50]: {
    stars: 2000,
    productId: PRODUCTS_IDS.PACKAGE_50,
    discount: '38',
    price: '$48.99',
    localPrice: getProductLocalPrice(PRODUCTS_IDS.PACKAGE_50)
  },
  // [PRODUCTS_IDS.PACKAGE_80]: {
  //   stars: 4000,
  //   productId: PRODUCTS_IDS.PACKAGE_80,
  //   discount: '40',
  //   price: '$79.99',
  //   localPrice: getProductLocalPrice(PRODUCTS_IDS.PACKAGE_80)
  // },
  // [PRODUCTS_IDS.PACKAGE_100]: {
  //   stars: 6000,
  //   productId: PRODUCTS_IDS.PACKAGE_100,
  //   discount: '60',
  //   price: '$99.99',
  //   localPrice: getProductLocalPrice(PRODUCTS_IDS.PACKAGE_100)
  // },
};

export const getProductList = ({ purchaseHistory }) => {
  const purchasedProductIds = purchaseHistory
    .filter(purchase => !!IAP_PRODUCTS[purchase?.productId]?.isSubscription)
    .map(purchase => purchase?.productId);
  const productsWithoutPurchased = omit(IAP_PRODUCTS, purchasedProductIds);
  return Object.values(productsWithoutPurchased);
};


