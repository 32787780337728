import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { compose, path } from 'ramda';
import queryString from 'query-string';
import { isExist } from 'utils/ramdaUtils';

const useListenFromTapPay = () => {
  const isLogin = useSelector(compose(isExist, path(['user', 'tiktok', 'uniqueId'])));
  const uid = useSelector(path(['user', 'uid']));
  const { bank_transaction_id: bankTxId, rec_trade_id: recTradeId } = queryString.parse(
    window.location.search,
  );

  useEffect(() => {
    if ((bankTxId || recTradeId) && isLogin && uid) {
      window.close();
    }
  }, [bankTxId, recTradeId, isLogin, uid]);
};

export default useListenFromTapPay;
