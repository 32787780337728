// @format
import PropTypes from 'prop-types';
import React from 'react';
import { Image, TouchableOpacity } from 'react-native';

const CloseButton = ({ onPress }) => (
  <TouchableOpacity onPress={onPress}>
    <Image style={{ width: 18, height: 18 }} source={require('assets/images/close.png')} />
  </TouchableOpacity>
);

CloseButton.propTypes = {
  onPress: PropTypes.func,
};

export default CloseButton;
