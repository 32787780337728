import * as React from 'react';
import { path } from 'ramda';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Ionicons, FontAwesome } from '@expo/vector-icons';
import {
  View,
  Image,
  Dimensions,
  TouchableOpacity,
  Text,
  Linking,
  ActivityIndicator,
  RefreshControl,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import styled from 'styled-components/native';

import useAppState from 'hooks/useAppState';
import apis from 'apis';
import {
  getUserProfileInfoAction,
  checkFollowStatusAction,
  updateShowSuspiciousAlert,
  pressSkipAction,
  checkIsExistLinkedAppleIdInDb,
  updateShowLoginScreenCount,
} from 'actions/userActions';
import PWAInstructionView from 'components/LoginScreen/PWAInstructionView';
// import { AdBanner, showFullScreenAd } from 'components/Ads';
// import { AdBanner, showADInterstitial } from 'components/Ads/FacebookAds';
import SelfPromotionBanner from 'components/Ads/SelfPromotionBanner';
// import TSAdBanner from 'components/Ads/TSAdBanner';
import { TSAdBanner, TSAdFullScreen, useAdModal } from 'ts-ad-modules';
import DailyReward from 'components/DailyReward';
import Alert from 'components/Alert';
import AppleLoginModal from 'screens/AppleLoginModal';
import i18n from 'i18n';
import { BANNER_LIST, FULLSCREEN_LIST } from 'constants/TsadConfig';

const showSelfPromotionBanner = false;
const bannerConfig = _.shuffle(BANNER_LIST)[0];

const HomeScreen = ({ navigation }) => {
  const dispatch = useDispatch();
  const [orders, setOrders] = React.useState([]);
  const [displayedOrderCount, setDisplayedOrderCount] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [shouldLoadMore, setShouldLoadMore] = React.useState(true);
  const [refreshing, setRefreshing] = React.useState(false);
  const [wentToTikTok, setWentToTikTok] = React.useState(false);
  const [currentVideoInfo, setCurrentVideoInfo] = React.useState({});

  const [appleLoginModalStatus, setAppleLoginModalStatus] = React.useState({ visible: false, type: 'default' });

  const authUser = useSelector(state => state.user);
  const showSuspiciousAlert = useSelector(state => state.user?.showSuspiciousAlert);
  const countryCode = authUser?.countryCode;
  const followingListObject = authUser?.followingList;

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    dispatch(getUserProfileInfoAction({ username: authUser?.tiktok?.uniqueId })).finally(() =>
      setRefreshing(false),
    );
    setShouldLoadMore(true);
  }, [refreshing]);

  React.useEffect(() => {
    if (_.isEmpty(authUser?.tiktok?.uniqueId)) {
      navigation.navigate('Login');
      return;
    }
    if (!shouldLoadMore) {
      return;
    }
    setIsLoading(true);

    apis.firebase
      .getOrders({ followingListObject })
      .then(orders => {
        const updatedOrders = orders.filter(order => order.uniqueId !== authUser?.tiktok?.uniqueId);
        setOrders(updatedOrders);
      })
      .finally(() => {
        setIsLoading(false);
        setShouldLoadMore(false);
      });
  }, [shouldLoadMore, authUser?.tiktok?.uniqueId]);

  React.useEffect(() => {
    dispatch(getUserProfileInfoAction({ username: authUser?.tiktok?.uniqueId }));
  }, [authUser.uid, authUser?.tiktok?.uniqueId]);

  React.useEffect(() => {
    if (!_.isEmpty(authUser?.appleId) || authUser?.showLoginCount >= 2 || authUser?.hasPurchased) {
      setAppleLoginModalStatus({ visible: false });
      return;
    }

    if (authUser?.showLoginCount < 1 && authUser?.stars >= 10 || authUser?.showLoginCount < 2 && authUser?.stars >= 20) {
      dispatch(updateShowLoginScreenCount());
      setAppleLoginModalStatus({ visible: true, type: 'default' });
    }
  }, [authUser?.stars, authUser?.appleId, authUser.hasPurchased]);

  React.useEffect(() => {
    const checkAppleId = async () => {
      if (authUser?.tiktok?.uniqueId) {
        dispatch(checkIsExistLinkedAppleIdInDb());
      }
    };
    if (!authUser?.appleId) checkAppleId();
  }, [authUser?.tiktok?.uniqueId]);

  // React.useEffect(() => {
  //   if (!showSuspiciousAlert) {
  //     return;
  //   }
  //   Alert({
  //     title: `🚫${i18n.t('unfollow_alert_title')}`,
  //     body: i18n.t('unfollow_alert_body'),
  //     actions: [
  //       {
  //         text: 'OK',
  //         onPress: () => {
  //           dispatch(updateShowSuspiciousAlert(false));
  //         },
  //       },
  //     ],
  //     onConfirm: () => {
  //       dispatch(updateShowSuspiciousAlert(false));
  //     },
  //   });
  // }, [showSuspiciousAlert]);

  const shuffleAdConfig = _.shuffle(FULLSCREEN_LIST)[0];
  const { openAd, ...adConfig } = useAdModal(shuffleAdConfig);

  const order = orders?.[0] ?? {};
  const { id, type, avatar, uniqueId, nickName, orderId, webVideoUrl, coverImage, videoUrl } =
    order;
  const imgUrl = type === 'follower' ? avatar : coverImage?.length ? coverImage : avatar;
  const emptyAvatar = isLoading
    ? avatar
    : 'https://i.pinimg.com/474x/ae/8a/c2/ae8ac2fa217d23aadcc913989fcc34a2---page-empty-page.jpg';
  const isEmpty = !id;

  useAppState({
    onForeground: async () => {
      // console.log('onForeground');
      if (wentToTikTok) {
        setWentToTikTok(false);
        setIsLoading(true);
        try {
          await dispatch(
            checkFollowStatusAction({
              id,
              type,
              targetUniqueId: uniqueId,
              orderId,
              order,
              currentVideoInfo,
            }),
          );
        } catch (e) {
          if (__DEV__) {
            console.log('onForeground error', e);
          }
        } finally {
          setIsLoading(false);
          updateOrderItems({
            countryCode,
            orders,
            setOrders,
            setShouldLoadMore,
            displayedOrderCount,
            setDisplayedOrderCount,
            openAd,
          });
        }
      }
    },
    onBackground: () => {
      // console.log('onBackground');
    },
  });

  React.useEffect(() => {
    const getVideoInfoFromLink = async () => {
      try {
        const { webVideoUrl } = order || {};
        const videoInfo = await apis.tiktok.getVideoInfoFromLink({ url: webVideoUrl });
        setCurrentVideoInfo(videoInfo);
      } catch (e) {
        if (__DEV__) {
          console.log('Home getVideoInfoFromLink error', e);
        }
      }
    };
    if (authUser?.tiktok?.openFavorite === false && order?.type !== 'follower') {
      getVideoInfoFromLink();
    }
  }, [order.id, authUser?.tiktok?.openFavorite]);

  return (
    <Container>
      <AppleLoginModal
        appleLoginModalStatus={appleLoginModalStatus}
        setAppleLoginModalStatus={setAppleLoginModalStatus}
      />
      <StyledScrollView
        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
      >
        {isLoading && <StyledActivityIndicator size="large" color="#FFFFFF" />}
        <UserProfilePicWrapper>
          {isEmpty ? (
            <RefreshButton onPress={() => setShouldLoadMore(true)} />
          ) : (
            <>
              {type === 'like' && <PlayButton onPress={() => { }} />}
              <UserProfilePic source={{ uri: imgUrl }} />
              <IdText>@{uniqueId || nickName}</IdText>
            </>
          )}
        </UserProfilePicWrapper>
        <ButtonWrapper>
          <SkipButton
            title="Skip"
            onPress={() => {
              updateOrderItems({
                countryCode,
                orders,
                setOrders,
                setShouldLoadMore,
                displayedOrderCount,
                setDisplayedOrderCount,
                openAd,
              });
              dispatch(pressSkipAction());
            }}
          />
          <FollowButton
            type={type}
            title={type === 'follower' ? 'Follow' : 'Like'}
            onPress={() => {
              if (orders.length <= 0) {
                return;
              }
              onPressFollowButton({
                type,
                uniqueId,
                webVideoUrl,
                setWentToTikTok,
              });
            }}
            isFollowButton={true}
          />
        </ButtonWrapper>
        <UnfollowWarningText>{i18n.t('unfollow_warning')}</UnfollowWarningText>
        <PWAInstructionView shouldCheckLaunchTimes={true} />
        {showSelfPromotionBanner && (
          <TSAdBanner
            style={{
              position: 'absolute',
              maxWidth: '95%',
              alignSelf: 'center',
              backgroundColor: '#EDF0F9',
            }}
            {...bannerConfig}
          />
        )}
      </StyledScrollView>
      <DailyReward />
      {/* <TSAdFullScreen {...adConfig} /> */}
    </Container>
  );
};

export default HomeScreen;

const { width: screenWidth } = Dimensions.get('window');

const Container = styled(View)`
  flex: 1;
`;

const StyledScrollView = styled(ScrollView).attrs({
  flex: 1,
  contentContainerStyle: {
    flex: 1,
    backgroundColor: 'white',
    alignItems: 'center',
  },
})``;

const UserProfilePic = styled(Image)`
  width: 100%;
  height: 100%;
  border-radius: 18;
`;

const UserProfilePicWrapper = styled(View)`
  width: ${screenWidth - 80};
  height: ${screenWidth - 80};
  border-radius: 18;
  max-width: 500;
  max-height: 500;
  background-color: ${path(['theme', 'primary', 'gray'])};
`;

const StyledActivityIndicator = styled(ActivityIndicator)`
  position: absolute;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
`;

const IdText = styled(Text)`
  position: absolute;
  bottom: 16;
  left: 16;
  color: #ffffff;
  font-size: 20;
  font-weight: bold;
  line-height: 24;
  shadow-color: #4a4a4a;
  shadow-opacity: 1;
  shadow-offset: 0 0;
`;

const FollowButtonText = styled(Text)`
  color: #ffffff;
  font-size: 18;
  font-weight: 900;
  line-height: 21;
`;

const FollowTouchableOpacity = styled(TouchableOpacity)`
  width: ${screenWidth / 2 - 40};
  max-width: 180;
  height: 49;
  border-radius: 24;
  align-items: center;
  justify-content: center;
  background-color: ${props =>
    props.isSkipButton ? path(['theme', 'primary', 'gray']) : path(['theme', 'primary', 'purple'])};
  margin-left: ${props => (props.isSkipButton ? 0 : 22)};
`;

const PlayButtonWrapper = styled(TouchableOpacity)`
  position: absolute;
  margin-left: -45;
  margin-top: -45;
  top: 50%;
  left: 50%;
  z-index: 90;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 45;
`;

const PlayButton = ({ onPress }) => {
  return (
    <PlayButtonWrapper onPress={onPress}>
      <Image source={require('assets/images/playbn.png')} style={{ width: 90, height: 90 }} />
    </PlayButtonWrapper>
  );
};

const FollowButtonTypeIcon = styled(Image)`
  width: 24;
  height: 24;
  margin-right: 5;
`;

const FollowButton = ({ type, title, isFollowButton, ...props }) => {
  const rewardNumber = type === 'comment' ? 2 : 1;
  const iconSource =
    type === 'comment'
      ? require('assets/images/commentbn.png')
      : type === 'like'
        ? require('assets/images/likebn.png')
        : require('assets/images/followbn.png');
  return (
    <FollowTouchableOpacity {...props}>
      {isFollowButton ? (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {type === 'comment' ? (
            <FontAwesome name="commenting-o" size={24} color="white" style={{ marginRight: 7 }} />
          ) : (
            <FollowButtonTypeIcon source={iconSource} />
          )}
          <FollowButtonText>
            {`+${rewardNumber}`}
            <Ionicons name="md-star" size={24} color="#fed967" />
          </FollowButtonText>
        </View>
      ) : (
        <FollowButtonText>{title}</FollowButtonText>
      )}
    </FollowTouchableOpacity>
  );
};

const SkipButton = props => {
  return <FollowButton isSkipButton={true} {...props} />;
};

const ButtonWrapper = styled(View)`
  flex-direction: row;
  margin-top: 40;
`;

const onPressFollowButton = ({ type, uniqueId, webVideoUrl, setWentToTikTok }) => {
  const link = type === 'follower' ? `https://www.tiktok.com/@${uniqueId}` : webVideoUrl;
  // const mobileUrl = `https://www.tiktok.com/@${uniqueId}`;
  Linking.canOpenURL(link)
    .then(supported => {
      if (supported) {
        Linking.openURL(link);
        setWentToTikTok(true);
      } else {
        console.log('not supported');
        // WebBrowser.openBrowserAsync(webUrl);
      }
    })
    .catch(err => {
      console.log('openURL error occurred', err);
      setWentToTikTok(false);
    });
};

const updateOrderItems = ({
  countryCode,
  orders,
  setOrders,
  setShouldLoadMore,
  displayedOrderCount,
  setDisplayedOrderCount,
  openAd,
}) => {
  if (orders.length > 0) {
    const newOrders = orders.slice(1);
    setOrders(newOrders);
    if (newOrders.length < 1) {
      setShouldLoadMore(true);
    }
    setDisplayedOrderCount(displayedOrderCount + 1);
    if (displayedOrderCount % 13 === 0) {
      openAd();
    }
  }
};

const UnfollowWarningText = styled(Text)`
  color: ${path(['theme', 'primary', 'lightGray'])};
  font-size: 12;
  max-width: 70%;
  margin-top: 20;
  line-height: 15;
`;

const RefreshButton = ({ onPress }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        marginLeft: -24,
        marginTop: -24,
        top: '50%',
        left: '50%',
        zIndex: 90,
      }}
    >
      <Ionicons name="md-refresh" size={48} color="#FFFFFF" />
    </TouchableOpacity>
  );
};

const BacklinkText = styled(UnfollowWarningText)`
  margin-top: 10;
  /* color: transparent; */
`;

const BacklinkButton = styled(Text)`
  margin-left: 10;
  font-weight: 600;
`;
