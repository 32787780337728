export const tiktokUserDataToProfile = (data) => {
  return ({
    id: data.userId,
    uniqueId: data.uniqueId,
    nickName: data.nickName,
    followingCount: data.following,
    followerCount: data.fans,
    video: data.video,
    avatar: data.coversMedium?.[0],
    signature: data.signature,
    heart: data.heart,
    diggCount: data.digg,
    isSecret: data.isSecret,
    secUid: data.secUid,
    openFavorite: data.openFavorite,
  });
};