import * as React from 'react';
import { View } from 'react-native';
import WebModal from 'modal-enhanced-react-native-web';
import styled from 'styled-components/native';
import { getMobileOperatingSystem } from 'utils/system';

import AppleLoginScreen from 'screens/AppleLoginScreen';

const os = getMobileOperatingSystem();

const Wrapper = styled(View)`
  flex: 1;
  margin: 35px;
  border-radius: 15;
  overflow: hidden;
`;

const AppleLoginModal = ({
  appleLoginModalStatus = { visible: false, type: 'default' },
  setAppleLoginModalStatus
}) => {
  const { visible, type = 'default', nextRoute = '' } = appleLoginModalStatus;
  if (os !== 'iOS') {
    return null;
  }

  return (
    <WebModal
      visible={visible}
      transparent={true}
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        margin: 0,
      }}
    >
      <Wrapper>
        <AppleLoginScreen
          setAppleLoginModalStatus={setAppleLoginModalStatus}
          loginType={type}
          nextRoute={nextRoute}
        />
      </Wrapper>
    </WebModal>
  );
};

export default AppleLoginModal;
