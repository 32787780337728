import _ from 'lodash';
import { Platform } from 'react-native';
import firebase from 'firebase/app';
import '@firebase/firestore';
import '@firebase/functions';
import '@firebase/auth';
import '@firebase/remote-config';
import { head, compose, path } from 'ramda';
// import messaging from '@react-native-firebase/messaging';

const firebaseConfig = __DEV__
  ? {
    apiKey: 'AIzaSyAF_OSUgUQgVixoIio2TRE4vy-Zwp6Lyus',
    authDomain: 'tikfans-hk.firebaseapp.com',
    databaseURL: 'https://tikfans-hk.firebaseio.com',
    projectId: 'tikfans-hk',
    storageBucket: 'tikfans-hk.appspot.com',
    messagingSenderId: '359885996311',
    appId: '1:359885996311:web:7e8173fa659f1fa04af752',
    measurementId: 'G-0M8QC4SWTY',
  }
  : {
    apiKey: 'AIzaSyAZqmylIOE4fQmf0pemugc2iBH33rSeMkg',
    authDomain: 'tikfans-prod-a3557.firebaseapp.com',
    databaseURL: 'https://tikfans-prod-a3557.firebaseio.com',
    projectId: 'tikfans-prod-a3557',
    storageBucket: 'tikfans-prod-a3557.appspot.com',
    messagingSenderId: '95733724385',
    appId: '1:95733724385:web:a30452b9c4c4154c3533a0',
    measurementId: 'G-KHCXNBWJM0',
  };

if (firebase.apps.length === 0) {
  try {
    firebase.initializeApp(firebaseConfig);
    // firebase.firestore().enablePersistence({ synchronizeTabs: true });
    const remoteConfig = firebase.remoteConfig();
    remoteConfig.settings.minimumFetchIntervalMillis = 60 * 1000 * 15;
    remoteConfig.fetchAndActivate();
  } catch (e) {
    if (__DEV__) {
      console.log('Init firebase error', e);
    }
  }
}

const db = firebase.firestore();

const getRemoteConfigValue = async ({ parameter, defaultValue }) => {
  try {
    const remoteConfig = firebase.remoteConfig();
    if (defaultValue) {
      remoteConfig.defaultConfig = {
        [parameter]: defaultValue,
      };
    }
    const value = remoteConfig.getValue(parameter);
    return value;
  } catch (e) {
    if (__DEV__) {
      console.log('remoteConfig parameter error', e);
    }
    return defaultValue;
  }
};

export const getOrders = async ({ type = 'followers', retry = 0, followingListObject = {} }) => {
  const purchasedSnapshot = await db
    .collection('orders')
    .where('fulfilled', '==', false)
    .where('hasPurchased', '==', true)
    .orderBy('updatedAt')
    .limit(2)
    .get();

  const purchasedDocs = purchasedSnapshot.docs.map(doc => ({
    ...doc.data(),
    orderId: doc.id,
  }));

  const defaultOrderFetchTimeSlots = [
    0.005, 0.08, 0.01, 0.015, 0.018, 0.03, 0.04, 0.05, 0.06, 0.8, 0.1, 0.11, 0.15, 0.2, 0.5, 3,
    1000,
  ];
  const orderFetchTimeSlotsValue = await getRemoteConfigValue({
    parameter: 'orderFetchTimeSlots',
    defaultValue: JSON.stringify(defaultOrderFetchTimeSlots),
  });

  const orderFetchTimeSlots = JSON.parse(orderFetchTimeSlotsValue.asString());
  const timeBefore = _.shuffle(orderFetchTimeSlots);
  const startAfter = new Date(Date.now() - 1000 * timeBefore[0] * 60 * 60);

  const normalSnapshot = await db
    .collection('orders')
    .where('fulfilled', '==', false)
    .where('hasPurchased', '==', false)
    .orderBy('updatedAt')
    .startAfter(startAfter)
    .limit(4)
    .get();

  const normalDocs = normalSnapshot.docs.map(doc => ({
    ...doc.data(),
    orderId: doc.id,
  }));
  const uniqueDocs = _.uniqBy([...purchasedDocs, ...normalDocs], 'id').filter(
    (order) => {
      return !followingListObject[order.orderId];
    }
  );
  if (uniqueDocs.length === 0 && retry < 3) {
    return getOrders({ type, retry: retry + 1, followingListObject });
  }
  return uniqueDocs;
};

export const getAuthUserId = async () => {
  try {
    const uid = firebase.auth().currentUser?.uid;
    if (!uid) {
      const { user } = await firebase.auth().signInAnonymously();
      return user?.uid;
    }
    return uid;
  } catch (e) {
    if (__DEV__) {
      console.log('getAuthUserId error', e);
    }
  }
};

export const updateUserStars = async ({ stars, uniqueId, uid, ...payload }) => {
  const data = {
    stars: firebase.firestore.FieldValue.increment(stars),
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    uid,
    uniqueId,
    ...payload,
  };
  return firebase.firestore().collection('users').doc(uid).set(data, { merge: true });
};

export const updateUserAppleId = async ({ appleId, uid, ...payload }) => {
  const data = {
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    uid,
    appleId,
    ...payload,
  };
  return firebase
    .firestore()
    .collection('users')
    .doc(uid)
    .set(data, { merge: true })
    .catch(e => {
      if (__DEV__) {
        console.log('updateUserAppleId error', e);
      }
    });
};

export const updateUserInfo = async (data, uid) => {
  return db
    .collection('users')
    .doc(uid)
    .set(data, { merge: true })
    .catch(e => {
      if (__DEV__) {
        console.log('updateUserInfo error:', e);
      }
    });
};

export const getUserInfoByAppleId = async appleId => {
  try {
    const snapshot = await db
      .collection('users')
      .where('appleId', '==', appleId)
      .orderBy('updatedAt', 'desc')
      .get();
    return snapshot.docs[0].data();
  } catch (e) {
    if (__DEV__) {
      console.log('getUserInfoByAppleId error', e);
    }
    return undefined;
  }
};

export const createUserDbRecord = async ({ uniqueId, uid }) => {
  db.collection('users').doc(uid).set(
    {
      uniqueId,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    },
    { merge: true },
  );
};

export const getUserInfo = async ({ uid }) => {
  try {
    const doc = await db.collection('users').doc(uid).get();
    const stars = doc.data()?.stars || 0;
    const hasUserDbRecord = doc.exists;
    return {
      ...doc.data(),
      stars,
      hasUserDbRecord,
    };
  } catch (e) {
    console.log('getUserInfo error', e);
    return { stars: 0, hasUserDbRecord: false };
  }
};

export const createOrder = async ({
  uid,
  type,
  selectedItem,
  tiktokProfile,
  orderCount,
  hasPurchased = false,
  canMakePaymentsWithActiveCard = false,
}) => {
  try {
    const timeBefore = _.shuffle([1, 2, 3, 4, 5, 6, 10]);
    const updatedAt = new Date(Date.now() - 1000 * 60 * timeBefore[0]);
    const buyerId = uid;
    const id = selectedItem?.id || tiktokProfile?.id;
    const data = {
      type,
      hasPurchased: hasPurchased,
      id: selectedItem?.id || tiktokProfile?.id,
      tiktokId: selectedItem?.tiktokId || tiktokProfile?.id,
      uniqueId: selectedItem?.uniqueId || tiktokProfile?.uniqueId,
      nickName: tiktokProfile?.nickName ?? '',
      initialFollowerCount: tiktokProfile?.followerCount,
      increasedFollowerCount: firebase.firestore.FieldValue.increment(0),
      orderCount: firebase.firestore.FieldValue.increment(Math.floor(orderCount * 1.2)),
      fulfilled: false,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: hasPurchased
        ? new Date(Date.now() - 1800 * 60 * 60)
        : firebase.firestore.FieldValue.serverTimestamp(),
      avatar: tiktokProfile?.avatar,
      buyerId,
      diggCount: selectedItem?.diggCount ?? 0,
      commentCount: selectedItem?.commentCount ?? 0,
      coverImage: selectedItem?.imageUrl ?? '',
      videoUrl: selectedItem?.videoUrl ?? '',
      webVideoUrl: selectedItem?.webVideoUrl ?? '',
      increasedCount: firebase.firestore.FieldValue.increment(0),
    };
    const historyData = {
      type: data.type,
      hasPurchased: data.hasPurchased,
      id: data.id,
      tiktokId: data.tiktokId,
      uniqueId: data.uniqueId,
      initialFollowerCount: data.initialFollowerCount,
      orderCount: data.orderCount,
      createdAt: data.createdAt,
      buyerId: data.buyerId,
      diggCount: data.diggCount,
      commentCount: data.commentCount,
      webVideoUrl: data.webVideoUrl,
    };
    const documentId = `${id}_${type}`
    await db.collection('orders').doc(documentId).set(data, { merge: true });
    await db.collection('orders').doc(documentId).collection('history').add(historyData);
  } catch (e) {
    console.log('createOrder error', e);
  }
};

export const updateOrderStatus = async ({ orderId }) => {
  try {
    const updateOrderFunction = firebase.functions().httpsCallable('updateOrderStatus');
    updateOrderFunction({ orderId });
  } catch (e) {
    console.log('updateOrderStatus error', e);
  }
};

export const onAuthStateChanged = () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(async user => {
      if (!user) {
        const newUser = await firebase
          .auth()
          .signInAnonymously()
          .catch(e => reject(e));
        resolve({ user: newUser });
      } else {
        resolve({ user });
      }
    });
  });
};

export const getLoginUserTikTokInfo = async ({ username }) => {
  const functionName = 'getLoginUserTikTokInfo';
  const callable = firebase.functions().httpsCallable(functionName);
  const res = await callable({ username });
  return res?.data;
};

export const getTikTokUserInfo = async ({ username }) => {
  const functionNames = _.shuffle([
    'getTikTokUserInfo',
    // 'getTikTokUserInfo1',
    // 'getTikTokUserInfo2',
    // 'getTikTokUserInfo3',
    // 'getTikTokUserInfo4',
    // 'getTikTokUserInfo5',
    // 'getTikTokUserInfo6',
  ]);
  const functionName = functionNames[0];

  const callable = firebase.functions().httpsCallable(functionName);
  const res = await callable({ username });
  return res?.data;
};

export const getUserTikTokVideoList = async ({ uniqueId }) => {
  const functionNames = _.shuffle([
    'getUserTikTokVideoList',
    // 'getUserTikTokVideoList1',
    // 'getUserTikTokVideoList2',
    // 'getUserTikTokVideoList3',
    // 'getUserTikTokVideoList4',
    // 'getUserTikTokVideoList5',
    // 'getUserTikTokVideoList6',
  ]);
  const functionName = functionNames[0];

  const callable = firebase.functions().httpsCallable(functionName);
  const res = await callable({ uniqueId });
  return res?.data;
};

export const getServerTime = async ({ timezone }) => {
  const callable = firebase.functions().httpsCallable('getServerTime');
  const res = await callable({ timezone });
  return res?.data;
};

export const handlePayByPrime = async ({ getPrimeResult, product, buyer, isDev }) => {
  const callable = firebase.functions().httpsCallable('handlePayByPrime');
  const res = await callable({
    getPrimeResult,
    product,
    buyer,
    isDev,
    frontend_redirect_url: __DEV__
      ? 'https://31c4-123-51-173-132.ngrok.io'
      : window.location.origin,
  });
  return res?.data;
};

export const getUserTransactionByBankTxId = async ({ bankTxId, uid }) => {
  const tx = await db
    .collection('transactions')
    .where('bank_transaction_id', '==', bankTxId)
    .where('uid', '==', uid)
    .get();
  const txDoc = tx?.docs?.[0];
  return txDoc && { docId: txDoc.id, ...txDoc.data() };
};

export const getTapPayRecord = async ({ recTradeId, isDev }) => {
  const callable = firebase.functions().httpsCallable('tapPayRecord');
  const res = await callable({ isDev, rec_trade_id: recTradeId });
  return compose(head, path(['data', 'trade_records']))(res);
};

export const fulfillByTxID = ({ txId }) => {
  return db.collection('transactions').doc(txId).set(
    {
      fulfilled: true,
    },
    { merge: true },
  );
};

export const getVideoFromLink = async ({ url }) => {
  const callable = firebase.functions().httpsCallable('getVideoFromLink');
  const res = await callable({ url });
  return res?.data;
};

export const slackNotificationFunction = async ({ payload, url }) => {
  try {
    const callable = firebase.functions().httpsCallable('slackNotification');
    callable({ payload, url });
  } catch (e) {
    if (__DEV__) {
      console.log('slackNotificationFunction error', e);
    }
  }
};

export const checkReturnUserInfo = async ({ uniqueId, uid }) => {
  try {
    const snapshot = await db.collection('users').where('uniqueId', '==', uniqueId).get();
    const doc = await db.collection('users').doc(uid).get();

    const result = [...snapshot?.docs, doc].reduce(
      (acc, cur) => {
        const res = {};
        if (cur?.data()?.hasReviewed) res.hasReviewed = true;
        if (cur?.data()?.hasRedeemedPromoCode) res.hasRedeemedPromoCode = true;
        if (cur?.data()?.referralCount)
          res.referralCount = acc?.referralCount ?? 0 + cur?.data()?.referralCount;
        if (cur?.data()?.hasRedeemedIOSFirstLoginReward) res.hasRedeemedIOSFirstLoginReward = true;
        if (cur?.data()?.hasRedeemedIOSFirstLoginRewardUsed)
          res.hasRedeemedIOSFirstLoginRewardUsed = true;
        if (cur?.data()?.hasFollowedIg) res.hasFollowedIg = true;
        if (cur?.data()?.appleId) res.appleId = cur?.data()?.appleId;
        if (cur?.data()?.hasRedeemedPromoCode) {
          res.hasRedeemedPromoCode = true;
          res.canRedeemPromoCode = false;
        }
        return {
          ...acc,
          ...res,
        };
      },
      {
        canRedeemPromoCode: true,
      },
    );
    return result;
  } catch (e) {
    if (__DEV__) {
      console.log('checkReturnUserInfo firebase error', e);
    }
    return {};
  }
};

export const rewardReview = async ({ stars, uniqueId, uid }) => {
  db.collection('users')
    .doc(uid)
    .set(
      {
        stars: firebase.firestore.FieldValue.increment(stars),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        uid,
        uniqueId,
        hasReviewed: true,
      },
      { merge: true },
    );
};

export const rewardFollowIg = async ({ stars, uniqueId, uid }) => {
  db.collection('users')
    .doc(uid)
    .set(
      {
        stars: firebase.firestore.FieldValue.increment(stars),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        uid,
        uniqueId,
        hasFollowedIg: true,
      },
      { merge: true },
    );
};

export const rewardDailyLogin = async ({ uid, stars, uniqueId, date, dailyRewardCount }) => {
  db.collection('users')
    .doc(uid)
    .set(
      {
        stars: firebase.firestore.FieldValue.increment(stars),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        uid,
        uniqueId,
        dailyReward: date,
        dailyRewardCount,
      },
      { merge: true },
    );
};

// export const registerAppWithFCM = async () => {
//   if (Platform.OS !== 'web') {
//     await messaging().registerDeviceForRemoteMessages();
//     if (__DEV__) {
//       // console.log('registerAppWithFCM');
//     }
//   }
// };

export const verifyPromoCode = async (promoCode, uniqueId) => {
  if (promoCode === uniqueId) {
    return {
      isPromoCodeValid: false,
    };
  }
  const db = firebase.firestore();
  const referrerSnapshot = await db
    .collection('users')
    .where('uniqueId', '==', promoCode)
    .orderBy('updatedAt', 'desc')
    .get();

  let referralCount = 0;
  let referralStars = 0;
  let accountExist = false;
  let referrerUid = '';
  referrerSnapshot.docs.forEach((doc, index) => {
    if (index === 0) {
      referrerUid = doc.id;
    }
    const data = doc.data();
    referralCount = referralCount + (data?.referralCount ?? 0);
    referralStars = referralStars + (data?.referralStars ?? 0);
    accountExist = true;
  });

  const validPromoCode = accountExist && referralStars < 500;
  // console.log('verifyPromoCode api', accountExist, referralStars);
  if (validPromoCode) {
    return {
      isPromoCodeValid: true,
      referrerUid,
    };
  }
  return {
    isPromoCodeValid: false,
    referrerUid,
  };
};

export const redeemPromoCode = async ({ uid, stars, uniqueId, referrerUid }) => {
  db.collection('users')
    .doc(uid)
    .set(
      {
        stars: firebase.firestore.FieldValue.increment(stars),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        uid,
        uniqueId,
        hasRedeemedPromoCode: true,
        // referrerUid,
      },
      { merge: true },
    );

  // Update referrer account
  db.collection('users')
    .doc(referrerUid)
    .set(
      {
        stars: firebase.firestore.FieldValue.increment(stars),
        referralStars: firebase.firestore.FieldValue.increment(stars),
        referralCount: firebase.firestore.FieldValue.increment(1),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true },
    );
};

export const redeemIOSFirstLoginReward = async ({ uid, uniqueId, stars }) => {
  db.collection('users')
    .doc(uid)
    .set(
      {
        stars: firebase.firestore.FieldValue.increment(stars),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        uid,
        uniqueId,
        hasRedeemedIOSFirstLoginReward: true,
        hasRedeemedIOSFirstLoginRewardUsed: false,
      },
      { merge: true },
    );
};

export const flagUserHasUsedWithIOSReward = async ({ used = true, uid }) => {
  db.collection('users').doc(uid).set(
    {
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      hasRedeemedIOSFirstLoginRewardUsed: used,
    },
    { merge: true },
  );
};

export const checkOrderTikTokStatus = async ({ orderId, order }) => {
  try {
    const callable = firebase.functions().httpsCallable('checkOrderTikTokStatus');
    await callable({ orderId, ...order });
  } catch (e) {
    if (__DEV__) {
      console.log('checkOrderTikTokStatus error', e);
    }
  }
};

export const logEvent = ({ name }) => {
  if (Platform.OS === 'web' && window.dataLayer) {
    if (process.env.NODE_ENV === 'development') {
      console.log('dataLayer send', { event: name });
    } else {
      window.dataLayer.push({
        event: name,
      });
    }
  }
};

export const createTransactionRecord = async payload => {
  const sanitizedPayload = _.pickBy(payload, p => p !== undefined);
  return firebase.firestore().collection('transactions').add(sanitizedPayload);
};

export const saveErrorLog = async payload => {
  try {
    const sanitizedPayload = _.pickBy(payload, p => p !== undefined);
    return db.collection('errors').add(sanitizedPayload);
  } catch (e) {
    if (__DEV__) {
      console.log('saveErrorLog', e);
    }
  }
};
