import { useEffect, useCallback, useRef } from 'react';
import { useNavigation } from '@react-navigation/native';

import { getTapPayRecord } from 'apis/firebase';
import Swal from 'sweetalert2';

const POLLING_TIMES = 10;

const usePollingTapPayTxResult = () => {
  const navigation = useNavigation();
  const tid = useRef(null);
  const pollingTimes = useRef(0);

  const pollingTxResult = useCallback(async ({ onSuccess, onPending, recTradeId }) => {
    const res = await getTapPayRecord({ isDev: __DEV__, recTradeId });

    pollingTimes.current += 1;
    if (res.record_status === 4 && pollingTimes.current < POLLING_TIMES) {
      tid.current = setTimeout(() => pollingTxResult({ onSuccess, onPending, recTradeId }), 500);
    } else if (res.record_status === 0) {
      onSuccess(res);
    } else if (res.record_status === 5) {
      navigation.goBack();
    } else {
      Swal.fire({
        title: 'Progressing',
        text: 'Retry?',
        icon: 'info',
        showCancelButton: true,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          pollingTimes.current = 0;
          tid.current = setTimeout(
            () => pollingTxResult({ onSuccess, onPending, recTradeId }),
            500,
          );
        } else {
          onPending(res);
          navigation.goBack();
        }
      });
    }
  }, []);

  useEffect(() => {
    return () => tid && clearTimeout(tid);
  }, []);

  return { pollingTxResult };
};

export default usePollingTapPayTxResult;
