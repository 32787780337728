import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import RedeemScreen from 'screens/RedeemScreen';
import StarsButton from 'components/Header/StarsButton';
import QuestionButton from 'components/Header/QuestionButton';


const Stack = createStackNavigator();

const RedeemStack = () => {
  return (
    <Stack.Navigator
      initialRouteName="Redeem"
    >
      <Stack.Screen
        name="Redeem"
        component={RedeemScreen}
        options={({ navigation, route }) => {
          return ({
            title: "",
            headerLeft: () => <QuestionButton destination="Introduction" />,
            headerRight: () => <StarsButton />,
            headerTitleAlign: 'center',
          });
        }}
      />
    </Stack.Navigator>
  );
};

export default RedeemStack;