import * as React from 'react';
import { path } from 'ramda';
import { Image, View, Text, TouchableOpacity, Platform } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import * as MailComposer from 'expo-mail-composer';
import dedent from 'dedent';
import styled from 'styled-components/native';

import TSAdBanner from 'components/Ads/TSAdBanner';
import i18n from 'i18n';

const ServiceDownScreen = () => {
  return (
    <Container>
      <StyledImage source={require('assets/images/server_maintenance.png')} />
      <Title>Service Maintenance</Title>
      <Subtitle>We'll return shortly</Subtitle>
      {/* <EmailIcon /> */}
      <TSAdBanner
        style={{
          position: 'absolute',
          bottom: 20,
          maxWidth: '95%',
          alignSelf: 'center',
          backgroundColor: '#FFFFFF'
        }}
        iconSource={{ uri: 'https://firebasestorage.googleapis.com/v0/b/instar-prod.appspot.com/o/icon.png?alt=media' }}
        linkUrl='https://ins.getfollowers.app'
        title={i18n.t('ins_promo_title')}
        subtitle={i18n.t('ins_promo_subtitle')}
      />
      <TSAdBanner
        style={{
          position: 'absolute',
          top: 20,
          maxWidth: '95%',
          alignSelf: 'center',
          backgroundColor: '#EDF0F9',
        }}
        iconSource={{
          uri:
            'https://play-lh.googleusercontent.com/tTnu7K0UQHnAKM_lNOse-ej4cmrdc1Pd9_69FvH0mzbH8Q4wIxkfiEo4fngvjCn1r4GL=s360',
        }}
        linkUrl="https://analyzer.page.link/in-app-ads"
        title={i18n.t('login_slogan_2')}
        subtitle={`Instagram ${i18n.t('purchase_promo_title_4')}`}
      />
    </Container>
  );
};

export default ServiceDownScreen;

const Container = styled(View)`
  flex: 1;
  background-color: ${path(['theme', 'primary', 'darkBlue'])};
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled(Image)`
  width: 210;
  height: 210;
`;

const Title = styled(Text)`
  font-size: 24;
  font-weight: 900;
  margin-top: 45;
  color: #FFFFFF;
`;

const Subtitle = styled(Text)`
  font-size: 16;
  margin-top: 12;
  margin-bottom: 12;
  color: #FFFFFF;
`;

const EmailIcon = () => {
  return (
    <TouchableOpacity onPress={() => {
      const options = {
        recipients: ['ttapp.service@gmail.com'],
        subject: 'App issue',
        body:
          '\n\n\n\n\n\n\n' +
          dedent`
                        ------ Don't delete infos below ------
                        platform: ${Platform.OS}
                        version: ${process.env?.APP_MANIFEST?.version}`,
      };
      MailComposer.composeAsync(options);
    }}>
      <AntDesign name="mail" size={22} color="white" style={{ marginLeft: 10 }} />
    </TouchableOpacity>
  );
};
