import * as React from 'react';
import {
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  Text,
  ActivityIndicator,
} from 'react-native';
import { path } from 'ramda';
import styled from 'styled-components/native';
import { FontAwesome } from '@expo/vector-icons';

import RedeemContentList from 'components/RedeemScreen/RedeemContentList';
import Colors from 'constants/Colors';

const RedeemLikesScreen = ({ route, navigation }) => {
  const { item } = route.params;
  const [isLoading, setIsLoading] = React.useState(false);
  return (
    <Container>
      {
        isLoading && <StyledActivityIndicator size="large" color={Colors.primary.lightGray} />
      }
      <StyledScrollView
        showsVerticalScrollIndicator={false}
      >
        <ListItem item={item} />
        <RedeemContentList
          type="like"
          selectedItem={item}
          setIsLoading={setIsLoading}
        />
      </StyledScrollView>
    </Container>
  );
};

export default RedeemLikesScreen;

const Container = styled(View)`
  flex: 1;
  background-color: ${path(['theme', 'primary', 'lightBlue'])};
  align-items: center;
`;

const StyledScrollView = styled(ScrollView).attrs({
  flex: 1,
  backgroundColor: Colors.primary?.lightBlue,
  contentContainerStyle: {
    backgroundColor: Colors.primary?.lightBlue,
    alignItems: 'center',
  }
})``;


const ListItemWrapper = styled(TouchableOpacity)`
  background-color: #ffffff;
  height: 184;
  width: 103;
  border-radius: 12;
  margin-top: 24;
  margin-left: 12;
  margin-right: 12;
  overflow: hidden;
`;

const VideoPreview = styled(Image)`
  width: 103;
  height: 123;
`;

const LikesNumberText = styled(Text)`
  color: #000000;
  margin-left: 8;
  margin-top:8;
`;

const ListItem = ({ item }) => {
  return (
    <ListItemWrapper onPress={() => { }}>
      <VideoPreview source={{ uri: item?.imageUrl || item?.covers?.default }} />
      <LikesNumberText>❤ {item?.diggCount}</LikesNumberText>
      <LikesNumberText><FontAwesome name="commenting-o" size={19} color="black" /> {item.commentCount >= 0 && item.commentCount}</LikesNumberText>
    </ListItemWrapper>
  );
};

const StyledActivityIndicator = styled(ActivityIndicator)`
  position: absolute;
  margin: auto;
  top: 0; 
  left: 0; 
  bottom: 0; 
  right: 0;
  z-index: 99;
`;

