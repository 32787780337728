import firebase from 'firebase/app';
import '@firebase/auth';

const provider = new firebase.auth.OAuthProvider('apple.com');
provider.addScope('email');

export const signInWithApple = () => firebase.auth().signInWithRedirect(provider);

export const getAppleLoginResult = async () => {
  const res = await firebase
    .auth()
    .getRedirectResult()
    .catch(e => console.log('auth redirect result err', e));
  const email = res?.additionalUserInfo?.profile?.email;
  return email;
};

export const SUPPORTED_LANG_OF_APPLE_BUTTON = {
  ar_SA: 'ar_SA',
  ca_ES: 'ca_ES',
  cs_CZ: 'cs_CZ',
  da_DK: 'da_DK',
  de_DE: 'de_DE',
  el_GR: 'el_GR',
  en_GB: 'en_GB',
  en_US: 'en_US',
  es_ES: 'es_ES',
  es_MX: 'es_MX',
  fi_FI: 'fi_FI',
  fr_CA: 'fr_CA',
  fr_FR: 'fr_FR',
  hr_HR: 'hr_HR',
  hu_HU: 'hu_HU',
  id_ID: 'id_ID',
  it_IT: 'it_IT',
  iw_IL: 'iw_IL',
  ja_JP: 'ja_JP',
  ko_KR: 'ko_KR',
  ms_MY: 'ms_MY',
  nl_NL: 'nl_NL',
  no_NO: 'no_NO',
  pl_PL: 'pl_PL',
  pt_BR: 'pt_BR',
  pt_PT: 'pt_PT',
  ro_RO: 'ro_RO',
  ru_RU: 'ru_RU',
  sk_SK: 'sk_SK',
  sv_SE: 'sv_SE',
  th_TH: 'th_TH',
  tr_TR: 'tr_TR',
  uk_UA: 'uk_UA',
  vi_VI: 'vi_VI',
  zh_CN: 'zh_CN',
  zh_HK: 'zh_HK',
  zh_TW: 'zh_TW',
};
