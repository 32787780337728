import * as React from 'react';
import { Platform, View } from 'react-native';
import styled from 'styled-components/native';
import {
  AdMobBanner,
  AdMobInterstitial,
  AdMobRewarded,
  setTestDeviceIDAsync,
} from 'expo-ads-admob';

// import AdSense from 'react-adsense';

const unitIDs = __DEV__
  ? Platform.select({
    // DEV
    android: {
      banner: 'ca-app-pub-3940256099942544/6300978111',
      interstitial: 'ca-app-pub-3940256099942544/1033173712',
      rewarded: 'ca-app-pub-3940256099942544/5224354917',
    },
    ios: {},
    web: {
      banner: {
        client: 'ca-pub-2563903975834912',
        slot: '2454280209',
      },
    },
  })
  : Platform.select({
    // PROD
    android: {
      banner: 'ca-app-pub-2563903975834912/1900408174',
      interstitial: 'ca-app-pub-2563903975834912/4824038696',
      rewarded: 'ca-app-pub-2563903975834912/7358896216',
    },
    ios: {},
  });

if (Platform.OS !== 'web') {
  setTestDeviceIDAsync('EMULATOR');
  AdMobRewarded.setAdUnitID(unitIDs.rewarded);
  AdMobInterstitial.setAdUnitID(unitIDs.interstitial);
}

const AdSenseDevWrapper = styled(View)`
  flex: 1;
  flex-basis: 100%;
  display: flex;
  height: 60px;
  border: 1px solid red;
  bottom: 0;
  right: 0;
  left: 0;
`;

export const AdBanner = ({ style }) => {
  return (<View></View>);
  // if (Platform.OS === 'web') {
  //   return __DEV__ ? (
  //     <AdSenseDevWrapper style={style} />
  //   ) : (
  //       <AdSense.Google
  //         client={unitIDs.banner.client}
  //         slot={unitIDs.banner.slot}
  //         style={{
  //           ...style,
  //           minWidth: '375px',
  //           maxWidth: '970px',
  //           width: '100%',
  //           height: '50px',
  //         }}
  //         format="horizontal"
  //       />
  //     );
  // }
  // return (
  //   <AdMobBanner
  //     style={style}
  //     bannerSize="smartBannerPortrait"
  //     adUnitID={unitIDs.banner} // Test ID, Replace with your-admob-unit-id
  //     setTestDeviceID="EMULATOR"
  //     servePersonalizedAds // true or false
  //     onDidFailToReceiveAdWithError={e => console.log('AdBanner error', e)}
  //   />
  // );
};

export const showADRewarded = async () => {
  if (Platform.OS === 'web') {
    return;
  }
  await AdMobRewarded.requestAdAsync();
  await AdMobRewarded.showAdAsync();
};

export const showADInterstitial = async () => {
  if (Platform.OS === 'web') {
    return;
  }
  await AdMobInterstitial.requestAdAsync({ servePersonalizedAds: true });
  await AdMobInterstitial.showAdAsync();
};

export const showFullScreenAd = () => {
  if (Platform.OS === 'web') {
    return;
  }
  Math.random() < 0.5 ? showADInterstitial() : showADRewarded();
};
