import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import HomeScreen from 'screens/HomeScreen';
import StarsButton from 'components/Header/StarsButton';
import QuestionButton from 'components/Header/QuestionButton';

const Stack = createStackNavigator();

const HomeStack = () => {
  return (
    <Stack.Navigator
      initialRouteName="Home"
    >
      <Stack.Screen
        name="Home"
        component={HomeScreen}
        options={(route) => {
          return ({
            title: "Get Stars",
            headerLeft: () => <QuestionButton />,
            headerRight: () => <StarsButton />,
            headerTitleAlign: 'center',
          });
        }}
      />
    </Stack.Navigator>
  );
};

export default HomeStack;