import * as React from 'react';
import {
  Text,
  Linking,
  TouchableOpacity,
} from 'react-native';
import styled from 'styled-components/native';
import { Ionicons } from '@expo/vector-icons';
import { path } from 'ramda';
import i18n from 'i18n';

const SelfPromotionBanner = ({ content, style, linkUrl = 'https://instarapp.page.link/invite' }) => {
  return (
    <Container
      style={style}
      onPress={async () => {
        const supported = await Linking.canOpenURL(linkUrl);
        if (supported) {
          await Linking.openURL(linkUrl);
        }
      }}
    >
      <Ionicons name="logo-instagram" size={38} color="white" />
      <ContentText numberOfLines={2} >{i18n.t('ins_promo_text')}</ContentText>
      <AdText>Ad sponsored</AdText>
    </Container>
  );
};

export default SelfPromotionBanner;

const Container = styled(TouchableOpacity)`
  width: 100%;
  height: 60;
  background-color: #c90586;
  padding-left: 10;
  flex-direction: row;
  align-items: center;
`;

const ContentText = styled(Text)`
  color: #FFFFFF;
  font-size: 16;
  font-weight: bold;
  max-height: 100%;
  margin-left: 10;
`;

const AdText = styled(Text)`
  color: ${path(['theme', 'primary', 'gray'])};
  position: absolute;
  top: 0;
  left: 6;
  font-size: 10;
`;
