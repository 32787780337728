import * as React from 'react';
import { useSelector } from 'react-redux';
import { TouchableOpacity, Image, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Colors from 'constants/Colors';

const StarsButton = () => {
  const user = useSelector(state => state.user);
  const stars = user.stars;
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      style={{ marginRight: 16, flexDirection: 'row', alignItems: 'center' }}
      onPress={() => navigation.navigate('PurchaseStack')}
    >
      <Text style={{
        marginRight: 4,
        color: Colors.primary.purple,
        fontSize: 18,
        fontWeight: 'bold',
      }}>
        {stars}
      </Text>
      <Image style={{ width: 24, height: 24 }} source={require('assets/images/star.png')} />
    </TouchableOpacity>
  );
};

export default StarsButton;