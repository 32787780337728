import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import RedeemScreen from 'screens/RedeemScreen';
import RedeemLikesScreen from 'screens/RedeemLikesScreen';
import UploadVideoLinkScreen from 'screens/UploadVideoLinkScreen';
import StarsButton from 'components/Header/StarsButton';
import QuestionButton from 'components/Header/QuestionButton';


const Stack = createStackNavigator();

const RedeemLikesStack = () => {
  return (
    <Stack.Navigator
      initialRouteName="RedeemLikes"
    >
      <Stack.Screen
        name="RedeemLikes"
        component={RedeemScreen}
        options={({ navigation, route }) => {
          return ({
            title: "",
            headerLeft: () => <QuestionButton destination="Introduction" />,
            headerRight: () => <StarsButton />,
            headerTitleAlign: 'center',
          });
        }}
      />
      <Stack.Screen
        name="RedeemLikesConfirm"
        component={RedeemLikesScreen}
        options={({ navigation, route }) => {
          return ({
            title: "",
            headerRight: () => <StarsButton />,
            headerTitleAlign: 'center',
          });
        }}
      />
      <Stack.Screen
        name="UploadVideoLink"
        component={UploadVideoLinkScreen}
        options={({ navigation, route }) => {
          return ({
            title: "",
            headerRight: () => <StarsButton />,
            headerTitleAlign: 'center',
          });
        }}
      />

    </Stack.Navigator>
  );
};

export default RedeemLikesStack;