import * as React from 'react';
import { TouchableOpacity, Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';

const QuestionButton = ({ destination = 'Tutorial' }) => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      style={{ marginLeft: 16 }}
      onPress={() => navigation.navigate(destination)}
    >
      <Image style={{ width: 24, height: 24 }} source={require('assets/images/help.png')} />
    </TouchableOpacity>
  );
};

export default QuestionButton;