import { Platform } from 'react-native';
import Swal from 'sweetalert2';
import { handlePayByPrime } from 'apis/firebase';
import { newApplePayEvent } from 'apis/slack';

export const init = async () => {
  try {
    if (Platform.OS === 'web') {
      const canMakePayments = TPDirect.paymentRequestApi.checkAvailability();
      const canMakePaymentsWithActiveCard = await checkCanMakePaymentsWithActiveCard();
      if (!canMakePayments) {
        return {
          canMakePayments: false,
          canMakePaymentsWithActiveCard: false,
        };
      }
      const env = __DEV__ ? 'sandbox' : 'production';
      TPDirect.setupSDK(
        16295,
        'app_TE8l8VoFVmbe6OhZdv66zmF3temHOVYTKAawpPyQxitrXWpNKAAhynTyF93h',
        env
      );
      TPDirect.paymentRequestApi.setupApplePay({
        merchantIdentifier: 'merchant.com.tikfans.www',
        countryCode: 'TW',
      });

      return {
        canMakePayments,
        canMakePaymentsWithActiveCard,
      };
    } else {
      return {
        canMakePayments: false,
        canMakePaymentsWithActiveCard: false,
      };
    }
  } catch (e) {
    if (__DEV__) {
      console.log('init apple pay error', e);
    }
  }
};

export const checkCanMakePaymentsWithActiveCard = async () => {
  try {
    if (Platform.OS === 'web') {
      if (window?.ApplePaySession) {
        var merchantIdentifier = 'merchant.com.tikfans.www';
        const canMakePayments = await ApplePaySession.canMakePaymentsWithActiveCard(
          merchantIdentifier
        );
        return canMakePayments;
      }
      return false;
    }
    return false;
  } catch (e) {
    if (__DEV__) {
      console.log('canMakePaymentsWithActiveCard error', e);
    }
    return false;
  }
};

const setupPaymentRequest = (data) => {
  TPDirect.paymentRequestApi.setupPaymentRequest(data, (result) => {
    if (__DEV__) {
      console.log('paymentRequest result', result);
      // 代表瀏覽器支援 payment request api (或 apple pay)
      if (!result.browserSupportPaymentRequest) {
        console.log('瀏覽器不支援 PaymentRequest');
        return;
      }
      if (result.canMakePaymentWithActiveCard === true) {
        console.log('該裝置有支援的卡片可以付款');
      } else {
        // 如果 supportedMethods 有 card 選項，仍然可以顯示 PaymentRequest
        // 瀏覽器會請使用者綁定卡片
        console.log('該裝置沒有支援的卡片可以付款');
      }
    }
  });
};

const getPrimeAsync = () => {
  return new Promise((resolve) => {
    TPDirect.paymentRequestApi.getPrime((getPrimeResult) => {
      resolve(getPrimeResult);
    });
  });
};

export const paymentRequest = async ({ content, user, onApplePayApprove }) => {
  const buyer = {
    uid: user?.uid,
    countryCode: user?.countryCode,
    timezone: user?.timezone,
    tiktok: user?.tiktok,
  };

  newApplePayEvent({
    type: 'onPress',
    message: 'onPress',
    ...content,
    ...buyer,
    platform: Platform.OS,
  });

  const price = content.price.replace(/[^0-9.-]+/g, '');
  const priceNumber = Number.parseFloat(price);
  const data = {
    supportedNetworks: ['AMEX', 'MASTERCARD', 'VISA'],
    supportedMethods: ['apple_pay'],
    displayItems: [
      {
        label: `${content.localPrice} = `,
        amount: {
          currency: 'TWD',
          // value: Number.parseFloat(priceNumber),
          value: Number.parseFloat(price * 30).toFixed(0),
        },
      },
    ],
    total: {
      label: `${content.stars} stars`,
      amount: {
        currency: 'TWD',
        value: Number.parseFloat(priceNumber * 30).toFixed(0),
      },
    },
    options: {
      requestPayerPhone: true,
      requestPayerEmail: true,
    },
  };

  setupPaymentRequest(data);

  const getPrimeResult = await getPrimeAsync().catch((e) => {
    if (__DEV__) {
      console.log('getPrimeAsync error', e);
    }
  });

  // console.log('getPrimeResult', getPrimeResult);
  if (getPrimeResult?.status === 401) {
    // User cancel
    newApplePayEvent({
      type: 'onCancel',
      message: 'onCancel',
      ...content,
      ...buyer,
      platform: Platform.OS,
    });
    return;
  }

  const email = getPrimeResult?.payer?.email;

  const payByPrimeResult = await handlePayByPrime({
    getPrimeResult,
    product: content,
    buyer,
    isDev: __DEV__,
  }).catch((e) => {
    if (__DEV__) {
      console.log('payByPrimeResult error:', e);
    }
    Swal.fire({
      title: 'Oops...Purchase Failed',
      text: 'Your payment failed. please try again!',
      icon: 'error',
      width: '85%',
    });
  });


  if (payByPrimeResult?.status === 0) {
    // Success
    onApplePayApprove(content);
    newApplePayEvent({
      type: 'onSuccess',
      message: 'onSuccess',
      ...content,
      ...buyer,
      email,
      platform: Platform.OS,
    });
  } else {
    // Failed
    if (__DEV__) {
      console.log('payByPrimeResult failed', payByPrimeResult);
    }
    Swal.fire({
      title: 'Oops...Purchase Failed',
      text: 'Your payment failed. please try again!',
      icon: 'error',
      width: '85%',
    });
    newApplePayEvent({
      type: 'onError',
      message: payByPrimeResult,
      ...content,
      ...buyer,
      platform: Platform.OS,
    });
  }
  return { isSuccessful: payByPrimeResult?.status === 0, payByPrimeResult: { ...payByPrimeResult, email } };
};
