import * as React from 'react';
import { path } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import {
  View,
  Dimensions,
  Image,
  Text,
  TextInput,
  TouchableOpacity,
  ActivityIndicator,
  Keyboard,
  Platform,
  Linking,
} from 'react-native';
import * as MailComposer from 'expo-mail-composer';
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { ScrollView } from 'react-native-gesture-handler';
import styled from 'styled-components/native';
import dedent from 'dedent';

import Colors from 'constants/Colors';
import {
  getUserProfileInfoAction,
  newTikTokLogin,
  resetTikTokUser,
} from 'actions/userActions';
import HelpModal from 'components/LoginScreen/HelpModal';
import PWAInstructionView from 'components/LoginScreen/PWAInstructionView';
import Alert from 'components/Alert';
import AppleLoginModal from 'screens/AppleLoginModal';
import { AdBanner } from 'components/Ads';
import ServiceDownScreen from 'screens/ServiceDownScreen';
import i18n from 'i18n';

// Usage of sign in from apple directly(not firebase)
// const validateIdToken = async idToken => {
//   const keysResponse = await fetch('https://appleid.apple.com/auth/keys');
//   const keySet = await keysResponse.json();
//   const headerObj = KJUR.jws.JWS.readSafeJSONString(b64utoutf8(idToken.split('.')[0]));
//   const payloadObj = KJUR.jws.JWS.readSafeJSONString(b64utoutf8(idToken.split('.')[1]));
//   const key = keySet?.keys?.filter(key => key?.kid === headerObj?.kid)[0];
//   const pubkey = KEYUTIL.getKey(key);
//   const isValid = KJUR.jws.JWS.verifyJWT(idToken, pubkey, { alg: [headerObj.alg] });

//   if (isValid) {
//     const isFromApple = payloadObj?.iss === 'https://appleid.apple.com';
//     const isClientIdCorrect = payloadObj?.aud === 'com.bumbumapp.tikfans';
//     const isBeforeExpiration = payloadObj?.exp >= Date.now() / 1000;
//     return isFromApple && isClientIdCorrect && isBeforeExpiration ? payloadObj : false;
//   } else {
//     return false;
//   }
// };

const LoginScreen = () => {
  const dispatch = useDispatch();
  const [isHelpModalOpen, setIsHelpModalOpen] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const isFetchingUser = useSelector(state => state.user?.isFetchingUser);
  const user = useSelector(state => state.user);
  const [appleIdPayload, setAppleIdPayload] = React.useState();
  React.useEffect(() => {
    dispatch(resetTikTokUser());
    // Usage of sign in from apple directly(not firebase)
    // const checkIsLoginWithApple = async () => {
    //   const url = await Linking.getInitialURL();
    //   if (url.indexOf('id_token') !== -1) {
    //     const idToken = url.slice(url.indexOf('id_token')).replace('id_token=', '');
    //     const validatedIdToken = await validateIdToken(idToken);
    //     setAppleIdPayload(validatedIdToken);
    //     console.log('validatedIdToken', validatedIdToken);
    //   }
    // };
    // checkIsLoginWithApple();
  }, []);

  return (
    <StyledScrollView keyboardShouldPersistTaps="handled">
      {isFetchingUser && <StyledActivityIndicator size="large" color="#FFFFFF" />}
      <LogoArea>
        <Logo source={require('assets/images/login.png')} resizeMode="contain" />
      </LogoArea>
      <InputArea
        username={username}
        setUsername={setUsername}
        setIsHelpModalOpen={setIsHelpModalOpen}
        appleIdPayload={appleIdPayload}
      />
      {/* <Footer /> */}
      <HelpModal visible={isHelpModalOpen} setIsHelpModalOpen={setIsHelpModalOpen} />
      <PWAInstructionView />
      {/* <AdBanner
        style={{ position: 'absolute', bottom: 0, left: 0 }}
      /> */}
    </StyledScrollView>
  );
};

export default LoginScreen;

const { height: screenHeight } = Dimensions.get('window');

const StyledScrollView = styled(ScrollView).attrs({
  flex: 1,
  backgroundColor: Colors.primary?.darkBlue,
  contentContainerStyle: {
    backgroundColor: Colors.primary?.darkBlue,
    alignItems: 'center',
  },
})``;

const StyledActivityIndicator = styled(ActivityIndicator)`
  position: absolute;
  margin: auto;
  top: 0; 
  left: 0; 
  bottom: 0; 
  right: 0;
  z-index: 99;
`;

const LogoArea = styled(View)`
  height: ${screenHeight * 0.3};
  width: 100%;
  justify-content: center;
  align-items: center;
  /* background-color: #847345; */
`;

const Logo = styled(Image)`
  max-width: 100;
  width: 100;
  height: 120;
`;

const InputAreaWrapper = styled(View)`
  height: ${screenHeight * 0.7};
  width: 100%;
  background-color: ${path(['theme', 'primary', 'lightBlue'])};
  border-top-right-radius: 24;
  border-top-left-radius: 24;
  align-items: center;
`;

const InputAreaContentWrapper = styled(View)`
  width: 70%;
  max-width: 264;
  margin-top: 40;
`;

const UserNameLabel = styled(Text)`
  font-size: 18;
  font-weight: bold;
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
  height: 24;
  border-bottom-width: 0.5;
  margin-top: 20;
  font-size: 18;
  padding-left: 20;
  font-weight: bold;
  padding-bottom: 5;
`;

const FooterWrapper = styled(View)`
  position: absolute;
  width: 100%;
  height: 40;
  bottom: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-top-color: #FFFFFF;
  border-top-width: 0.5;
`;

const FooterText = styled(Text)`
  font-size: 14;
  color: ${path(['theme', 'primary', 'dark'])};
`;

const FooterIconsWrapper = styled(View)`
  flex-direction: row;
  align-items: center;
`;

const EmailIcon = () => {
  return (
    <TouchableOpacity onPress={() => {
      const options = {
        recipients: ['ttapp.service@gmail.com'],
        subject: 'TikFans issue',
        body:
          '\n\n\n\n\n\n\n' +
          dedent`
                        ------ Don't delete infos below ------
                        platform: ${Platform.OS}
                        version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}`,
      };
      MailComposer.composeAsync(options);
    }}>
      <AntDesign name="mail" size={22} color="black" style={{ marginLeft: 10 }} />
    </TouchableOpacity>
  );
};

const InstagramIcon = () => {
  return (
    <TouchableOpacity onPress={async () => {
      const url = 'https://www.instagram.com/tikfans.app/';
      const supported = await Linking.canOpenURL(url);
      if (supported) {
        Linking.openURL(url);
      }
    }}>
      <AntDesign name="instagram" size={22} color="black" />
    </TouchableOpacity>
  );
};

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterText>@2020 Toushih Tech, Inc</FooterText>
      <FooterIconsWrapper>
        <InstagramIcon />
        <EmailIcon />
      </FooterIconsWrapper>
    </FooterWrapper>
  );
};

const TextInputArea = (props) => (
  <View>
    <UserNameLabel style={{ position: 'absolute', left: 0, bottom: 4 }} >@</UserNameLabel>
    <StyledTextInput {...props} />
  </View>
);

const WarningMessage = styled(Text)`
  width: 100%;
  color: ${path(['theme', 'primary', 'lightGray'])};
  margin-top: 12;
`;

const ContinueButton = styled(TouchableOpacity)`
  width: 220;
  height: 49;
  background-color: ${path(['theme', 'primary', 'purple'])};
  border-radius: 24;
  justify-content: center;
  margin-top: 24;
  align-self: center;
`;

const ContinueButtonText = styled(Text)`
  color: #FFFF;
  font-size: 18;
  font-weight: bold;
  text-align: center;
`;

const HelpButton = styled(TouchableOpacity)`
  margin-top: 22;
  align-self: center;
`;

const HelpButtonText = styled(Text)`
  font-size: 15;
  font-weight: bold;
  color: ${path(['theme', 'primary', 'lightPink'])};
  text-decoration-line: underline;
`;

const InputArea = ({ username, setUsername, setIsHelpModalOpen, appleIdPayload }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [appleLoginModalStatus, setAppleLoginModalStatus] = React.useState({ visible: false, type: 'default' });

  const onPressContinue = async () => {
    const sanitizedUsername = username.replace('@', '').trim();
    Keyboard.dismiss();
    const userSuccessfullyFetched = await dispatch(
      getUserProfileInfoAction({ username: sanitizedUsername, isLogin: true }),
    );
    if (!userSuccessfullyFetched) {
      Alert({
        title: 'Username not found',
        body: `@${sanitizedUsername} does not exist. Please confirm your username again.`,
        actions: [
          {
            text: 'OK',
            onPress: () => {
              setIsHelpModalOpen(true);
            },
          },
        ],
        onConfirm: () => setIsHelpModalOpen(true),
      });
      return;
    }
    const { shouldShowAppleLogin } = await dispatch(newTikTokLogin());
    if (shouldShowAppleLogin) {
      setAppleLoginModalStatus({ visible: true, type: 'account_confirm', nextRoute: 'Root' });
      return;
    }
    navigation.navigate('Root');
  };

  return (
    <InputAreaWrapper>
      <AppleLoginModal
        appleLoginModalStatus={appleLoginModalStatus}
        setAppleLoginModalStatus={setAppleLoginModalStatus}
      />
      <InputAreaContentWrapper>
        <UserNameLabel>{i18n.t('general_username')}</UserNameLabel>
        <TextInputArea
          autoCapitalize="none"
          value={username}
          onChangeText={text => setUsername(text)}
        />
        <WarningMessage>{i18n.t('login_username_desc')}</WarningMessage>
        <ContinueButton onPress={() => onPressContinue({ username })}>
          <ContinueButtonText>{i18n.t('general_continue')}</ContinueButtonText>
        </ContinueButton>
        <HelpButton onPress={() => setIsHelpModalOpen(true)}>
          <HelpButtonText>{i18n.t('login_find_username')}</HelpButtonText>
        </HelpButton>
      </InputAreaContentWrapper>
    </InputAreaWrapper>
  );
};
