const tintColor = '#2f95dc';

export default {
  tintColor,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColor,
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
  primary: {
    purple: '#7A8BFF',
    gray: '#9B9B9B',
    lightGray: '#4A4A4A',
    lightBlue: '#EDF0F9',
    pink: '#C576C7',
    lightPink: '#F07AA3',
    blue: '#82ACFF',
    darkBlue: '#05112a',
    dark: '#030303',
    darkBlue2: '#0C0A2E',
  },
};
