import * as React from 'react';
import { path } from 'ramda';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import styled from 'styled-components/native';
import { isIosBrowser, getIOSVersion } from 'utils/system';
import i18n from 'i18n';

const PWAInstructionView = ({ shouldCheckLaunchTimes = false }) => {
  const [shouldDisplay, setShouldDisplay] = React.useState(false);

  React.useEffect(() => {
    const shouldDisplay = isIosBrowser() && getIOSVersion() > 14;
    setShouldDisplay(shouldDisplay);
  }, []);

  React.useEffect(() => {
    const checkLaunchTimes = async () => {
      try {
        const value = await AsyncStorage.getItem('pwaLaunchTimes') ?? 0;
        if (parseInt(value) > 1) {
          setShouldDisplay(false);
          return;
        }
        await AsyncStorage.setItem('pwaLaunchTimes', parseInt(value) + 1)
      } catch (e) {
        if (__DEV__) {
          console.log('CheckLaunchTimes error', e);
        }
      }
    };
    if (shouldCheckLaunchTimes) {
      checkLaunchTimes();
    }
  }, []);

  if (!shouldDisplay) {
    return (<View></View>);
  }
  return (
    <Container>
      <InstructionView>
        <CloseButton onPress={() => setShouldDisplay(false)} />
        <Title>TikFans App</Title>
        <SubTitle>{i18n.t('pwa_instruction_subtitle')}</SubTitle>
        <Icons>
          <IconWithTitle
            source={require('assets/images/pwa1.png')}
            title="↘️ ↘️ ↘️"
          />
          <ArrowIcon source={require('assets/images/pwanext.png')} />
          <IconWithTitle
            source={require('assets/images/pwa2.png')}
            title={i18n.t('pwa_instruction_add_home_screen')}
          />
          <ArrowIcon source={require('assets/images/pwanext.png')} />
          <IconWithTitle
            source={require('assets/images/pwa3.png')}
            title={`${i18n.t('pwa_instruction_add')} TikFans`}
          />
        </Icons>
        <ArrowBottom />
      </InstructionView>
    </Container>
  );
};

const backgroundColor = 'rgba(255, 255, 255, 1)';

const Container = styled(View)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 22;
  align-items: center;
`;

const InstructionView = styled(View)`
  position: absolute;
  bottom: 60;
  width: 90%;
  max-width: 358;
  height: 200;
  background-color: ${backgroundColor};
  margin: auto;
  z-index: 99;
  border-radius: 24;
  align-items: center;
`;

const Title = styled(Text)`
  font-size: 20;
  font-weight: bold;
  color: ${path(['theme', 'primary', 'purple'])};
  margin-top: 15;
`;

const SubTitle = styled(Text)`
  font-size: 14;
  margin-top: 5;
`;

const IconWithTitle = ({ source, title }) => {
  return (
    <View style={{ alignItems: 'center', alignSelf: 'flex-start' }}>
      <Image source={source} style={{ width: 60, height: 60 }} />
      <Text style={{ maxWidth: 90, marginTop: 7, textAlign: 'center', fontSize: 13 }}>{title}</Text>
    </View>
  );
};

const ArrowIcon = styled(Image)`
  width: 12;
  height: 21;
`;

const Icons = styled(View)`
  flex-direction: row;
  margin-top: 13;
  justify-content: space-around;
  align-items: center;
  width: 95%;
`;

const ArrowBottom = styled(View)`
  position: absolute;
  bottom: -30;
  width: 10;
  height: 0;
  background-color: transparent;
  border-style: solid;
  border-left-width: 25;
  border-right-width: 25;
  border-top-width: 30;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: ${backgroundColor};
`;

const CloseButton = ({ onPress }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        position: 'absolute',
        top: 12,
        right: 15,
      }}>
      <Image style={{ width: 18, height: 18 }} source={require('assets/images/close.png')} />
    </TouchableOpacity>
  );
};

export default PWAInstructionView;