import * as React from 'react';
import { path } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import {
  View,
  Text,
  Image,
  Platform,
  Dimensions,
  TouchableOpacity,
} from 'react-native';
import delay from 'delay';

import styled from 'styled-components/native';

import { rewardDailyLoginAction } from 'actions/userActions';
import i18n from 'i18n';

const DailyReward = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = React.useState(false);
  const state = useSelector(state => state);
  const dailyReward = state?.user?.dailyReward;
  const dailyRewardCount = state?.user?.dailyRewardCount;
  const serverTime = state?.time?.data;
  const serverTimeDate = new Date(serverTime?.datetime).getDate();
  const today = new Date();
  const countryCode = state?.user?.countryCode;
  const isRestrictedCountries = (countryCode === 'IN' || countryCode === 'PK' || countryCode === 'VN' || countryCode === 'IQ' || countryCode === 'PH');

  React.useEffect(() => {
    const checkIsVisible = async () => {
      if (isRestrictedCountries) {
        return setVisible(false);
      }
      if (!dailyReward) {
        await delay(300);
        return setVisible(true);
      }
      if (dailyReward === today.getDate()) {
        return setVisible(false);
      }
      if (serverTimeDate !== today.getDate()) {
        return setVisible(false);
      }
      await delay(300);
      setVisible(true);
    };
    checkIsVisible();
  }, [serverTimeDate]);

  const onPress = React.useCallback(() => {
    dispatch(rewardDailyLoginAction({ date: today.getDate() }));
    setVisible(false);
  }, [today]);

  if (visible) {
    return (
      <Container>
        <ContentWrapper>
          <Title>DAILY REWARD</Title>
          <GiftImage source={require('assets/images/rewardstars.png')} />
          <Calendar>
            {
              DATES.map((date, index) => {
                return (<CalendarDateUnit key={index} date={date} />);
              })
            }
          </Calendar>
          <ActionButton onPress={onPress}>
            <ActionButtonText>{i18n.t('general_continue')}</ActionButtonText>
          </ActionButton>
        </ContentWrapper>
      </Container>
    );
  }
  return null;
};

export default DailyReward;

const Container = styled(View)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%; 
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
`;

const { width: screenWidth } = Dimensions.get('window');

const ContentWrapper = styled(View)`
  width: ${screenWidth * 0.8};
  max-width: 350;
  height: ${screenWidth * 0.8 * 1.189};
  max-height: 415;
  border-radius: 24;
  background-color: #FFFFFF;
  align-items: center;
  justify-content: space-evenly;
`;

const Title = styled(Text)`
  font-size: 16;
  font-weight: bold;
  line-height: 19;
  align-self: center;
`;

const GiftImage = styled(Image)`
  width: 85;
  height: 115;
`;

const ActionButton = styled(TouchableOpacity)`
  width: 62%;
  max-width: 220;
  height: 49;
  border-radius: 24;
  justify-content: center;
  align-items: center;
  background-color: ${path(['theme', 'primary', 'purple'])};
`;

const ActionButtonText = styled(Text)`
  color: #FFFFFF;
  font-size: 18;
`;

const DATES = [
  new Date(new Date().setDate(new Date().getDate() - 2)),
  new Date(new Date().setDate(new Date().getDate() - 1)),
  new Date(),
  new Date(new Date().setDate(new Date().getDate() + 1)),
  new Date(new Date().setDate(new Date().getDate() + 2)),
];

const Calendar = styled(View)`
  width: 100%;
  height: 82;
  flex-direction: row;
  justify-content: space-evenly;
`;

const CalendarDateUnit = ({ date }) => {
  const today = new Date();
  const isToday = date.getDate() === today.getDate();
  const isFuture = date > today;
  return (
    <CalendarDateUnitWrapper isToday={isToday}>
      <CalendarDateUnitMonthText>{date?.getMonth() + 1}</CalendarDateUnitMonthText>
      <CalendarDateUnitDateText>{date?.getDate()}</CalendarDateUnitDateText>
      {
        isFuture && <LockImage source={require('assets/images/rewardlock.png')} />
      }
    </CalendarDateUnitWrapper>
  );
};

const CalendarDateUnitWrapper = styled(View)`
  height: 82;
  width: 17.5%;
  border-width: ${props => props.isToday ? 3 : 1};
  border-color: ${props => props.isToday ? path(['theme', 'primary', 'purple']) : '#CDCDCDCD'};
  border-radius: 12;
  justify-content: center;
  align-items: center;
`;

const CalendarDateUnitMonthText = styled(Text)`
  color: #000000;
  font-size: 18;
  font-weight: 500;
`;

const CalendarDateUnitDateText = styled(Text)`
  color: #000000;
  font-size: 20;
  font-weight: bold;
`;

const LockImage = styled(Image)`
  width: 16;
  height: 16;
  position: absolute;
  top: -8;
  left: 50%;
  margin-left: -8;
`;

