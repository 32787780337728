import * as React from 'react';
import { path } from 'ramda';
import {
  Text,
  View,
  Linking,
  Image,
  Dimensions,
  AsyncStorage,
  TouchableOpacity,
} from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import delay from 'delay';
import styled from 'styled-components/native';

import Colors from 'constants/Colors';
import { redeemStars } from 'actions/userActions';
import { showFullScreenAd } from 'components/Ads';
import Alert from 'components/Alert';
import SegmentedControl from 'components/RedeemScreen/SegmentedControl';

const getListContents = (countryCode = 'US', type = 'follower') => {
  const contents = [
    { amount: 5, stars: 10, bonusAmount: 20 },
    { amount: 10, stars: 20, bonusAmount: 30 },
    { amount: 15, stars: 30, bonusAmount: 35 },
    { amount: 20, stars: 40, bonusAmount: 60 },
    { amount: 25, stars: 50, bonusAmount: 90 },
    { amount: 37, stars: 75, bonusAmount: 110 },
    { amount: 50, stars: 100, bonusAmount: 130 },
    { amount: 75, stars: 150, bonusAmount: 160 },
    { amount: 100, stars: 200, bonusAmount: 200 },
    { amount: 200, stars: 400, bonusAmount: 400 },
    { amount: 300, stars: 600, bonusAmount: 600 },
    { amount: 400, stars: 800, bonusAmount: 800 },
    { amount: 500, stars: 1000, bonusAmount: 1000 },
    { amount: 1000, stars: 2000, bonusAmount: 2000 },
    { amount: 2000, stars: 4000, bonusAmount: 4000 },
    { amount: 3500, stars: 6000, bonusAmount: 7000 },
  ];

  const commentContents = [
    { amount: 5, stars: 20, bonusAmount: 20 },
    { amount: 10, stars: 40, bonusAmount: 30 },
    { amount: 15, stars: 60, bonusAmount: 35 },
    { amount: 20, stars: 80, bonusAmount: 60 },
    { amount: 25, stars: 100, bonusAmount: 90 },
  ];

  if (type === 'comment') {
    return commentContents;
  }
  return contents;
};


const RedeemContentList = ({ type, selectedItem = {}, setIsLoading }) => {
  const countryCode = useSelector(state => state.user?.countryCode);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const currentType = type === 'follower' ? 'follower' : (selectedTab === 0 ? 'like' : 'comment');
  const listContents = getListContents(countryCode, currentType);

  return (
    <View style={{ width: '100%', alignItems: 'center' }}>
      {
        currentType !== 'follower' &&
        <SegmentedControl
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          values={[
            () => <ItemNameImage source={require('assets/images/getlikes.png')} />,
            () => <FontAwesome name="commenting-o" size={22} color="black" />
          ]}
        />
      }
      {
        listContents.map((content, index) => (
          <ListItem
            key={index}
            type={currentType}
            selectedItem={selectedItem}
            content={content}
            setIsLoading={setIsLoading}
          />
        ))
      }
    </View>
  );
};

export default RedeemContentList;

const { width: screenWidth } = Dimensions.get('window');

const ListItemContainer = styled(View)`
  height: 60;
  width: ${screenWidth - 56};
  max-width: 358;
  background-color: #FFFFFF;
  margin-top: 20;
  border-radius: 12;
  justify-content: space-between;
  padding-left: 18;
  padding-right: 18;
  align-items: center;
  flex-direction: row;
`;

const ItemNameText = styled(Text)`
  font-size: 18;
  font-weight: 700;
  letter-spacing: 0.21;
  line-height: 25;
  color: #000000;
`;

const StarsNumberText = styled(ItemNameText)`
  color: ${props => path(['theme', 'primary', 'purple'])};
`;

const ItemNameImage = styled(Image)`
  width: 24;
  height: 24;
`;

const ItemName = ({ type, amount }) => {
  const ItemIcon = () => {
    if (type === 'follower') {
      return <ItemNameImage source={require('assets/images/getfans.png')} />
    } else if (type == 'like') {
      return <ItemNameImage source={require('assets/images/getlikes.png')} />
    }
    return <Text>{" "}<FontAwesome name="commenting-o" size={24} color="black" /></Text>
  }

  return (
    // IS_REVIEWING
    // <View style={{ flexDirection: 'row' }}>
    //   <ItemNameText style={{ fontSize: 16 }}>Highlight for</ItemNameText>
    //   <Text>{" "}</Text>
    //   <StarsNumberText>{amount}</StarsNumberText>
    //   <Text>{" "}</Text>
    //   <ItemNameText style={{ fontSize: 16 }}>Days</ItemNameText>
    // </View>
    <View style={{ flexDirection: 'row' }}>
      <StarsNumberText>+</StarsNumberText>
      <Text>{" "}</Text>
      <StarsNumberText>{amount}</StarsNumberText>
      <Text>{" "}</Text>
      {/* <ItemNameText>{type}</ItemNameText> */}
      {ItemIcon()}
    </View>
  );
};

const ItemButtonWrapper = styled(TouchableOpacity)`
  width: 100;
  height: 32;
  border-radius: 8;
  background-color: ${props => path(['theme', 'primary', 'purple'])};
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const ItemButtonText = styled(Text)`
  color: #FFFFFF;
  font-size: 16;
  font-weight: 700;
  line-height: 19;
  margin-right: 5;
`;

const ItemButton = ({ stars, onPress, disabled }) => (
  <ItemButtonWrapper onPress={onPress} disabled={disabled}>
    <ItemButtonText>
      {`- ${stars}`}
    </ItemButtonText>
    <Image style={{ width: 16, height: 16 }} source={require('assets/images/star.png')} />
  </ItemButtonWrapper>
);

// AsyncStorage.removeItem('@promotionCount');

const promotionAlert = async () => {
  try {
    const promotionCount = await AsyncStorage.getItem('@promotionCount') ?? 0;
    if (parseInt(promotionCount) > 1) {
      return;
    }

    const onConfirm = async () => {
      const url = 'instagram://media?id=2289232146745005007_32721043111';
      const supported = await Linking.canOpenURL(url);
      if (supported) {
        await Linking.openURL(url);
      }
    };

    await AsyncStorage.setItem('@promotionCount', JSON.stringify(parseInt(promotionCount) + 1));
    Alert({
      title: '🎁 FREE 100 stars giveaway',
      body: 'Check out TikFans Instagram, we send out 100 stars⭐ to 10 lucky winners everyday',
      actions: [
        {
          text: '🙅NO', onPress: undefined,
        },
        {
          text: '😍YES', onPress: () => onConfirm(),
        },
      ],
      onConfirm: () => onConfirm(),
    });
  } catch (error) {
    if (__DEV__) {
      console.log('promotionAlert', error);
    }
  }
};

const ListItem = ({ type, selectedItem, content, setIsLoading }) => {
  const dispatch = useDispatch();
  const [disableRedeem, setDisabledRedeem] = React.useState(false);
  const currentStars = useSelector(state => state.user?.stars ?? 0);
  const uniqueId = useSelector(state => state.user?.tiktok?.uniqueId);
  const isSecret = useSelector(state => state.user?.tiktok?.isSecret);
  const hasPurchased = useSelector(state => state.user?.hasPurchased);
  const countryCode = useSelector(state => state.user?.countryCode);
  const navigation = useNavigation();
  const onPress = React.useCallback(() => {
    const onConfirm = async () => {
      setIsLoading(true);
      setDisabledRedeem(true);
      const orderCount = hasPurchased ? content.bonusAmount : content.amount;
      const adjustedOrderCount = countryCode === 'IN' ? Math.floor(orderCount * 0.9) : orderCount;
      if (adjustedOrderCount <= 0) {
        setIsLoading(false);
        setDisabledRedeem(false);
        return;
      }

      const isSuccessful = await dispatch(redeemStars({ type, selectedItem, stars: content.stars, orderCount: adjustedOrderCount }))
      setIsLoading(false);
      setDisabledRedeem(false);
      if (isSuccessful) {
        Alert({
          title: '💜Success💜',
          body: 'Congrats🎉, it usually takes couple minutes to hours. WARNING🚫: DO NOT TURN YOUR ACCOUNT TO "PRIVATE"',
          // IS_REVIEWING
          // body: '🎉🎉🎉🎉',
          actions: [{
            text: 'OK', onPress: async () => {
              if (!hasPurchased) {
                // showFullScreenAd();
                await delay(1800);
                // promotionAlert();
              }
            }
          }],
          // onConfirm: () => promotionAlert(),
        });
      } else {
        Alert({
          title: 'Please Try Again :(',
          body: 'Something went wrong, please try again',
          actions: [{ text: 'OK', onPress: undefined }],
        });
      }
    };

    if (!uniqueId) {
      Alert({
        title: 'Please Login Again😢',
        body: 'Something went wrong ',
        actions: [{
          text: 'OK', onPress: () => {
            navigation.navigate('Login')
          }
        }],
        onConfirm: () => navigation.navigate('Login'),
      })
      return;
    }

    if (isSecret) {
      Alert({
        title: 'Your account is private, Please set it to public to continue😢',
        actions: [{ text: 'OK', onPress: undefined }],
      })
      return;
    }

    if (currentStars >= content.stars && content.stars >= content.amount && content.amount > 0) {
      onConfirm();
      // Alert({
      //   title: `Use ${content.stars} Stars?`,
      //   body: `You will use ${content.stars} stars`,
      //   // IS_REVIEWING
      //   // body: 'Your profile and videos will show on home page',
      //   actions: [
      //     { text: 'Cancel', onPress: undefined },
      //     { text: 'Confirm', onPress: onConfirm },
      //   ],
      //   onConfirm: () => onConfirm(),
      // })
    } else {
      Alert({
        title: `You don't have enough stars 😢`,
        body: 'Win more stars 💪💪💪',
        actions: [{ text: 'OK', onPress: undefined }],
      })
    }
  }, [currentStars, hasPurchased, type]);
  return (
    <ListItemContainer>
      <ItemName
        type={type}
        amount={content.amount}
      />
      <ItemButton
        stars={content.stars}
        onPress={onPress}
        disabled={disableRedeem}
      />
    </ListItemContainer>
  );
};
