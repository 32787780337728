import * as React from 'react';
import { path } from 'ramda';
import {
  Platform,
  View,
  Text,
  Image,
  TextInput,
  TouchableOpacity,
  Modal as NativeModal,
  ActivityIndicator,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import styled from 'styled-components/native';
import WebModal from 'modal-enhanced-react-native-web';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as TikTokApi from 'apis/tiktok';
import { getUserProfileInfoAction } from 'actions/userActions';
import Alert from 'components/Alert';

const Modal = Platform.select({
  web: WebModal,
  default: NativeModal,
});

const HelpModal = ({ visible, setIsHelpModalOpen }) => {
  const [shareUrl, setShareUrl] = React.useState('');
  const isFetchingUser = useSelector(state => state.user?.isFetchingUser);
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const onPressContinueButton = async () => {
    const username = await TikTokApi.getUsernameFromShareLink(shareUrl);
    if (!username || username?.length === 0 || username === '__iamgjheiroumei__') {
      Alert({
        title: 'Username not found',
        body: `@${username} does not exist. Please confirm your username again.`,
        actions: [{ text: 'OK', onPress: undefined }],
      });
      return;
    }
    const userSuccessfullyFetched = await dispatch(getUserProfileInfoAction({ username, isLogin: true }));
    if (!userSuccessfullyFetched) {
      Alert({
        title: 'Username not found',
        body: `@${username} does not exist. Please confirm your username again.`,
        actions: [{ text: 'OK', onPress: undefined }],
      });
      return;
    }
    setIsHelpModalOpen(false);
    navigation.navigate('Tutorial');
  };

  return (
    <Modal
      visible={visible}
      transparent={true}
      style={{ margin: 0 }}
    >
      {
        isFetchingUser && <StyledActivityIndicator size="large" color="black" />
      }
      <HelpModalWrapper
        keyboardShouldPersistTaps="handled"
        enableOnAndroid={true}
        removeClippedSubviews={false}
        contentContainerStyle={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
      >
        <HelpModalBody>
          <HelpModalImage source={require('assets/images/findaccountpic2.png')} />
          <HelpModalBottomArea>
            {/* <HelpModalBottomAreaInstruction>
              Go to TikTok app > Go to your Profile > Tap “…” icon on the top right > Tap “Share Profile” > Tap "Copy Link" => paste it below
            </HelpModalBottomAreaInstruction>
            <StyledTextInput
              placeholder="https://vt.tiktok.com/XXXXXX"
              selectTextOnFocus={true}
              value={shareUrl}
              onChangeText={text => setShareUrl(text)}
            /> */}
            <HelpButtonsWrapper>
              {/* <HelpModalButton
                isCancel={true}
                onPress={() => setIsHelpModalOpen(false)}
              >
                <ButtonText>Cancel</ButtonText>
              </HelpModalButton> */}
              {/* <HelpModalButton
                onPress={onPressContinueButton}
              >
                <ButtonText>Continue</ButtonText>
              </HelpModalButton> */}
              <HelpModalButton
                onPress={() => setIsHelpModalOpen(false)}
              >
                <ButtonText>OK</ButtonText>
              </HelpModalButton>
            </HelpButtonsWrapper>
          </HelpModalBottomArea>
        </HelpModalBody>
      </HelpModalWrapper>
    </Modal>
  );
};

export default HelpModal;

const StyledTextInput = styled(TextInput)`
  width: 100%;
  height: 24;
  border-bottom-width: 0.5;
  margin-top: 20;
  font-size: 18;
  padding-left: 20;
  font-weight: bold;
  padding-bottom: 5;
  color: #000000;
`;

const HelpModalWrapper = styled(KeyboardAwareScrollView)`
  flex: 1;
  /* justify-content: center; */
  /* align-items: center; */
  background-color: rgba(0, 0, 0, 0.3);
`;

const HelpModalBody = styled(View)`
  width: 90%;
  height: 387;
  max-width: 370;
  background-color: #FFFF;
  border-radius: 24px;
  overflow: hidden;
  shadow-opacity: 0.65;
  shadow-radius: 5px;
  shadow-color: black;
  shadow-offset: 0px 0px;
`;

const HelpModalImage = styled(Image)`
  width: 100%;
  height: 46%;
  background-color: #8765;
`;

const HelpModalBottomArea = styled(View)`
  width: 100%;
  height: 54%;
  padding: 22px;
`;

const HelpModalBottomAreaInstruction = styled(Text)`
  font-size: 14;
  font-weight: 500;
  line-height: 17;
`;

const HelpButtonsWrapper = styled(View)`
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 10;
`;

const HelpModalButton = styled(TouchableOpacity)`
  width: 124;
  height: 49;
  border-radius: 24;
  margin-top: 16;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.isCancel ? path(['theme', 'primary', 'gray']) : path(['theme', 'primary', 'purple'])}
`;

const ButtonText = styled(Text)`
  font-size: 18;
  font-weight: bold;
  color: #FFFF;
`;

const StyledActivityIndicator = styled(ActivityIndicator)`
  position: absolute;
  margin: auto;
  top: 0; 
  left: 0; 
  bottom: 0; 
  right: 0;
  z-index: 99;
`;
