// @format
import * as time from './time.js';
import * as firebase from 'apis/firebase';
import * as tiktok from 'apis/tiktok';
import * as slack from 'apis/slack';

export default {
  time,
  firebase,
  tiktok,
  slack,
};
