// @format
import { combineReducers } from 'redux';
import timeReducers from './timeReducers';
import userReducers from './userReducers';
import paymentReducers from './paymentReducers';

const rootReducer = combineReducers({
  time: timeReducers,
  user: userReducers,
  payment: paymentReducers,
});

export default rootReducer;
