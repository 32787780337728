import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { path } from 'ramda';
import { shuffle } from 'lodash';
import {
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  Linking,
  Platform,
  Modal,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import * as StoreReview from 'expo-store-review';
import Constants from 'expo-constants';
import styled from 'styled-components/native';
import * as MailComposer from 'expo-mail-composer';
import dedent from 'dedent';
import useAppState from 'react-native-appstate-hook';
import { WebView } from 'react-native-webview';

import Colors from 'constants/Colors';
import ProfileCard from 'components/ProfileCard';
import Alert from 'components/Alert';
import Header from 'components/Header/Header';
import CloseButton from 'components/Header/CloseButton';
import { rewardReviewAction, rewardFollowIgAction } from 'actions/userActions';
import { AdBanner } from 'components/Ads';
import InviteModal, {
  onPressShare,
} from 'components/SettingsScreen/InviteModal';
import PromoCodeModal from 'components/SettingsScreen/PromoCodeModal';
// import TSAdBanner from 'components/Ads/TSAdBanner';
import { TSAdBanner } from 'ts-ad-modules';
import * as slack from 'apis/slack';
import i18n from 'i18n';
import { BANNER_LIST } from 'constants/TsadConfig';

const bannerConfig = _.shuffle(BANNER_LIST)[0];

const VersionText = styled(Text)`
  margin-top: 10px;
  color: ${path(['theme', 'primary', 'gray'])};
`;

const onTapPromotion = async (user) => {
  slack.tapPromotion(user);
  const url = 'https://www.instagram.com/p/B_SkhNTpeck/?igshid=l9o20t74xxwz';
  const supported = await Linking.canOpenURL(url);
  if (supported) {
    await Linking.openURL(url);
  }
};

const positiveReviews = [
  {
    author: 'Beatriz Segovia',
    url:
      'https://play.google.com/store/apps/details?id=com.tikfans.www&hl=en_US&reviewId=gp%3AAOqpTOHWQFTbE622BXN0YnzZ7g04w-booQcSBPw76ldosKROHn-qoUrZoxTEWWPhC8EAsZJ7_K3Llu672BLHrKI',
  },
];

const SettingsScreen = ({ navigation }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [wentToReview, setWentToReview] = React.useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = React.useState(false);
  const [isPromoCodeModalOpen, setIsPromoCodeModalOpen] = React.useState(false);
  const [isReviewModalVisible, setIsReviewModalVisible] = React.useState(false);
  const hasReviewed = user?.hasReviewed ?? false;
  const hasFollowedIg = user?.hasFollowedIg ?? false;
  const hasRedeemedPromoCode = user?.hasRedeemedPromoCode ?? false;
  const hasPurchased = user?.hasPurchased ?? false;
  const canRedeemPromoCode = user?.canRedeemPromoCode;
  const countryCode = user?.countryCode;
  const positiveReview = shuffle(positiveReviews)[0];
  const shouldLinkToReview = Math.random() < 0;
  const reviewTitle = shouldLinkToReview
    ? `👍 ${i18n.t('settings_review_title_upvote')}`
    : i18n.t('settings_review_title_rate');
  const reviewIntroText = shouldLinkToReview
    ? i18n.t('settings_review_text_upvote')
    : i18n.t('settings_review_text_rate');

  useAppState({
    onForeground: async () => {
      if (wentToReview) {
        dispatch(rewardReviewAction());
        setWentToReview(false);
      }
    },
  });

  return (
    <StyledScrollView>
      <ProfileCard />
      {/* <TSAdBanner
        style={{
          marginTop: 10,
          maxWidth: '90%',
          alignSelf: 'center',
          backgroundColor: '#FFFFFF',
        }}
        {...bannerConfig}
      /> */}
      {/* <AdBanner style={{ marginTop: 15 }} /> */}
      {/* {
        <ListItem
          title="⭐⭐ Get 100 Free Stars ⭐⭐"
          onPress={() => {
            Alert({
              title: 'Share TikFans on Instagram 💜💜',
              body:
                'Share our Instagram post to your story, every 2 viewers = 1 star ⭐, go check it out',
              actions: [
                {
                  text: 'OK',
                  onPress: () => {
                    onTapPromotion(user);
                  },
                },
              ],
              onConfirm: () => onTapPromotion(user),
            });
          }}
        />
      } */}
      {!(countryCode === 'IN' || countryCode === 'PK' || countryCode === 'VN' || countryCode === 'IQ' || countryCode === 'PH') && (
        <ListItem
          title={`+20 ⭐⭐⭐ ${i18n.t('settings_free_stars')}`}
          onPress={() => {
            setIsInviteModalOpen(true);
            slack.tapPromotion(user);
          }}
        />
      )}
      {/* {!hasReviewed && Platform.OS === 'web' && Math.random < 0.20 && (
        <ListItem
          title={`+10 ⭐⭐⭐ ${i18n.t('settings_free_stars')}`}
          onPress={() => {
            const url =
              'https://itunes.apple.com/app/id1511867825?action=write-review';
            Alert({
              // title: 'Rate us 5 stars',
              body: i18n.t('settings_review_text_rate'),
              actions: [
                {
                  text: 'OK',
                  onPress: async () => {
                    setWentToReview(true);
                    const supported = await Linking.canOpenURL(url);
                    if (supported) {
                      Linking.openURL(url);
                    }
                  },
                },
              ],
              onConfirm: async () => {
                setWentToReview(true);
                const supported = await Linking.canOpenURL(url);
                if (supported) {
                  Linking.openURL(url);
                }
              },
            });
          }}
        />
      )} */}
      {!hasFollowedIg && Math.random() < 0.01 && (
        <ListItem
          title={`+10 ⭐⭐⭐ ${i18n.t('settings_free_stars')}`}
          color="#9400D3"
          onPress={() => {
            Alert({
              // title: reviewTitle,
              body: i18n.t('settings_review_follow_ig'),
              actions: [
                {
                  text: 'OK',
                  onPress: async () => {
                    const url =
                      Platform.OS === 'web'
                        ? 'https://www.instagram.com/tikfans.app/'
                        : 'instagram://user?username=tikfans.app';
                    const supported = await Linking.canOpenURL(url);
                    if (supported) {
                      Linking.openURL(url);
                      dispatch(rewardFollowIgAction());
                    }
                  },
                },
              ],
              onConfirm: async () => {
                const url =
                  Platform.OS === 'web'
                    ? 'https://www.instagram.com/tikfans.app/'
                    : 'instagram://user?username=tikfans.app';
                const supported = await Linking.canOpenURL(url);
                if (supported) {
                  Linking.openURL(url);
                  dispatch(rewardFollowIgAction());
                }
              },
            });
          }}
        />
      )}
      {/* {
        Platform.OS === 'web' &&
        <ListItem
          title="𝒞𝑜𝑜𝓁 🅕🅞🅝🅣🅢 𝔸𝕡𝕡 ⌨️ 🔠"
          color="#000000"
          onPress={async () => {
            const url = 'https://itunes.apple.com/app/id1497287140';
            const supported = await Linking.canOpenURL(url);
            if (supported) {
              Linking.openURL(url);
            }
          }}
        />
      } */}
      {!(countryCode === 'IN' || countryCode === 'PK' || countryCode === 'VN' || countryCode === 'IQ' || countryCode === 'PH') && canRedeemPromoCode && !hasRedeemedPromoCode && (
        <ListItem
          title={i18n.t('settings_promo_code')}
          onPress={() => {
            setIsPromoCodeModalOpen(true);
          }}
        />
      )}
      <ListItem
        title={`${i18n.t('share_app')} 🔗`}
        onPress={async () => {
          onPressShare(user?.tiktok?.uniqueId);
        }}
      />
      {((countryCode !== 'IN' && countryCode !== 'NP') || hasPurchased) && (
        <ListItem
          title={i18n.t('settings_contact_us')}
          onPress={() => {
            const options = {
              recipients: ['ttapp.service@gmail.com'],
              subject: 'TikFans issue',
              body:
                '\n\n\n\n\n\n\n' +
                dedent`
                        ------ Don't delete infos below ------
                        user: ${user?.uid}
                        platform: ${user?.platform}
                        country: ${user?.countryCode}
                        uniqueId: ${user?.tiktok?.uniqueId}
                        version: ${process.env?.APP_MANIFEST?.version || Constants.manifest.version}
                        p: ${user?.hasPurchased}`,
            };
            MailComposer.composeAsync(options);
          }}
        />
      )}
      {/* <ListItem
        title="Instagram"
        onPress={async () => {
          const url =
            Platform.OS === 'web'
              ? 'https://www.instagram.com/tikfans.app/'
              : 'instagram://user?username=tikfans.app';
          const supported = await Linking.canOpenURL(url);
          if (supported) {
            await Linking.openURL(url);
          }
        }}
      /> */}
      <ListItem
        title={"Policy and Terms"}
        onPress={() => {
          Linking.openURL('https://get-more-ttfans.webflow.io/terms-of-use-policy');
        }}
      />
      {!(
        user?.hasRedeemedIOSFirstLoginReward === true &&
        user?.hasRedeemedIOSFirstLoginRewardUsed === false
      ) && (
          <ListItem
            title={i18n.t('settings_log_out')}
            onPress={() => {
              navigation.navigate('Login');
            }}
          />
        )}
      <VersionText>
        Current version:{' '}
        {process.env?.APP_MANIFEST?.version || Constants.manifest.version}
      </VersionText>
      <InviteModal
        visible={isInviteModalOpen}
        setIsInviteModalOpen={setIsInviteModalOpen}
      />
      <PromoCodeModal
        visible={isPromoCodeModalOpen}
        setIsPromoCodeModalOpen={setIsPromoCodeModalOpen}
      />
      {/* {
        Platform.OS !== 'web' &&
        <Modal
          visible={isReviewModalVisible}
        >
          <View style={{
            flex: 1,
            backgroundColor: '#F00111',
            animationType: 'slide',
          }}>
            <StyledHeader>
              <LeftWrapper>
                <CloseButton
                  onPress={() => {
                    setIsReviewModalVisible(false);
                  }}
                />
              </LeftWrapper>
              <HeaderTitle>{`Upvote 👍${positiveReview?.author}'s review`}</HeaderTitle>
            </StyledHeader>
            <WebView
              source={{
                uri: positiveReview?.url,
              }}
            />
          </View>
        </Modal>
      } */}
    </StyledScrollView>
  );
};

export default SettingsScreen;

const { width: screenWidth } = Dimensions.get('window');

const StyledScrollView = styled(ScrollView).attrs({
  flex: 1,
  backgroundColor: Colors.primary?.lightBlue,
  contentContainerStyle: {
    backgroundColor: Colors.primary?.lightBlue,
    alignItems: 'center',
    paddingBottom: 100,
  },
})``;

const ListItemContainer = styled(TouchableOpacity)`
  height: 55;
  width: ${screenWidth - 56};
  max-width: 358;
  background-color: #ffffff;
  margin-top: 20;
  border-radius: 12;
  justify-content: space-between;
  padding-left: 18;
  padding-right: 18;
  align-items: center;
  flex-direction: row;
`;

const ItemNameText = styled(Text)`
  font-size: 18;
  font-weight: 700;
  letter-spacing: 0.21;
  line-height: 25;
  color: ${(props) => props.color ?? path(['theme', 'primary', 'purple'])};
`;

const ListItem = ({ title, onPress, color }) => {
  return (
    <ListItemContainer onPress={onPress}>
      <ItemNameText color={color}>{title}</ItemNameText>
    </ListItemContainer>
  );
};

const StyledHeader = styled(Header)`
  justify-content: center;
  position: relative;
  background-color: transparent;
  align-items: center;
  /* max-width: none; */
`;

const LeftWrapper = styled(View)`
  position: absolute;
  left: 10;
  top: 0;
  bottom: 0;
  justify-content: center;
`;

const HeaderTitle = styled(Text)`
  font-size: 20;
  line-height: 24;
  /* color: ${path(['theme', 'primary', 'dark'])}; */
  color: #ffffff;
  font-weight: bold;
  letter-spacing: -0.48;
  max-width: 88%;
`;
