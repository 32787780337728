import * as React from 'react';
import { path } from 'ramda';
import { isEmpty } from 'lodash';
import {
  View,
  TextInput,
  Image,
  Text,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useNavigation } from '@react-navigation/native';
import styled from 'styled-components/native';
import { Entypo } from '@expo/vector-icons';

import { getVideoInfoFromLink } from 'apis/tiktok';
import Alert from 'components/Alert';
import Colors from 'constants/Colors';
import i18n from 'i18n';

const UploadVideoFromLink = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  return (
    <StyledScrollView>
      {isLoading && <StyledActivityIndicator size="large" color="#000000" />}
      <TutorialImage source={require('assets/images/addvideo_step1.png')} />
      <Paragraph number={1}>
        <ParaText>{i18n.t('upload_video_step_1')}</ParaText>
      </Paragraph>
      <TutorialImage source={require('assets/images/addvideo_step2.png')} />
      <Paragraph number={2}>
        <ParaText>{i18n.t('upload_video_step_2')}</ParaText>
      </Paragraph>
      <VideoLinkArea isLoading={isLoading} setIsLoading={setIsLoading} />
    </StyledScrollView>
  );
};

export default UploadVideoFromLink;

const StyledScrollView = styled(ScrollView).attrs({
  flex: 1,
  backgroundColor: '#FFFFFF',
  width: '100%',
  contentContainerStyle: {
    backgroundColor: '#FFFFFF',
    alignItems: 'flex-start',
    width: '100%',
    maxWidth: 500,
    padding: 16,
    alignSelf: 'center',
  },
})``;

const StyledActivityIndicator = styled(ActivityIndicator)`
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
`;

const VideoLinkAreaWrapper = styled(View)`
  margin-top: 15;
  width: 100%;
  margin-bottom: 20;
`;

const VideoLinkTitle = styled(Text)`
  font-size: 16;
  font-weight: bold;
  margin-bottom: 12;
  margin-left: 4;
`;

const VideoLinkArea = ({ isLoading, setIsLoading }) => {
  const navigation = useNavigation();
  const [url, setUrl] = React.useState('');
  const onPress = async ({ url }) => {
    console.log('url', url.length);
    if (url?.length <= 0 || isLoading) {
      return;
    }
    setIsLoading(true);
    const item = await getVideoInfoFromLink({ url });
    setIsLoading(false);
    if (isEmpty(item)) {
      Alert({
        title: '⚠️',
        body: `⚠️ ${i18n.t('upload_video_alert')} `,
        actions: [{ text: 'OK', onPress: undefined }],
      });
      return;
    }
    navigation.navigate('RedeemLikesConfirm', { item });
  };
  return (
    <VideoLinkAreaWrapper>
      <VideoLinkTitle>{i18n.t('upload_video_link')}:</VideoLinkTitle>
      <StyledTextInput
        value={url}
        onChangeText={(text) => setUrl(text)}
        autoCapitalize="none"
        placeholder="https://vt.tiktok.com/AhYhKC/"
      />
      <ConfirmButton onPress={() => onPress({ url })}>
        <Entypo name="direction" size={30} color="white" />
      </ConfirmButton>
    </VideoLinkAreaWrapper>
  );
};

const StyledTextInput = styled(TextInput)`
  width: 100%;
  height: 60;
  border-color: #000000;
  border-width: 1;
  align-self: center;
  border-radius: 5;
  padding-left: 20;
  padding-right: 20;
  font-size: 20;
`;

const ConfirmButton = styled(TouchableOpacity)`
  width: 85%;
  height: 49;
  margin-top: 16;
  margin-bottom: 16;
  background-color: ${path(['theme', 'primary', 'purple'])};
  align-self: center;
  border-radius: 12;
  justify-content: center;
  align-items: center;
`;

const TutorialImage = styled(Image)`
  width: 100%;
  height: 209;
`;

const ParaText = styled(Text)`
  font-size: 16;
  font-weight: 500;
  line-height: 19;
  margin-left: 14;
  max-width: 80%;
`;

const ListNumber = ({ number }) => (
  <View
    style={{
      width: 24,
      height: 24,
      borderRadius: 12,
      backgroundColor: Colors.primary.purple,
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Text style={{ color: 'white' }}>{number}</Text>
  </View>
);

const Paragraph = ({ number, children }) => (
  <View
    style={{
      flexDirection: 'row',
      marginTop: 16,
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <ListNumber number={number} />
    {children}
  </View>
);
