// @format
import PropTypes from 'prop-types';
import { path, pathEq, is } from 'ramda';
import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  View,
  Text,
  Image,
  ActivityIndicator,
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import styled from 'styled-components/native';
import { FontAwesome5 } from '@expo/vector-icons';
import Swal from 'sweetalert2';

import CloseButton from 'components/Header/CloseButton';
import Header from 'components/Header/Header';
import Colors from 'constants/Colors';
import { IAP_PRODUCTS } from 'constants/Products';
import ScriptId from 'constants/ScriptId';
import { extractPrice } from 'modules/payment/utils/paymentUtils';
import WithPayPalButton from 'modules/paypal/components/WithPayPalButton';
import * as ApplePay from 'modules/ApplePay';
import { newPayPalEvent } from 'apis/slack';
import { createTransactionRecordAction, updateShowLoginScreenCount } from 'actions/userActions';
import WithTapPayCreditCardButton from 'modules/tappay/WithTapPayCreditCardButton';
import AppleLoginModal from 'screens/AppleLoginModal';
import i18n from 'i18n';

const StyledActivityIndicator = styled(ActivityIndicator)`
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
`;

const StyledHeader = styled(Header)`
  justify-content: center;
  position: relative;
  background-color: transparent;
  max-width: none;
`;

const Wrapper = styled(SafeAreaView)`
  flex: 1;
`;

const LeftWrapper = styled(View)`
  position: absolute;
  left: 10;
  top: 0;
  bottom: 0;
  justify-content: center;
`;

const HeaderTitle = styled(Text)`
  font-size: 20;
  line-height: 24;
  color: ${path(['theme', 'primary', 'dark'])};
  font-weight: bold;
  letter-spacing: -0.48;
`;

const Body = styled(ScrollView)`
  flex: 1;
  padding-horizontal: 18;
  padding-vertical: 15;
`;

const OrderLabel = styled(Text)`
  font-size: 18;
  line-height: 21.5;
  font-weight: bold;
  color: ${path(['theme', 'primary', 'purple'])};
`;

const OrderItemWrapper = styled(View)`
  padding-horizontal: 18;
  padding-vertical: 24;
  margin-left: -18;
  margin-right: -18;
  display: flex;
  flex-direction: row;
  background: white;
  justify-content: space-between;
`;

const OrderItemText = styled(Text)`
  font-size: 18;
  line-height: 21.5;
  font-weight: 500;
  letter-spacing: 0;
  color: ${path(['theme', 'primary', 'dark'])};
  flex: 1;
`;

const OrderPriceText = styled(OrderItemText)`
  color: ${path(['theme', 'primary', 'lightPink'])};
  font-weight: bold;
  flex: none;
  align-self: flex-end;
`;

const OrderItem = ({ style, stars, price, type, isDirectPurchase }) => {
  const orderCount = isDirectPurchase ? stars / 2.0 : stars;
  const iconSource = isDirectPurchase
    ? type === 'like'
      ? require('assets/images/getlikes.png')
      : require('assets/images/getfans.png')
    : require('assets/images/star.png');

  return (
    <OrderItemWrapper style={style}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <OrderItemText>{`+${orderCount}`}</OrderItemText>
        <Image style={{ width: 18, height: 18, marginLeft: 5 }} source={iconSource} />
      </View>
      <OrderPriceText>{price}</OrderPriceText>
    </OrderItemWrapper>
  );
};
const SelectPaymentWrapper = styled(View)`
  margin-top: 70;
  padding-horizontal: 10px;
`;

const ApplePayButton = styled(TouchableOpacity)`
  width: 100%;
  height: 45;
  background-color: #000000;
  border-radius: 2;
  margin-bottom: 15;
  justify-content: center;
  align-items: center;
`;

OrderItem.propTypes = {
  style: PropTypes.object,
  stars: PropTypes.number,
  price: PropTypes.string,
};

const SelectPaymentScreen = ({ route, navigation }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(state => state.user);
  const applePay = useSelector(state => state.payment?.applePay);
  const countryCode = useSelector(state => state.user?.countryCode) || 'TW';
  // const shouldHideApplePay = ['US', 'CA', 'AU', 'NZ'].indexOf(countryCode.toUpperCase()) === -1;
  // const shouldShowApplePay = countryCode.toUpperCase() === 'TW';
  const [appleLoginModalStatus, setAppleLoginModalStatus] = React.useState({
    visible: false,
    type: 'default',
  });
  const shouldShowApplePay = false;
  const { type, isDirectPurchase } = route.params;

  const onPress = useCallback(() => {
    setIsLoading(false);
    navigation.goBack();
  }, []);

  const ShowSuccessPopUp = () => {
    Swal.fire({
      icon: 'success',
      title: `+ ${IAP_PRODUCTS[route?.params?.productId]?.stars}⭐`,
    }).then(() => {
      if (!user?.appleId && user?.showLoginCount < 3) {
        setAppleLoginModalStatus({
          visible: true,
          type: 'default',
          nextRoute: 'Back',
        });
        dispatch(updateShowLoginScreenCount());
      } else {
        navigation.goBack();
      }
    });
  };

  return (
    <Wrapper>
      {isLoading && <StyledActivityIndicator size="large" color={Colors.primary.lightGray} />}
      <AppleLoginModal
        appleLoginModalStatus={appleLoginModalStatus}
        setAppleLoginModalStatus={setAppleLoginModalStatus}
      />
      <StyledHeader>
        <LeftWrapper>
          <CloseButton onPress={onPress} />
        </LeftWrapper>
        <HeaderTitle>PAYMENT</HeaderTitle>
      </StyledHeader>
      <Body>
        <OrderLabel>Your order</OrderLabel>
        <OrderItem
          style={{ marginTop: 10 }}
          stars={IAP_PRODUCTS[route?.params?.productId]?.stars}
          price={route?.params?.localPrice || route?.params?.price}
          type={type}
          isDirectPurchase={isDirectPurchase}
        />
        <Text
          style={{ alignSelf: 'flex-end' }}
        >{`(${route?.params?.localPrice} = USD${route?.params?.price})`}</Text>
        <SelectPaymentWrapper>
          <OrderLabel style={{ marginLeft: -10 }}>{i18n.t('purchase_choose_payment')}</OrderLabel>
          <View style={{ marginTop: 30 }}>
            {shouldShowApplePay && (
              <ApplePayButton
                onPress={async () => {
                  try {
                    setIsLoading(true);
                    const { isSuccessful, payByPrimeResult } = await ApplePay.paymentRequest({
                      user,
                      content: route?.params?.content,
                      onApplePayApprove: route?.params?.onApplePayApprove,
                    });
                    setIsLoading(false);
                    if (isSuccessful) {
                      dispatch(
                        createTransactionRecordAction({
                          applePay: {
                            content: route?.params?.content,
                            payByPrimeResult,
                          },
                        }),
                      );
                      ShowSuccessPopUp();
                    }
                  } catch (e) {
                    setIsLoading(false);
                    if (__DEV__) {
                      console.log('ApplePay.paymentRequest error:', e);
                    }
                  }
                }}
              >
                <FontAwesome5 name="apple-pay" size={38} color="white" />
              </ApplePayButton>
            )}
            {
              true &&
              <WithTapPayCreditCardButton
                user={user}
                product={route?.params?.content}
                onProcess={() => setIsLoading(true)}
                onSuccess={({ stars, result }) => {
                  route?.params?.onTapPayCreditCardApprove({ stars });
                  setIsLoading(false);
                  ShowSuccessPopUp();
                  dispatch(
                    createTransactionRecordAction({
                      tapPay: {
                        content: route?.params?.content,
                        result,
                      },
                    }),
                  );
                }}
                onPending={({ stars, result }) => {
                  dispatch(
                    createTransactionRecordAction({
                      tapPay: {
                        content: route?.params?.content,
                        result,
                      },
                    }),
                  );
                }}
                onFailed={() => setIsLoading(false)}
              />
            }
            {/* <WithPayPalButton
              id={ScriptId.PAYPAL_LIB_ID}
              price={extractPrice(route?.params?.price)}
              onClick={useCallback((data, actions) => {
                if (pathEq(['fundingSource'], 'paypal', data)) {
                  setIsLoading(true);
                }
                newPayPalEvent({
                  ...user,
                  type: 'onClick',
                  price: route?.params?.price,
                  message: data,
                });
              }, [])}
              onError={useCallback((data, action) => {
                setIsLoading(false);
                newPayPalEvent({
                  ...user,
                  type: 'onError',
                  price: route?.params?.price,
                  message: data,
                });
              }, [])}
              onCancel={useCallback((data, action) => {
                setIsLoading(false);
                newPayPalEvent({
                  ...user,
                  type: 'onCancel',
                  price: route?.params?.price,
                  message: data,
                });
              }, [])}
              onCaptureApprove={useCallback(details => {
                if (is(Function, route?.params?.onPayPalCaptureApprove)) {
                  route.params.onPayPalCaptureApprove({
                    stars: IAP_PRODUCTS[route?.params?.productId]?.stars,
                    details,
                  });
                  if (window.dataLayer) {
                    // purchase_units[0].payments.captures[0]
                    window.dataLayer.push({
                      event: 'onPayPalCaptureApprove',
                      amount: path(['purchase_units', 0, 'payments', 'captures', 0, 'amount'])(
                        details,
                      ),
                      transactionId: path(['purchase_units', 0, 'payments', 'captures', 0, 'id'])(
                        details,
                      ),
                      productId: route?.params?.productId,
                    });
                  }
                  newPayPalEvent({
                    ...user,
                    type: 'onApprove',
                    price: route?.params?.price,
                    message: details,
                  });
                  dispatch(
                    createTransactionRecordAction({
                      paypal: {
                        details,
                        price: route?.params?.price,
                        productId: route?.params?.productId,
                      },
                    }),
                  );
                }
                navigation.goBack();
              }, [])}
            >
              {useCallback(({ button }) => button, [])}
            </WithPayPalButton> */}
          </View>
        </SelectPaymentWrapper>
      </Body>
    </Wrapper>
  );
};

SelectPaymentScreen.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
};

export default SelectPaymentScreen;
