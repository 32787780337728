import * as React from 'react';
import {
  View,
  SafeAreaView,
  Text,
} from 'react-native';
import { path } from 'ramda';
import { ScrollView } from 'react-native-gesture-handler';
import i18n from 'i18n';

import Header from 'components/Header/Header';
import styled from 'styled-components/native';
import CloseButton from 'components/Header/CloseButton';
import Colors from 'constants/Colors';

const IntroductionScreen = ({ navigation }) => {
  const goToHome = React.useCallback(() => {
    navigation.pop();
  }, []);

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#FFFFFF', }}>
      <Header>
        <CloseButton onPress={() => goToHome()} />
      </Header>
      <StyledScrollView>
        <ParaText>{i18n.t('how_it_work_desc')}</ParaText>
      </StyledScrollView>
    </SafeAreaView>
  );
};

export default IntroductionScreen;

const StyledScrollView = styled(ScrollView).attrs({
  flex: 1,
  backgroundColor: '#FFFFFF',
  contentContainerStyle: {
    backgroundColor: '#FFFFFF',
    alignItems: 'flex-start',
    width: '100%',
    maxWidth: 500,
    padding: 15,
    alignSelf: 'center',
  }
})``;

const ParaText = styled(Text)`
  font-size: 16;
  font-weight: 500;
  line-height: 19;
  margin-left: 14;
  max-width: 80%;
`;

const ListNumber = ({ number }) => (
  <View
    style={{
      width: 24,
      height: 24,
      borderRadius: 12,
      backgroundColor: Colors.primary.purple,
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Text style={{ color: 'white' }}>
      {number}
    </Text>
  </View>
);

const Paragraph = ({ number, children }) => (
  <View style={{
    flexDirection: 'row',
    marginTop: 16,
    justifyContent: 'center',
    alignItems: 'center',
  }}>
    <ListNumber number={number} />
    {children}
  </View>
);

