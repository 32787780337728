// @format
import PropTypes from 'prop-types';
import { when, compose, is, prop, invoker } from 'ramda';
import React, { useEffect } from 'react';
import { Platform, View } from 'react-native';

import loadDynamicScript from 'utils/loadScript';
import { query2Search } from 'utils/urlUtils';

/* toushih.tech@gmail.com */
const _config = __DEV__ ?
  {
    'client-id': 'AYv4yHTF5YZkM7C1xT_AF5kRf2brHmJ8VDwH5mV2_S7L0nK4vEQAvTzNPLcLjRwotJSH-sHQhMtEVU4-',
  } :
  {
    'client-id': 'ARp0_Qeafen585eLNM6dzQrHN1x4OI_8Ckj4TgT2gmnwYSJEBT0Irgz7mnvE_p_m7Pl7xlEqU9OiuA-X',
  };


const WithPayPalButton = ({
  children,
  id,
  config = _config,
  price,
  onCaptureApprove,
  onCancel,
  onClick,
  onClose,
  onError,
}) => {
  useEffect(() => {
    loadDynamicScript(id, `https://www.paypal.com/sdk/js${query2Search(config)}`, () => {
      if (Platform.OS === 'web') {
        window.paypal
          .Buttons({
            // Set up the transaction
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: price,
                    },
                  },
                ],
                application_context: { shipping_preference: 'NO_SHIPPING' },
              });
            },

            // Finalize the transaction
            onApprove: (data, actions) => {
              return actions.order.capture().then(function (details) {
                // Show a success message to the buyer
                // alert('Transaction completed by ' + details.payer.name.given_name + '!');
                when(
                  compose(is(Function), prop('onCaptureApprove')),
                  invoker(1, 'onCaptureApprove')(details),
                )({ onCaptureApprove });
              });
            },
            onCancel: (data, actions) => {
              if (is(Function, onCancel)) {
                onCancel(data, actions);
              }
            },
            onClick: (data, actions) => {
              if (is(Function, onClick)) {
                onClick(data, actions);
              }
            },
            onClose: (data, actions) => {
              if (is(Function, onClose)) {
                onClose(data, actions);
              }
            },
            onError: err => {
              if (is(Function, onError)) {
                onError(err);
              }
              console.error('PayPal error', err);
            },
          })
          .render('#paypal-button-container');
      }
    });
  });
  return children({
    button: <View nativeID="paypal-button-container" />,
  });
};

WithPayPalButton.propTypes = {
  children: PropTypes.func,
  id: PropTypes.string.isRequired,
  config: PropTypes.object,
  price: PropTypes.string,
  onCaptureApprove: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  onError: PropTypes.func,
  onCancel: PropTypes.func,
};

export default React.memo(WithPayPalButton);
