// @format
import {
  isEmpty,
  compose,
  ifElse,
  always,
  fromPairs,
  map,
  split,
  drop,
  concat,
  join,
  toPairs,
} from 'ramda';

export const search2Query = ifElse(
  isEmpty,
  always({}),
  compose(fromPairs, map(map(decodeURIComponent)), map(split('=')), split('&'), drop(1)),
);

export const query2Search = ifElse(
  isEmpty,
  always(''),
  compose(concat('?'), join('&'), map(join('=')), map(map(encodeURIComponent)), toPairs),
);
