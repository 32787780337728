import React, { useCallback } from "react";
import {
  View,
  Text,
  Dimensions,
  TouchableOpacity,
  Image,
} from "react-native";
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import styled from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';

import * as slack from 'apis/slack';

const { width: screenWidth } = Dimensions.get('window');

const ListItemContainer = styled(View)`
  height: 80;
  width: ${screenWidth - 56};
  max-width: 358;
  background-color: #ffffff;
  margin-top: 20;
  border-radius: 12;
  justify-content: space-between;
  padding-left: 18;
  padding-right: 18;
  align-items: center;
  flex-direction: row;
`;

const DiscountLabelWrapper = styled(View)`
  height: 22.5;
  width: 80;
  background-color: ${path(['theme', 'primary', 'blue'])};
  position: absolute;
  top: 0;
  left: 20;
  justify-content: center;
  align-items: center;
`;

const DiscountLabelText = styled(Text)`
  font-size: 13;
  font-weight: 800;
  color: #ffffff;
`;

const DiscountLabel = ({ discount }) => (
  <DiscountLabelWrapper>
    <DiscountLabelText>{`${discount}% OFF`}</DiscountLabelText>
  </DiscountLabelWrapper>
);

const ItemNameText = styled(Text)`
  font-size: 19;
  font-weight: 700;
  letter-spacing: 0.21;
  line-height: 25;
  color: #000000;
`;

const ItemNumberText = styled(ItemNameText)`
  color: ${(props) => path(['theme', 'primary', 'lightPink'])};
`;

const ItemButtonWrapper = styled(TouchableOpacity)`
  width: 120;
  height: 40;
  border-radius: 8;
  background-color: ${(props) => path(['theme', 'primary', 'pink'])};
  justify-content: center;
  align-items: center;
`;

const ItemButtonText = styled(Text)`
  color: #ffffff;
  font-size: 14;
  font-weight: 700;
  line-height: 19;
`;

const ItemButton = ({ onPress, price, customText }) => (
  <ItemButtonWrapper onPress={() => onPress()}>
    <ItemButtonText>{customText ? customText : price}</ItemButtonText>
  </ItemButtonWrapper>
);

ItemButton.propTypes = {
  onPress: PropTypes.func,
  price: PropTypes.string,
};

const ItemName = ({ itemNumber, promotion, type }) => (
  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
    {promotion && (
      <Image
        source={require('assets/images/best-seller.png')}
        style={{
          width: 30,
          height: 30,
        }}
      />
    )}
    <ItemNumberText>+{itemNumber}</ItemNumberText>
    <Text> </Text>
    <Image
      style={{ width: 20, height: 20 }}
      source={type === 'like' ? require('assets/images/getlikes.png') : require('assets/images/getfans.png')}
    />
  </View>
);


export const DirectPurchaseItem = (props) => {
  const { content, type = 'follower', onPress = () => { } } = props;
  const price = content?.localPrice || content?.price;
  return (
    <ListItemContainer>
      <DiscountLabel discount={props?.content.discount} />
      <ItemName
        itemNumber={content?.stars / 2.0}
        type={type}
      />
      <ItemButton price={price} onPress={onPress} />
    </ListItemContainer>
  );
};

export const WebDirectPurchaseItem = props => {
  const navigation = useNavigation();
  const user = useSelector((state) => state.user);
  const applePay = useSelector((state) => state.payment?.applePay);
  const onPress = useCallback(() => {
    slack.tapWebPurchase({
      ...user,
      price: props?.content?.price,
      applePay: applePay?.canMakePaymentsWithActiveCard,
    });

    // if (applePay?.canMakePaymentsWithActiveCard) {
    //   ApplePay.paymentRequest({
    //     user,
    //     content: props?.content,
    //     onApplePayApprove: props?.onApplePayApprove,
    //     setIsLoading: props?.setIsLoading,
    //   });
    //   return;
    // }
    navigation.navigate('SelectPayment', {
      content: props?.content,
      price: props?.content?.price,
      localPrice: props?.content?.localPrice,
      productId: props?.content?.productId,
      onPayPalCaptureApprove: props?.onPayPalCaptureApprove,
      onApplePayApprove: props?.onApplePayApprove,
      isDirectPurchase: true,
      type: props?.type,
    });
  }, [props]);
  return <DirectPurchaseItem {...props} onPress={onPress} />;
};
