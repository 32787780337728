import i18n from 'i18n';
import _ from 'lodash';

const insMasterBannerTitle = _.shuffle([
  i18n.t('login_slogan_2'),
  i18n.t('login_slogan_1'),
  i18n.t('login_slogan_3'),
]);

export const BANNER_LIST = [
  // IG star config
  // {
  //   iconSource: {
  //     uri:
  //       'https://play-lh.googleusercontent.com/g1gfx1c01ASqBkWELk6DG_LFM6xiAPrN4IeFdOQfnvssqSGec9ydpUDc1_ewi6J4jQ=s360-rw',
  //   },
  //   linkUrl: 'http://onelink.to/uereen',
  //   title: i18n.t('ins_promo_title'),
  //   subtitle: i18n.t('ins_promo_subtitle'),
  // },
  // {
  //   iconSource: {
  //     uri:
  //       'https://play-lh.googleusercontent.com/tTnu7K0UQHnAKM_lNOse-ej4cmrdc1Pd9_69FvH0mzbH8Q4wIxkfiEo4fngvjCn1r4GL=s360-rw',
  //   },
  //   linkUrl: 'http://onelink.to/4kfcum',
  //   title: insMasterBannerTitle[0],
  //   subtitle: `Instagram ${i18n.t('purchase_promo_title_4')}`,
  // },
  // {
  //   iconSource: {
  //     uri:
  //       'https://play-lh.googleusercontent.com/2W2US9ORows_zhrDpuPYXwFIxmMGoDUFQ5zwPNX7C8gwZOpYnuGovBc5T1JLNJZRvb8=s180',
  //   },
  //   linkUrl: 'https://iscan.page.link/tsad',
  //   title: i18n.t('iscan_promo_title'),
  //   subtitle: i18n.t('iscan_promo_subtitle'),
  // },
  // {
  //   iconSource: {
  //     uri:
  //       'https://play-lh.googleusercontent.com/hFEJ1DTEtW77ak4mrNivgbVBuibv_4F4sDSTsjRJWSzkWxzb5wZTavetNPvnGMH2XFw=w480-h960',
  //   },
  //   linkUrl: 'https://tiksave.page.link/ad',
  //   title: i18n.t('tiksave_promo_title'),
  //   subtitle: i18n.t('tiksave_promo_subtitle'),
  // },
  // {
  //   iconSource: {
  //     uri:
  //       'https://is4-ssl.mzstatic.com/image/thumb/Purple125/v4/45/b2/00/45b200a5-47b7-1745-5732-a5ffb9db7faf/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/460x0w.png',
  //   },
  //   linkUrl: 'http://onelink.to/be89ry',
  //   title: '𝒮𝓅𝑒𝒸𝒾𝒶𝓁 𝒻𝑜𝓃𝓉𝓈 𝒻𝑜𝓇 𝐼𝓃𝓈𝓉𝒶𝑔𝓇𝒶𝓂',
  //   subtitle: '𝔽𝕣𝕖𝕖 𝔽𝕠𝕟𝕥𝕤 𝔸𝕡𝕡',
  // },
  {
    iconSource: {
      uri:
        'https://dashboard-assets.dappradar.com/document/17394/floorpricepredictionmarket-dapp-defi-ethereum-logo-166x166_0b9131e45980075558e7633adb1cd418.png',
    },
    linkUrl: 'https://floorprice.finance/',
    title: 'NFT Floor Price Prediction 🔥🔥',
    subtitle: 'Predict and win rewards (BAYC, CryptoPunks...)',
  },
];

export const FULLSCREEN_LIST = [
  {
    iconSource: {
      uri:
        'https://play-lh.googleusercontent.com/tTnu7K0UQHnAKM_lNOse-ej4cmrdc1Pd9_69FvH0mzbH8Q4wIxkfiEo4fngvjCn1r4GL=s360',
    },
    mediaSource:
      // 'https://player.vimeo.com/video/494025163?autoplay=1&muted=1&loop=1',
      'https://www.dailymotion.com/embed/video/x7zfhsg?autoplay=1&mute=1&queue-enable=0',
    linkUrl: 'http://onelink.to/4yd2mx',
    title: 'Reports for Instagram',
    subtitle: 'Ins Master 🚀',
  },
  {
    iconSource: {
      uri:
        'https://play-lh.googleusercontent.com/_IUCAuZzsIlTWL0DqvLgFpALKpKq25NV93zX5JbYsGpN2knE8rvtIk2dr6Kmh_wk03o=s360-rw',
    },
    mediaSource: 'https://www.dailymotion.com/embed/video/x81e4lq?autoplay=1&mute=1&queue-enable=0',
    linkUrl: 'http://onelink.to/msrvgm',
    title: i18n.t('promo_title_pv'),
    subtitle: i18n.t('promo_subtitle_pv'),
  },
  // {
  //   iconSource: {
  //     uri:
  //       'https://play-lh.googleusercontent.com/g1gfx1c01ASqBkWELk6DG_LFM6xiAPrN4IeFdOQfnvssqSGec9ydpUDc1_ewi6J4jQ=s360-rw',
  //   },
  //   mediaSource: 'https://www.dailymotion.com/embed/video/x81e4xc?autoplay=1&mute=1&queue-enable=0',
  //   linkUrl: 'http://onelink.to/uereen',
  //   title: i18n.t('ins_promo_title'),
  //   subtitle: i18n.t('ins_promo_subtitle'),
  // },
  {
    iconSource: {
      uri:
        'https://is4-ssl.mzstatic.com/image/thumb/Purple125/v4/45/b2/00/45b200a5-47b7-1745-5732-a5ffb9db7faf/AppIcon-0-0-1x_U007emarketing-0-0-0-10-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/460x0w.png',
    },
    mediaSource: 'https://www.dailymotion.com/embed/video/x81e4fe?autoplay=1&mute=1&queue-enable=0',
    linkUrl: 'http://onelink.to/be89ry',
    title: '𝒮𝓅𝑒𝒸𝒾𝒶𝓁 𝒻𝑜𝓃𝓉𝓈 𝒻𝑜𝓇 𝐼𝓃𝓈𝓉𝒶𝑔𝓇𝒶𝓂',
    subtitle: '𝔽𝕣𝕖𝕖 𝔽𝕠𝕟𝕥𝕤 𝔸𝕡𝕡',
  },
  {
    iconSource: {
      uri:
        'https://play-lh.googleusercontent.com/2W2US9ORows_zhrDpuPYXwFIxmMGoDUFQ5zwPNX7C8gwZOpYnuGovBc5T1JLNJZRvb8=s180',
    },
    mediaSource:
      'https://www.dailymotion.com/embed/video/k69CZHXxeGfe84x8O6D?autoplay=1&mute=1&queue-enable=0',
    linkUrl: 'https://iscan.page.link/tsad',
    title: i18n.t('iscan_promo_title'),
    subtitle: i18n.t('iscan_promo_subtitle'),
  },
];
