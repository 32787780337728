import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import SettingsScreen from 'screens/SettingsScreen';
import StarsButton from 'components/Header/StarsButton';
import QuestionButton from 'components/Header/QuestionButton';
import i18n from 'i18n';

const Stack = createStackNavigator();

const SettingsStack = () => {
  return (
    <Stack.Navigator initialRouteName="Settings">
      <Stack.Screen
        name="Settings"
        component={SettingsScreen}
        options={({ navigation, route }) => {
          return {
            title: i18n.t('settings'),
            headerLeft: null,
            headerRight: () => <StarsButton />,
            headerTitleAlign: 'center',
          };
        }}
      />
    </Stack.Navigator>
  );
};

export default SettingsStack;
